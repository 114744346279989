/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "lato-bold";
  src: url("../assets/fonts/lato-bold.woff2") format("woff2"),
    url("../assets/fonts/lato-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-light";
  src: url("../assets/fonts/lato-light.woff2") format("woff2"),
    url("../assets/fonts/lato-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-regular";
  src: url("../assets/fonts/lato-regular.woff2") format("woff2"),
    url("../assets/fonts/lato-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-black";
  src: url("../assets/fonts/lato-black.woff2") format("woff2"),
    url("../assets/fonts/lato-black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-semibold";
  src: url("../assets/fonts/lato-semibold.woff2") format("woff2"),
    url("../assets/fonts/lato-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-heavy";
  src: url("../assets/fonts/lato-heavy.woff2") format("woff2"),
    url("../assets/fonts/lato-heavy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-medium";
  src: url("../assets/fonts/lato-medium.woff2") format("woff2"),
    url("../assets/fonts/lato-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "lato-regular";
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
}
.animation_delay- {
  &0point2 {
    animation-delay: 0.2s;
  }
  &0point5 {
    animation-delay: 0.5s;
  }
  &0point8 {
    animation-delay: 0.8s;
  }
  &1 {
    animation-delay: 1s;
  }
  &1point2 {
    animation-delay: 1.2s;
  }
  &1point4 {
    animation-delay: 1.4s;
  }
  &1point6 {
    animation-delay: 1.6s;
  }
  &1point8 {
    animation-delay: 1.8s;
  }
  &2 {
    animation-delay: 2s;
  }
  &2point2 {
    animation-delay: 2.2s;
  }
  &2point4 {
    animation-delay: 2.4s;
  }
  &2point6 {
    animation-delay: 2.6s;
  }
  &2point8 {
    animation-delay: 2.8s;
  }
  &3 {
    animation-delay: 3s;
  }
  &3point2 {
    animation-delay: 3.2s;
  }
  &3point4 {
    animation-delay: 3.4s;
  }
  &3point6 {
    animation-delay: 3.6s;
  }
  &3point8 {
    animation-delay: 3.8s;
  }
}

.trusteddev-tab {
  .Animations_Circle {
    width: 100px;
    position: absolute;
    margin: -35px 0 0 -25px;
  }
  .Animations_Clock {
    position: absolute;
    z-index: 1;
  }
  .Animations_Bar {
    position: absolute;
  }
  .Animations_Cog {
    position: absolute;
  }
  .FSD_animation {
    position: absolute;
  }
}

/* --------------------------------------------// Animations Guy //--------------------------------------------- */
// 0.5|0.2|0.2|0.2|0.2|0.2|0.2|0.2|0.2|0.2|0.2|0.2|0.2|0,4|0.2|0.2|0.2|0.4|5|

@keyframes Guy {
  0% {
    visibility: visible;
  }
  60% {
    visibility: hidden;
  }
}
@keyframes Guy1 {
  0% {
    visibility: visible;
  }
  2.5% {
    visibility: hidden;
  }
}
// .Guy_Animation {
//   #guy1,
//   #guy2,
//   #guy3,
//   #guy4,
//   #guy5,
//   #guy6,
//   #guy10,
//   #guy11,
//   #guy12,
//   #gu13,
//   #guy14,
//   #guy15,
//   #guy16,
//   #guy17,
//   #guy18,
//   #guy19 {
//     animation: 9s Guy infinite;
//     visibility: hidden;
//     margin-left: 25px;
//   }
//   #guy7,
//   #guy8,
//   #guy9 {
//     animation: 8s Guy1 infinite;
//     visibility: hidden;
//     margin-left: 25px;
//   }
//   #guy1 {
//     animation-delay: 0s;
//   }
//   #guy2 {
//     animation-delay: 0.2s;
//   }
//   #guy3 {
//     animation-delay: 0.4s;
//   }
//   #guy4 {
//     animation-delay: 0.6s;
//   }
//   #guy5 {
//     animation-delay: 0.8s;
//   }
//   #guy6 {
//     animation-delay: 1s;
//   }
//   #guy7 {
//     animation-delay: 1.2s;
//   }
//   #guy8 {
//     animation-delay: 1.4s;
//   }
//   #guy9 {
//     animation-delay: 1.6s;
//   }
//   #guy10 {
//     animation-delay: 1.8s;
//   }
//   #guy11 {
//     animation-delay: 2s;
//   }
//   #guy12 {
//     animation-delay: 2.2s;
//   }
//   #guy13 {
//     animation-delay: 2.4s;
//   }
//   #guy14 {
//     animation-delay: 2.8s;
//   }
//   #guy15 {
//     animation-delay: 3s;
//   }
//   #guy16 {
//     animation-delay: 3.2s;
//   }
//   #guy17 {
//     animation-delay: 3.4s;
//   }
//   #guy18 {
//     animation-delay: 3.8s;
//   }
//   #guy19 {
//     animation-delay: 4s;
//   }
// }

/* -------------------------------------------// Table Animation //-------------------------------------------- */

@keyframes table-animate {
  0% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

.FSD_animation {
  #table1,
  #table2,
  #table3,
  #table4,
  #table5,
  #table6,
  #table7,
  #table8,
  #table9,
  #table10,
  #table11,
  #table12,
  #table13,
  #table14,
  #table15,
  #table16,
  #table17,
  #table18,
  #table19,
  #table20 {
    animation: 2.8s table-animate;
    margin-top: -555px;
    width: 100%;
    height: 1100px;
    visibility: hidden;
  }
  #table1 {
    animation-delay: 0s;
  }
  #table2 {
    animation-delay: 0.2s;
  }
  #table3 {
    animation-delay: 0.4s;
  }
  #table4 {
    animation-delay: 0.6s;
  }
  #table5 {
    animation-delay: 0.8s;
  }
  #table6 {
    animation-delay: 0.9s;
  }
  #table7 {
    animation-delay: 1s;
  }
  #table8 {
    animation-delay: 1.1s;
  }
  #table9 {
    animation-delay: 1.2s;
  }
  #table10 {
    animation-delay: 1.3s;
  }
  #table11 {
    animation-delay: 1.5s;
  }
  #table12 {
    animation-delay: 1.6s;
  }
  #table13 {
    animation-delay: 1.7s;
  }
  #table14 {
    animation-delay: 1.8s;
  }
  #table15 {
    animation-delay: 1.9s;
  }
  #table16 {
    animation-delay: 2.1s;
  }
  #table17 {
    animation-delay: 2.3s;
  }
  #table18 {
    animation-delay: 2.4s;
  }
  #table19 {
    animation-delay: 2.5s;
  }
  #table20 {
    animation-delay: 2.6s;
    animation-iteration-count: infinite;
  }
}

@keyframes show {
  0% {
    visibility: visible;
  }
  70.5% {
    visibility: hidden;
  }
  70.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
// .Animations_Circle #frame1 {
//   visibility: hidden;
//   animation: 6s show infinite;
// }
// .Animations_Circle #frame2 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.2s;
// }
// .Animations_Circle #frame3 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.3s;
// }
// .Animations_Circle #frame4 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.4s;
// }
// .Animations_Circle #frame5 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.5s;
// }
// .Animations_Circle #frame6 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.6s;
// }
// .Animations_Circle #frame7 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.7s;
// }
// .Animations_Circle #frame8 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.8s;
// }
// .Animations_Circle #frame9 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 0.9s;
// }
// .Animations_Circle #frame10 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 1s;
// }
// .Animations_Circle #frame11 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 1.1s;
// }
// .Animations_Circle #frame12 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 1.2s;
// }
// .Animations_Circle #frame13 {
//   visibility: hidden;
//   animation: 6s show infinite;
//   animation-delay: 1.3s;
// }

/* ------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------// Animations_Clock //-------------------------------------------- */
/* clockSVG First one. Timing will be 0.2 0.2 0.2 0.2 0.2 and 4 */

.Animations_Clock {
  width: 115px;
  position: absolute;
  z-index: 1;
  margin: -30px 0 0 -30px;
}

@keyframes Clock {
  0% {
    visibility: visible;
  }
  5.5% {
    visibility: visible;
  }
  5.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes ClockStop {
  0% {
    visibility: visible;
  }
  99.5% {
    visibility: visible;
  }
  99.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

// .Animations_Clock #frame1 {
//   visibility: hidden;
//   animation: 4s Clock infinite;
// }

// .Animations_Clock #frame2 {
//   visibility: hidden;
//   animation: 4s Clock infinite;
//   animation-delay: 0.2s;
// }

// .Animations_Clock #frame3 {
//   visibility: hidden;
//   animation: 4s Clock infinite;
//   animation-delay: 0.4s;
// }

// .Animations_Clock #frame4 {
//   visibility: hidden;
//   animation: 4s Clock infinite;
//   animation-delay: 0.6s;
// }

// .Animations_Clock #frame5 {
//   visibility: hidden;
//   animation: 4s Clock infinite;
//   animation-delay: 0.8s;
// }

// .Animations_Clock #frame6 {
//   visibility: hidden;
//   animation: 4s ClockStop infinite;
//   animation-delay: 1s;
// }

// @keyframes show1 {
//   0% {
//     visibility: visible;
//   }
//   16.5% {
//     visibility: visible;
//   }
//   16.6% {
//     visibility: hidden;
//   }
//   100% {
//     visibility: hidden;
//   }
// }

/* ------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------// Animations_Bar //--------------------------------------------- */

/* BarsSVG Third. Same 0.1/4 */

// @keyframes bar {
//   0% {
//     visibility: visible;
//   }
//   12.5% {
//     visibility: visible;
//   }
//   12.6% {
//     visibility: hidden;
//   }
//   100% {
//     visibility: hidden;
//   }
// }

// .Animations_Bar {
//   width: 85px;
//   position: absolute;
//   margin: -18px 0 0 -5px;
// }
// .Animations_Bar #frame0 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
// }
// .Animations_Bar #frame1 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.2s;
// }
// .Animations_Bar #frame2 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.3s;
// }
// .Animations_Bar #frame3 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.4s;
// }
// .Animations_Bar #frame4 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.5s;
// }
// .Animations_Bar #frame5 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.6s;
// }
// .Animations_Bar #frame6 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.7s;
// }
// .Animations_Bar #frame7 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.8s;
// }
// .Animations_Bar #frame8 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 0.9s;
// }
// .Animations_Bar #frame9 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1s;
// }
// .Animations_Bar #frame10 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1.1s;
// }
// .Animations_Bar #frame11 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1.2s;
// }
// .Animations_Bar #frame12 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1.3s;
// }
// .Animations_Bar #frame13 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1.4s;
// }
// .Animations_Bar #frame14 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1.5s;
// }
// .Animations_Bar #frame15 {
//   visibility: hidden;
//   animation: 2.5s bar infinite;
//   animation-delay: 1.6s;
// }

// @keyframes bar {
//   0% {
//     visibility: visible;
//   }
//   12.5% {
//     visibility: visible;
//   }
//   12.6% {
//     visibility: hidden;
//   }
//   100% {
//     visibility: hidden;
//   }
// }

// @keyframes barStop {
//   0% {
//     visibility: visible;
//   }
//   75%,
//   100% {
//     visibility: hidden;
//   }
// }

// .Animations_Bar {
//   width: 85px;
//   position: absolute;
//   margin: -18px 0 0 -5px;
// }
// .Animations_Bar #frame0 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
// }
// .Animations_Bar #frame1 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.2s;
// }
// .Animations_Bar #frame2 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.3s;
// }
// .Animations_Bar #frame3 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.4s;
// }
// .Animations_Bar #frame4 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.5s;
// }
// .Animations_Bar #frame5 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.6s;
// }
// .Animations_Bar #frame6 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.7s;
// }
// .Animations_Bar #frame7 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.8s;
// }
// .Animations_Bar #frame8 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 0.9s;
// }
// .Animations_Bar #frame9 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 1s;
// }
// .Animations_Bar #frame10 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 1.1s;
// }
// .Animations_Bar #frame11 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 1.2s;
// }
// .Animations_Bar #frame12 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 1.3s;
// }
// .Animations_Bar #frame13 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 1.4s;
// }
// .Animations_Bar #frame14 {
//   visibility: hidden;
//   animation: 4.5s bar infinite;
//   animation-delay: 1.5s;
// }
// .Animations_Bar #frame15 {
//   visibility: hidden;
//   animation: 4.5s barStop infinite;
//   animation-delay: 1.6s;
// }

/* ------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------// COGSVG //------------------------------------------------ */
/* COGSVG Last one - 0.5/4  [Final]*/

// @keyframes show_cog {
//   0% {
//     visibility: visible;
//   }
//   23.5% {
//     visibility: visible;
//   }
//   23.6% {
//     visibility: hidden;
//   }
//   100% {
//     visibility: hidden;
//   }
// }

// .Animations_Cog {
//   width: 80px;
//   position: absolute;
//   margin: -30px 0 0 10px;
// }
// .Animations_Cog #frame1 {
//   visibility: hidden;
//   animation: 4s show_cog infinite;
//   animation-delay: .5s;
// }
// .Animations_Cog #frame2 {
//   visibility: hidden;
//   animation: 4s show_cog infinite;
//   animation-delay: 1s;
// }
// .Animations_Cog #frame3 {
//   visibility: hidden;
//   animation: 4s show_cog infinite;
//   animation-delay: 1.5s;
// }
// .Animations_Cog #frame4 {
//   visibility: hidden;
//   animation: 4s show_cog infinite;
//   animation-delay: 2s;
// }

@keyframes show_cog {
  0% {
    visibility: visible;
  }
  10.5% {
    visibility: visible;
  }
  10.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes show_cog1 {
  0% {
    visibility: visible;
  }
  70% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

.Animations_Cog {
  width: 80px;
  position: absolute;
  margin: -30px 0 0 10px;
}
// .Animations_Cog #frame1 {
//   visibility: hidden;
//   animation: 5.5s show_cog infinite;
//   animation-delay: 0.5s;
// }
// .Animations_Cog #frame2 {
//   visibility: hidden;
//   animation: 5.5s show_cog infinite;
//   animation-delay: 1s;
// }
// .Animations_Cog #frame3 {
//   visibility: hidden;
//   animation: 5.5s show_cog infinite;
//   animation-delay: 1.5s;
// }
// .Animations_Cog #frame4 {
//   visibility: hidden;
//   animation: 5.5s show_cog1 infinite;
//   animation-delay: 2s;
// }

/* --------------------------------------------// Animations_Heads //--------------------------------------------- */
//0.2-0.2-0.2-0.2-2-0.2-0.2-0.2-0.2 HEADS

@keyframes head {
  0% {
    visibility: visible;
  }
  25.5% {
    visibility: hidden;
  }
  25.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes headHold {
  0% {
    visibility: visible;
  }
  40.5% {
    visibility: hidden;
  }
  40.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
.Animations_Heads {
  width: 65px;
  position: absolute;
  margin: 55px 0 0 368px;
}
.Animations_Heads #frame1 {
  visibility: hidden;
  animation: 4.8s headHold infinite;
  animation-delay: 0.2s;
}
.Animations_Heads #frame2 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 1.4s;
}
.Animations_Heads #frame3 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 1.6s;
}
.Animations_Heads #frame4 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 2.2s;
}
.Animations_Heads #frame5 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 2.4s;
}
.Animations_Heads #frame6 {
  visibility: hidden;
  animation: 4.8s headHold infinite;
  animation-delay: 2.6s;
}
.Animations_Heads #frame7 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 3.8s;
}
.Animations_Heads #frame8 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 3.9s;
}
.Animations_Heads #frame9 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 4.8s;
}
.Animations_Heads #frame10 {
  visibility: hidden;
  animation: 4.8s head infinite;
  animation-delay: 5s;
}

/* --------------------------------------------// Animations_Code //--------------------------------------------- */

@keyframes code {
  0% {
    visibility: visible;
  }
  32.5% {
    visibility: visible;
  }
  32.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes codeHold {
  0% {
    visibility: visible;
  }
  70% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
.Animations_Code {
  width: 50px;
  position: absolute;
  margin: 60px 0 0 680px;
}
.Animations_Code #frame1 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 2s;
}
.Animations_Code #frame2 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 3s;
}
.Animations_Code #frame3 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 3.5s;
}
.Animations_Code #frame4 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 4s;
}
.Animations_Code #frame5 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 4.5s;
}
.Animations_Code #frame6 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 5s;
}
.Animations_Code #frame7 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 5.5s;
}
.Animations_Code #frame8 {
  visibility: hidden;
  animation: 5.5s code infinite;
  animation-delay: 6s;
}

/* --------------------------------------------// Animations_Labirynth //--------------------------------------------- */

@keyframes Labirynth {
  0% {
    visibility: visible;
  }
  25.5% {
    visibility: visible;
  }
  25.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes LabirynthHold {
  0% {
    visibility: visible;
  }
  70% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
.Animations_Labirynth {
  width: 58px;
  position: absolute;
  margin: 177px 0px 0 156px;
}
.Animations_Labirynth #frame1 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 0.5s;
}
.Animations_Labirynth #frame2 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 1s;
}
.Animations_Labirynth #frame3 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 1.5s;
}
.Animations_Labirynth #frame4 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 2s;
}
.Animations_Labirynth #frame5 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 2.1s;
}
.Animations_Labirynth #frame6 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 3.1s;
}
.Animations_Labirynth #frame7 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 3.9s;
}
.Animations_Labirynth #frame8 {
  visibility: hidden;
  animation: 6s Labirynth infinite;
  animation-delay: 4s;
}

/* -------------------------------------------// AI Animation //-------------------------------------------- */

@keyframes AI-animate {
  0% {
    visibility: visible;
  }
  25.5% {
    visibility: visible;
  }
  25.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

.Animations_Turbo {
  width: 48px;
  position: absolute;
  margin: 395px 0 0 1006px;
}

.AI {
  width: 48px;
  position: absolute;
  margin: 180px 0 0 876px;
}
.AI #brain1 {
  visibility: hidden;
  animation: 5s AI-animate infinite;
  animation-delay: 0.5s;
}
.AI #brain2 {
  visibility: hidden;
  animation: 5s AI-animate infinite;
  animation-delay: 1s;
}
.AI #brain3 {
  visibility: hidden;
  animation: 5s AI-animate infinite;
  animation-delay: 1.5s;
}
.AI #brain4 {
  visibility: hidden;
  animation: 5s AI-animate infinite;
  animation-delay: 2s;
}
.AI #brain5 {
  visibility: hidden;
  animation: 5s AI-animate infinite;
  animation-delay: 2.5s;
}
.AI #brain6 {
  visibility: hidden;
  animation: 5s AI-animate infinite;
  animation-delay: 3s;
}

/* --------------------------------------------// Animations_Card //--------------------------------------------- */

@keyframes card {
  0% {
    visibility: visible;
  }
  25.5% {
    visibility: visible;
  }
  25.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes cardHold {
  0% {
    visibility: visible;
  }
  70% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
.Animations_Card {
  width: 50px;
  position: absolute;
  margin: 400px 0 0 19px;
}
.Animations_Card #frame1 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 0.2s;
}
.Animations_Card #frame2 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 1s;
}
.Animations_Card #frame3 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 1.8s;
}
.Animations_Card #frame4 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 2.4s;
}
.Animations_Card #frame5 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 3.2s;
}
.Animations_Card #frame6 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 3.7s;
}
.Animations_Card #frame7 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 4.2s;
}
.Animations_Card #frame8 {
  visibility: hidden;
  animation: 6s card infinite;
  animation-delay: 4.7s;
}

/* --------------------------------------------// Animations_Ekierka //--------------------------------------------- */

@keyframes Ekierka {
  0% {
    visibility: visible;
  }
  25.5% {
    visibility: visible;
  }
  25.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
.Animations_Ekierka {
  width: 55px;
  position: absolute;
  margin: 201px 0 0 900px;
}
.Animations_Ekierka #frame1 {
  visibility: hidden;
  animation: 1.2s Ekierka infinite;
}
.Animations_Ekierka #frame2 {
  visibility: hidden;
  animation: 1.2s Ekierka infinite;
  animation-delay: 0.3s;
}
.Animations_Ekierka #frame3 {
  visibility: hidden;
  animation: 1.2s Ekierka infinite;
  animation-delay: 0.5s;
}
.Animations_Ekierka #frame4 {
  visibility: hidden;
  animation: 1.2s Ekierka infinite;
  animation-delay: 0.7s;
}
.Animations_Ekierka #frame5 {
  visibility: hidden;
  animation: 1.2s Ekierka infinite;
  animation-delay: 0.9s;
}
.Animations_Ekierka #frame6 {
  visibility: hidden;
  animation: 1.2s Ekierka infinite;
  animation-delay: 1s;
}

/* ------------------------------------------------------------------------------------------------------------- */

/* Font sizes */
.font {
  &8 {
    font-size: 0.5rem; /* 8px */
  }
  &10 {
    font-size: 0.625rem; /* 10px */
  }
  &12 {
    font-size: 0.75rem; /* 12px */
  }
  &14 {
    font-size: 0.875rem; /* 14px */
  }
  &16 {
    font-size: 1rem; /* 16px */
  }
  &18 {
    font-size: 1.125rem; /* 18px */
  }
  &20 {
    font-size: 1.25rem; /* 20px */
  }
  &21 {
    font-size: 1.313rem; /* 21px */
  }
  &22 {
    font-size: 1.375rem; /* 22px */
  }
  &24 {
    font-size: 1.5rem; /* 24px */
  }
  &26 {
    font-size: 1.625rem; /* 26px */
  }
  &28 {
    font-size: 1.75rem; /* 28px */
  }
  &30 {
    font-size: 1.875rem; /* 30px */
  }
  &32 {
    font-size: 2rem; /* 32px */
  }
  &35 {
    font-size: 2.219rem; /* 35.5px */
  }
  &36 {
    font-size: 2.25rem; /* 36px */
  }
  &38 {
    font-size: 2.375rem; /* 38px */
  }
  &40 {
    font-size: 2.5rem; /* 40px */
  }
  &90 {
    font-size: 5.625rem; /* 90px */
  }

  &_sb {
    font-weight: 600;
  }
}

.font-weight {
  &100 {
    font-weight: 100;
  }
  &500 {
    font-weight: 500;
  }
  &600 {
    font-weight: 600;
  }
  &700 {
    font-weight: 700;
  }
  &800 {
    font-weight: 800;
  }
  &bold {
    font-weight: bolder;
  }
}

.padding {
  &0 {
    padding: 0;
  }
  &8 {
    padding: 0 8px;
  }
  &25 {
    padding: 0 25px;
  }
  &24 {
    padding: 24px 0;
  }
  &40 {
    padding: 0px 40px;
  }
}

.text_underline {
  text-decoration: underline;
}

.margin {
  &0 {
    margin-top: 0 !important;
  }
  &10 {
    margin: 10px 0;
  }
  &top50 {
    margin-top: 15px;
  }
  &15 {
    margin: 15px 0;
  }
  &30 {
    margin: 30px 0;
  }
  &100 {
    margin-top: 100px;
  }
  &90 {
    margin-right: 90px;
  }
}

.card_line {
  padding-top: 2px;
  background-color: #9ccce8;
  display: block;
  &Left_1 {
    width: auto;
    margin: -55px 9px 55px 61%;
  }
  &Left_3 {
    width: auto;
    margin: -55px 9px 55px 61%;
  }
  &Left_5 {
    width: auto;
    margin: -55px 9px 55px 61%;
  }
  &Right_2 {
    width: auto;
    margin: -55px 61% 55px 9px;
  }
  &Right_4 {
    width: auto;
    margin: -55px 61% 55px 9px;
  }
  &Last {
    width: 33%;
    margin: -55px 61% 55px 24px;
  }
}

.blue_dot {
  position: absolute;
  min-width: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #09b6b4;
  &_step1 {
    right: -4px;
    margin-top: -7px;
  }
  &_step2 {
    left: -6px;
    margin-top: -7px;
  }
  &_step3 {
    right: -4px;
    margin-top: -7px;
  }
  &_step4 {
    left: -6px;
    margin-top: -7px;
  }
  &_step5 {
    right: 4px;
    margin-top: 4px;
  }
}

.trusteddev-tab .digit {
  position: absolute;
  font-family: "lato-black";
  color: #2e88c2;
  font-size: 16px;
  letter-spacing: 0.54px;
  &_01 {
    margin-top: -30px;
    right: 13px;
  }
  &_02 {
    margin-top: -30px;
    left: 13px;
  }
  &_03 {
    margin-top: -30px;
    right: 13px;
  }
  &_04 {
    margin-top: -30px;
    left: 13px;
  }
  &_05 {
    margin-top: -30px;
    right: 13px;
  }
  &_06 {
    margin-top: -25px;
    left: 23px;
  }
}

.image {
  height: auto;
  margin: 0 20px;
  &_w90 {
    width: 90px;
  }
  &_w100 {
    width: 100px;
  }
  &_w135 {
    width: 135px;
  }
  &_w150 {
    width: 150px;
  }
  &_w160 {
    width: 160px;
  }
  &_w85 {
    width: 85px;
    margin: -45px 0 0 0;
  }
  &_PML_img {
    width: 100px;
    margin-left: -25px;
  }
}

.color {
  &_color1 {
    color: #09b6b4;
  }
  &_color2 {
    color: #3f95cc;
  }
  &_white {
    color: #ffffff;
  }
  &_color3 {
    color: #e5f5ff;
  }
  &_color4 {
    color: #547ca3;
  }
  &_color5 {
    color: #94cff3;
  }
  &_color6 {
    color: #185780;
  }
  &_color7 {
    color: #185780;
  }
  &_heading {
    color: #b9a6d6;
  }
  &_color_light_red {
    color: #ef6d66;
  }
  &_blue_light {
    color: #5684a7;
  }
  &_violet_light {
    color: #8b75c6;
  }
  &_why_active {
    color: #18395d;
  }
  &_what_inactive {
    color: #bde4fa;
  }
  &_page2_tab1 {
    color: #0f6297;
  }
  &_page3_tab2 {
    color: #f3fbff;
  }
}

.bg {
  &_white {
    background-color: #ffffff;
  }
  &_color2 {
    background-color: #ecf7fd;
  }
  &_blue_nav {
    background-color: #134067;
  }
  &_sky_blue {
    background-color: #09b6b4;
  }
  &_blue_footer {
    background-color: #004069;
  }
  &_cto_theme {
    background-color: #2f254c;
  }
  &_transperant {
    background-color: #ff000000;
  }
}

.font-family {
  &_lato-black {
    font-family: "lato-black";
  }
}

a {
  outline: none;
}

.overflowX {
  overflow-x: hidden;
}

.logo_divider_row {
  background-color: #ecf7fd;
  padding: 25px !important;
  border-radius: 0 90px 0 0;
}

.top_navbar {
  display: flex;
  font-size: 20px;
  z-index: 4;
  border-bottom: 2px solid #ffffff;
  height: 56px;
  padding: 0;
}

.icon {
  position: absolute;
  left: 50%;
  &-Enterprise {
    width: 40px;
    margin: -74px 0 0 -20px;
  }
  &-identity {
    width: 60px;
    margin: -81px 0 0 -28px;
  }
  &-Culture {
    width: 65px;
    margin: -85px 0 0 -35px;
  }
  &-OngoingAI {
    width: 65px;
    margin: -90px 0 0 -30px;
  }
  &-ProblemSolving {
    width: 65px;
    margin: -88px 0 0 -32px;
  }
  &-TestProject {
    width: 70px;
    margin: -82px 0 0 -30px;
  }
  &-Staff {
    width: 55px;
    margin: -78px 0 0 -30px;
  }
  &-puzzle {
    width: 60px;
    margin: -78px 0 0 -28px;
  }
}
.icon-circleBgLtr {
  width: 85px;
  height: auto;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}
.icon-circleBgRtl {
  width: 85px;
  height: auto;
  display: block;
  margin: 55px auto 10px;
}
.how-page .trusteddev-tab .circle-margin {
  margin: 15px auto 10px;
}
.login-form {
  .form-group {
    &.field_cont {
      height: 5rem; // 80px
      margin-bottom: 10px;
    }
  }
}

.btn.btn-link:focus {
  box-shadow: none;
  padding-bottom: 2px;
}

button:disabled {
  cursor: not-allowed;
}

.error_msg {
  color: #ff0000;
  padding-top: 2px;
  font-size: 12px;
}

.toast_cont {
  background-color: #000000cf;
  border-radius: 5px;
  max-width: 40rem;
  color: #fff;
  @extend .font14;
  display: flex;
  align-items: center;

  .message {
    width: calc(100% - 50px);
    padding: 10px;
    text-align: center;
  }

  .btn {
    border-left: 1px solid;
    width: 50px;
    padding: 0;
    border-radius: 0;
    @extend .font14;
  }
}

.trusteddev-tab .Vetting-Methodology-card-header {
  padding: 0.15rem 1.25rem;
}

.trusteddev-tab .card_body_color,
.what-page .card_body_color {
  background-color: #e2f6ff;
}

.trusteddev-tab .navbar-nav .nav-item .nav-link,
.ctoServicePortal-tab .navbar-nav .nav-item .nav-link {
  font-family: "lato-regular";
  font-size: 12px;
  letter-spacing: 0.38px;
  text-align: center;
  white-space: nowrap;
  color: #ffffff;
}

.trusteddev-tab .banner_heading {
  font-family: "lato-bold";
  font-size: 2.781rem;
  line-height: 1.16;
  letter-spacing: 1.11px;
  text-align: center;
  color: #ffffff;
}

.ctoServicePortal-tab .banner_heading {
  font-family: "lato-bold";
  font-size: 2.156rem;
  line-height: 1.49;
  letter-spacing: 0.86px;
  text-align: center;
  color: #ffffff;
  margin: 25px auto 0;
  width: 875px;
}

.trusteddev-tab .hire_button,
.ctoServicePortal-tab .cto_hire_button {
  font-family: "lato-black";
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #fafbfc;
  background-color: #09b6b4;
  border-color: #09b6b4;
  padding: 7px 30px;
}
.ctoServicePortal-tab .cto_hire_button {
  margin: 25px auto;
}

.trusteddev-tab .active_nav,
.trusteddev-tab .underline:hover {
  border-bottom: 1px solid #0ba8b1;
  border-bottom-width: 2px;
  padding-bottom: 3px;
}

.ctoServicePortal-tab .active_nav,
.ctoServicePortal-tab .underline:hover {
  border-bottom: 1px solid #ef6d66;
  border-bottom-width: 2px;
  padding-bottom: 3px;
}

.ctoServicePortal-tab .hire_button {
  font-family: "lato-black";
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #fafbfc;
  background-color: #ef6d66;
  border-color: #ef6d66;
  padding: 7px 30px;
}

.trusteddev-tab .banner_paragraph {
  color: #ffffff;
  margin: 10px auto 25px auto;
  font-size: 1rem;
  font-family: "lato-regular";
  line-height: 1.67;
  letter-spacing: 0.45px;
  text-align: center;
  width: 714px;
}

.get-a-demo-container {
  margin: 0 auto;
}

.ctoServicePortal-tab .banner_paragraph {
  color: #ffffff;
  margin: 15px auto 25px auto;
  font-size: 1rem;
  font-family: "lato-regular";
  line-height: 1.67;
  letter-spacing: 0.45px;
  text-align: center;
  width: 750px;
}

.ctoServicePortal-tab .banner-content {
  .lines-wrapper {
    display: grid;
    grid-template-columns: 100vw;
  }
  .center-circle-lines {
    justify-self: center;
    align-self: center;
  }
}
.trusteddev-tab .The-TrustedDev-Vetting-Methodology {
  font-family: "lato-black";
  font-size: 1.75rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3.3px;
  text-align: center;
  line-height: 48px;
  &-text-style-1 {
    font-size: 2.25rem;
  }
  &-text-style-2 {
    color: #134067;
    font-size: 2.25rem;
  }
}

.about-page .why-trustedDev,
.what-page .what-trustedDev {
  font-family: "lato-bold";
  font-size: 2.219rem;
  line-height: 1.45;
  letter-spacing: 0.89px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
  .text-style {
    color: #134067;
  }
  .text-style-1 {
    color: #09b6b4;
  }
  .text-style-2 {
    font-size: 2.406rem;
  }
  &Left {
    text-align: left;
  }
}

.trusteddev-tab .your-staff-is-ready-to-hire-wrapper {
  .three-person-image {
    width: 52px;
    margin-left: 40px;
    margin-right: 5px;
  }
  .ready-to-hire-label {
    margin: 7px 0 0 87px;
    position: absolute;
    width: 280%;
    text-transform: uppercase;
    color: #185780;
    font-family: "lato-black";
    font-size: 0.906rem;
    text-align: center;
  }
}

.about-page .why-content,
.what-page .what-content {
  font-size: 1.188rem;
  color: #18395d;
  line-height: 1.43;
  letter-spacing: 0.42px;
  text-align: center;
  .text-style {
    font-family: "lato-bold";
  }
  .text-style-1 {
    font-family: "lato-heavy";
  }
}

.about-page .card-body {
  padding: 30px 60px;
  border-bottom-right-radius: 30px;
}

.trusteddev-tab .dotted_line {
  border-right: 3px dashed #09b6b4;
  position: absolute;
  height: 720px;
  left: -3px;
  top: 42px;
}

.trusteddev-tab .last_dot {
  left: 6px;
  top: 6px;
}

.trusteddev-tab .circle_blue {
  position: absolute;
  border-radius: 50%;
  top: 5px;
  &_medium {
    border: 4px solid #09b6b4;
    width: 30px;
    height: 30px;
    left: 6px;
  }
  &_large {
    border: 2px solid #09b6b4;
    width: 46px;
    height: 43px;
    left: -21px;
    top: 584px;
  }
}

.trusteddev-tab .page_divider {
  background-color: #134067;
  padding: 18px 25px;
  border-radius: 0 50px 0 0;
  margin-bottom: 16px;
}
.AI-page .page_divider {
  background-color: #134067;
  padding: 18px 25px;
  border-radius: 0 50px 0 0;
  margin-top: -25px;
}
.ctoServicePortal-tab {
  .Brands-That-Our-Leadership-content,
  .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
    font-family: "lato-bold";
    font-size: 1.375rem;
    line-height: 1.25;
    letter-spacing: 0.71px;
    color: #ffffff;
    text-align: center;
    &_text-style-1 {
      font-size: 1.75rem;
      letter-spacing: 0.89px;
    }
    &_brand-text {
      font-size: 1.75rem;
      letter-spacing: 0.89px;
      color: #ef6d66;
    }
    &_bold-text {
      font-size: 1.75rem;
      letter-spacing: 0.89px;
    }
    .text-style-1 {
      font-size: 1.75rem;
      letter-spacing: 0.89px;
    }
    .text-style-2 {
      font-size: 1.75rem;
      letter-spacing: 0.89px;
      color: #ef6d66;
    }
  }
}
.trusteddev-tab {
  .The-Implementation-of-AI-With-Software-Development-Resource-Vet,
  .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
    font-family: "lato-bold";
    font-size: 1.781rem;
    line-height: 1.25;
    letter-spacing: 0.71px;
    color: #ffffff;
    text-align: center;
    &_text-style-1 {
      font-size: 2.219rem;
      letter-spacing: 0.89px;
    }
    .text-style-1 {
      font-size: 2.219rem;
      letter-spacing: 0.89px;
    }
  }
}

.trusteddev-tab .right_cut_corners::before,
.AI-page .left_cut_corners::before,
.trusteddev-tab .left_cut_corners::before,
.AI-page .right_cut_corners::before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  border-top: 30px solid #134067;
}
.trusteddev-tab,
.AI-page {
  .right_cut_corners::before {
    right: 0;
    border-left: 35px solid #ffffff;
  }
  .left_cut_corners::before {
    left: 0;
    border-right: 35px solid #ecf7fd;
  }
}

.trusteddev-tab .digit-96,
.AI-page .digit-96 {
  font-family: "lato-black";
  font-size: 2.625rem;
  letter-spacing: 1.05px;
}

.trusteddev-tab .Belive-AI-content,
.AI-page .Belive-AI-content {
  font-family: "lato-semibold";
  font-size: 1.125rem;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #18395d;
}

.trusteddev-tab .Average-amount-content,
.AI-page .Average-amount-content {
  font-family: "lato-semibold";
  font-size: 1rem;
  line-height: 1.22;
  letter-spacing: 0.36px;
  color: #18395d;
  .text-style {
    color: #09b6b4;
    font-size: 1.125rem;
    font-family: "lato-black";
  }
}

.trusteddev-tab .cost-of-not-automating-content,
.AI-page .cost-of-not-automating-content {
  font-family: "lato-black";
  font-size: 18px;
  line-height: 1.19;
  letter-spacing: 0.6px;
  color: #3f95cc;
}

.trusteddev-tab .list-item-content,
.AI-page .list-item-content {
  color: #185780;
  letter-spacing: 0.36px;
  font-size: 1.125rem;
  font-family: "lato-semibold";
  .text-style {
    font-family: "lato-black";
  }
}

.trusteddev-tab .hour24-content,
.AI-page .hour24-content {
  font-family: "lato-black";
  font-size: 2.594rem;
  line-height: 1.6;
  letter-spacing: 1.04px;
}

.trusteddev-tab .what-can-be-automated-content,
.AI-page .what-can-be-automated-content {
  font-family: "lato-black";
  letter-spacing: 0.6px;
  color: #3f95cc;
  font-size: 1.5rem;
}

.trusteddev-tab .percentage-line-content,
.AI-page .percentage-line-content {
  font-family: "lato-semibold";
  font-weight: 600;
  font-size: 1.125rem;
  color: #185780;
  letter-spacing: 0.36px;
  .text-style {
    font-family: "lato-black";
    font-size: 1.5rem;
  }
}

.trusteddev-tab,
.ctoServicePortal-tab,
.what-page {
  .Proud-Member-of-the-Following-Organizations {
    font-family: "lato-bold";
    font-size: 1.875rem;
    line-height: 1.45;
    letter-spacing: 0.89px;
    text-align: center;
    color: #ffffff;
  }
}

.trusteddev-tab .footer-content,
.ctoServicePortal-tab .footer-content,
.what-page .footer-content {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  text-align: left;
  margin-bottom: 5px;
}

.trusteddev-tab .private-policy,
.ctoServicePortal-tab .private-policy,
.what-page .private-policy {
  font-size: 12px;
  line-height: 2.75;
  letter-spacing: 0.8px;
  text-align: justify;
  color: #cdddea;
}

.trusteddev-tab .private-policy a,
.what-page .private-policy a,
.ctoServicePortal-tab .private-policy a {
  color: #cdddea;
}

.trusteddev-tab .copyrights,
.ctoServicePortal-tab .copyrights,
.what-page .copyrights {
  font-size: 12px;
  line-height: 2.5;
  letter-spacing: 0.7px;
}

.trusteddev-tab .footer-nav,
.what-page .footer-nav {
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: justify;
  a {
    color: #5684a7;
    :hover {
      text-decoration: auto;
    }
  }
}

.ctoServicePortal-tab .footer-nav {
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: justify;
  a {
    color: #8b75c6;
    :hover {
      text-decoration: auto;
    }
  }
}

.trusteddev-tab .footer_block,
.ctoServicePortal-tab .footer_block,
.what-page .footer_block {
  padding: 25px;
  border-radius: 0 100px 0 0;
  height: 275px;
}

.trusteddev-tab .social-media,
.ctoServicePortal-tab .social-media,
.what-page .social-media {
  width: 38px;
  height: 38px;
  object-fit: contain;
  cursor: pointer;
}

.trusteddev-tab .longdash,
.ctoServicePortal-tab .longdash {
  padding: 0.1em 0 0 0;
  width: 6em;
  display: inline-flex;
  &_blue {
    background-color: #57a4d5;
    animation-delay: 0.2s;
  }
  &_lightRed {
    background-color: #9a8bb4;
  }
}

.ctoServicePortal-tab .longdash-sm {
  background-color: #ef6d66;
  padding: 0.1em 0 0 0;
  width: 3em;
  margin: 5px auto 15px;
}

.ctoServicePortal-tab {
  .page-divider-section-background {
    background-color: #d1e6f3;
    border-radius: 0 115px 0 0;
    padding: 0 10px 10px 0;
    margin-top: 10px;
  }
  .page-divider-section {
    background-color: #2f254c;
    padding: 25px 25px;
    margin-top: 5px;
    border-radius: 0 85px 0 0;
  }
}

.ctoServicePortal-tab .brands {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 0.5rem;
}
.ctoServicePortal-tab .brands__item img {
  display: block;
  max-width: 80%;
}
.ctoServicePortal-tab .cto-brand-logo-divider {
  .cocacola,
  .kelloggs,
  .jpmorgan,
  .experian {
    max-width: 175px;
    min-width: 100px;
  }
  .charles {
    max-width: 115px;
    min-width: 90px;
  }
}
.trusteddev-tab .order-section,
.what-page .order-section {
  padding: 35px 160px 50px;
}
.ctoServicePortal-tab .order-section {
  padding: 40px 160px 75px;
}

//CTO Page step section CSS

.ctoServicePortal-tab .step-img {
  position: absolute;
  left: 50%;
  &-step1 {
    width: 400px;
    margin: 8px 0 0 -475px;
  }
  &-step2 {
    width: 450px;
    margin: 225px 0 0 -60px;
  }
  &-step3 {
    margin: 465px 0 0 -330px;
    width: 425px;
  }
  &-step4 {
    width: 250px;
    margin: 610px 0 0 150px;
  }
  &-step5 {
    width: 260px;
    margin: 820px 0 0 -545px;
  }
  &-step6 {
    width: 240px;
    margin: 1010px 0 0 -50px;
  }
}

.ctoServicePortal-tab .step-content {
  width: 240px;
  position: absolute;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: center;
  color: #185780;
  font-size: 0.875rem;
  font-family: "lato-semibold";
  left: 50%;
  &-step1 {
    margin: 100px 0 0 -105px;
  }
  &-step2 {
    margin: 390px 0 0 -240px;
  }
  &-step3 {
    // margin: 710px 0 0 -233px;
    margin: 520px 0 0 -595px;
    width: 215px;
  }
  &-step4 {
    margin: 760px 0 0 310px;
    width: 200px;
  }
  &-step5 {
    margin: 920px 0 0 -275px;
    width: 205px;
  }
  &-step6 {
    margin: 1110px 0 0 135px;
    width: 285px;
  }
}

.ctoServicePortal-tab .step-arrow {
  position: absolute;
  left: 50%;
  &-step1 {
    margin: 28px 0px 0px -70px;
    width: 350px;
  }
  &-step2 {
    margin: 330px 0px 0 -380px;
    width: 250px;
  }
  &-step3 {
    margin: 545px 0px 0 35px;
    width: 200px;
  }
  &-step4 {
    margin: 755px 0 0 -300px;
    width: 375px;
  }
  &-step5 {
    margin: 985px 0 0 -340px;
    width: 220px;
    transform: scaleY(-1) rotate(-65deg);
  }
}

//TrustedDev Step section CSS

.trusteddev-tab .step-img {
  position: absolute;
  left: 50%;
  &-step1 {
    width: 445px;
    margin: 0px 0 0 -450px;
  }
  &-step2 {
    width: 450px;
    margin: 215px 0 0 0px;
  }
  &-step3 {
    margin: 465px 0 0 -305px;
    width: 400px;
  }
  &-step4 {
    width: 250px;
    margin: 610px 0 0 150px;
  }
  &-step5 {
    width: 260px;
    margin: 820px 0 0 -545px;
  }
  &-step6 {
    width: 310px;
    margin: 1040px 0 0 -60px;
  }
}

.trusteddev-tab .step-label,
.ctoServicePortal-tab .step-label {
  position: absolute;
  font-size: 24px;
  font-family: "lato-black";
  line-height: 0.85;
  letter-spacing: 0.84px;
  text-align: center;
  color: #3f95cc;
  left: 50%;
  &-step1 {
    margin: 25px 0 0 -140px;
  }
  &-step2 {
    margin: 325px 0 0 -65px;
  }
  &-step3 {
    margin: 565px 0 0 -370px;
  }
  &-step4 {
    margin: 770px 0 0 80px;
  }
  &-step5 {
    margin: 935px 0px 0 -425px;
  }
  &-step6 {
    margin: 1108px 0px 0 -105px;
  }
}

.trusteddev-tab .step-content {
  width: 240px;
  position: absolute;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: center;
  color: #185780;
  font-size: 1rem;
  font-family: "lato-semibold";
  left: 50%;
  &-step1 {
    margin: 80px 0 0 -90px;
    width: 260px;
  }
  &-step2 {
    margin: 370px 0 0 -230px;
    width: 265px;
  }
  &-step3 {
    margin: 520px 0 0 -615px;
  }
  &-step4 {
    margin: 805px 0 0 320px;
    width: 140px;
  }
  &-step5 {
    margin: 920px 0 0 -275px;
    width: 205px;
  }
  &-step6 {
    margin: 1170px 0 0 135px;
    width: 285px;
    text-align: start;
  }
}

.trusteddev-tab .step-arrow {
  position: absolute;
  left: 50%;
  &-step1 {
    margin: 25px 0px 0px -12px;
    width: 350px;
  }
  &-step2 {
    margin: 330px 0px 0 -345px;
    width: 250px;
  }
  &-step3 {
    margin: 545px 0px 0 35px;
    width: 200px;
  }
  &-step4 {
    margin: 755px 0 0 -300px;
    width: 375px;
  }
  &-step5 {
    margin: 985px 0 0 -340px;
    width: 220px;
    transform: scaleY(-1) rotate(-65deg);
  }
}

//AI-Page Step section CSS

.AI-page .step-img {
  position: absolute;
  left: 50%;
  &-step1 {
    width: 425px;
    margin: 8px 0 0 -355px;
  }
  &-step2 {
    width: 450px;
    margin: 220px 0 0 95px;
  }
  &-step3 {
    margin: 445px 0 0 -220px;
    width: 400px;
  }
  &-step4 {
    width: 235px;
    margin: 590px 0 0 210px;
  }
  &-step5 {
    width: 260px;
    margin: 790px 0 0 -485px;
  }
  &-step6 {
    width: 310px;
    margin: 1040px 0 0 60px;
  }
}

.AI-page .step-label {
  position: absolute;
  font-size: 1.5rem;
  font-family: "lato-black";
  line-height: 0.85;
  letter-spacing: 0.84px;
  text-align: center;
  color: #3f95cc;
  left: 50%;
  &-step1 {
    margin: 30px 0 0 -65px;
  }
  &-step2 {
    margin: 325px 0 0 30px;
  }
  &-step3 {
    margin: 553px 0 0 -290px;
  }
  &-step4 {
    margin: 740px 0 0 140px;
  }
  &-step5 {
    margin: 905px 0px 0 -365px;
  }
  &-step6 {
    margin: 1108px 0px 0 15px;
  }
}

.AI-page .step-content {
  position: absolute;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: center;
  color: #185780;
  font-size: 1rem;
  font-family: "lato-semibold";
  left: 50%;
  &-step1 {
    margin: 85px 0 0 -20px;
    width: 275px;
  }
  &-step2 {
    margin: 375px 0 0 -130px;
    width: 300px;
  }
  &-step3 {
    margin: 535px 0 0 -510px;
    width: 210px;
  }
  &-step4 {
    margin: 715px 0 0 350px;
    width: 140px;
  }
  &-step5 {
    margin: 860px 0 0 -190px;
    width: 215px;
  }
  &-step6 {
    margin: 1170px 0 0 255px;
    width: 285px;
    text-align: start;
  }
}

.AI-page .step-arrow {
  position: absolute;
  left: 50%;
  &-step1 {
    margin: 25px 0px 0px 80px;
    width: 350px;
  }
  &-step2 {
    margin: 325px 0px 0 -265px;
    width: 250px;
  }
  &-step3 {
    margin: 535px 0px 0 120px;
    width: 170px;
  }
  &-step4 {
    margin: 730px 0 0 -240px;
    width: 375px;
  }
  &-step5 {
    margin: 985px 0 0 -240px;
    width: 220px;
    transform: scaleY(-1) rotate(-65deg);
  }
}

//What Page Step section CSS

.what-page .step-img {
  position: absolute;
  left: 50%;
  &-step1 {
    width: 440px;
    margin: 8px 0 0 -465px;
  }
  &-step2 {
    width: 410px;
    margin: 230px 0 0 -15px;
  }
  &-step3 {
    margin: 430px 0 0 -315px;
    width: 400px;
  }
  &-step4 {
    width: 250px;
    margin: 580px 0 0 140px;
  }
  &-step5 {
    width: 260px;
    margin: 795px 0 0 -555px;
  }
   &-step6 {
    width: 310px;
    margin: 1040px 0 0 -60px;
  }
}

.what-page .step-label {
  position: absolute;
  font-size: 1.5rem;
  font-family: "lato-black";
  line-height: 0.85;
  letter-spacing: 0.84px;
  text-align: center;
  color: #3f95cc;
  left: 50%;
  &-step1 {
    margin: 30px 0 0 -165px;
  }
  &-step2 {
    margin: 295px 0 0 -80px;
  }
  &-step3 {
    margin: 530px 0 0 -380px;
  }
  &-step4 {
    margin: 740px 0 0 65px;
  }
  &-step5 {
    margin: 910px 0px 0 -435px;
  }
   &-step6 {
    margin: 1108px 0px 0 -105px;
  }
}

.what-page .step-content {
  width: 240px;
  position: absolute;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: center;
  color: #185780;
  font-size: 1rem;
  font-family: "lato-semibold";
  left: 50%;
  &-step1 {
    margin: 78px 0 0 -90px;
  }
  &-step2 {
    margin: 335px 0 0 -145px;
    width: 210px;
  }
  &-step3 {
    margin: 565px 0 0 -495px;
  }
  &-step4 {
    margin: 745px 0 0 300px;
    width: 160px;
  }
  &-step5 {
    margin: 900px 0 0 -305px;
    width: 240px;
  }
  &-step6 {
    margin: 1170px 0 0 135px;
    width: 285px;
    text-align: start;
  }
}

.what-page .step-arrow {
  position: absolute;
  left: 50%;
  &-step1 {
    margin: 25px 0px 0px -12px;
    width: 320px;
  }
  &-step2 {
    margin: 295px 0px 0 -350px;
    width: 250px;
  }
  &-step3 {
    margin: 515px 0px 0 25px;
    width: 200px;
  }
  &-step4 {
    margin: 735px 0 0 -310px;
    width: 370px;
  }
  &-step5 {
    margin: 985px 0 0 -340px;
    width: 220px;
    transform: scaleY(-1) rotate(-65deg);
  }
}

.trusteddev-tab .Vetting-Methodology-step-heading,
.ctoServicePortal-tab .Vetting-Methodology-step-heading {
  text-transform: uppercase;
  font-family: "lato-black";
  padding: 0px 25px;
  font-size: 0.906rem;
  text-align: center;
}

.trusteddev-tab .Rectangle-box,
.AI-page .Rectangle-box {
  min-height: 188px;
  max-height: 200px;
}
.ctoServicePortal-tab .cto-page-banner {
  background-image: url("../assets/images/layer-35.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 1117px;
  border-radius: 0px 0px 110px 0px;
}

.about-page .why-page-banner {
  background-image: url("../assets/images/WhyHD.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  border-radius: 0px 0px 125px 0px;
  border-bottom: 10px solid #d1e6f3;
}

.AI-page .AI-page-banner {
  background-image: url("../assets/images/HeroAI.jpg");
  background-repeat: no-repeat;
  height: 535px;
  border-radius: 0px 0px 110px 0px;
  background-size: 100%, 100%;
  background-position-y: top;
}

.Custom-SAAS-page .Custom-Saas-page-banner {
  background-image: url("../assets/images/custom-saas-home-banner.jpg");
  height: 440px;
  border-radius: 0px 0px 110px 0px;
  border-bottom: 10px solid #d1e6f3;
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  background-position-y: 100%;
}
.trusteddev-tab .four_box_center_img,
.AI-page .four_box_center_img {
  position: absolute;
  z-index: 1;
  width: 85px;
  margin: -45px 0 0 0;
}
.trusteddev-tab .The-TrustedDev-Vetting-Methodology-Card-Content,
.ctoServicePortal-tab .The-TrustedDev-Vetting-Methodology-Card-Content {
  padding: 0 28px;
}
.trusteddev-tab .white-box,
.AI-page .white-box {
  background-color: #ffffff;
  height: 57px;
  position: absolute;
  z-index: 1;
  width: 58px;
  margin: -31px 0 0 7px;
}

.trusteddev-tab .blue-box,
.AI-page .blue-box {
  opacity: 0.62;
  background-color: #b0e8e7;
  height: 55px;
  position: absolute;
  z-index: 1;
  width: 58px;
  margin: -22px 0 0 12px;
}

.trusteddev-tab .Revealing-Stats-label,
.AI-page .Revealing-Stats-label {
  font-family: "lato-semibold";
  line-height: 1.83;
  font-size: 10px;
  letter-spacing: 0.24px;
  float: right;
  color: #ffffff;
  margin-top: 7px;
  a {
    color: #ffffff;
  }
}

.trusteddev-tab .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #09b6b44a;
}

.trusteddev-tab .your-staff-is-ready-to-hire-wrapper {
  display: flex;
  align-items: self-start;
  flex-direction: row;
  width: 100%;
  .three-person-image {
    width: 55px;
    margin-left: 45px;
    margin-right: 8px;
  }
}

.icon-MobilecircleBgRtl {
  margin: 0px auto 10px;
}

.trusteddev-tab .blue_bar_page_divider {
  background-color: #134067;
  padding: 15px;
  border-radius: 0 0 0 75px;
  border-bottom: 12px solid #d1e6f3;
}

.ctoServicePortal-tab .dark_bar_page_divider {
  background-color: #2b1f4a;
  text-align: center;
  padding: 15px;
  border-radius: 0 0 0 135px;
  border-bottom: 13px solid #d1e6f3;
}

.trusteddev-tab .card_border {
  border-radius: 30px 30px 0 0;
  border: none;
}

.about-page .card_border {
  border-radius: 0 50px 0 0;
  border: none;
}

.navbar .navbar-collapse .tabs-section {
  display: flex;
  align-items: baseline;
  margin-top: 8px;
}

.navbar .top-nav-logo {
  margin-left: -135px;
}

// .navbar .top_nav .navbar-collapse .activeTrustedDevTab {
//   height: 55px;
//   margin: -9px 0px -7px -28px;
// }
// .navbar .top_nav .navbar-collapse .InactiveTrustedDevTab {
//   height: 55px;
//   margin: -13px 0px -7px -28px;
// }

// .navbar .top_nav .navbar-collapse .TabLabel {
//   letter-spacing: 0.33px;
//   font-family: "lato-bold";
//   font-size: 0.813rem;
//   cursor: pointer;
//   &TrustedDevActive-label {
//     margin: 19px 50px 0 -195px;
//     color: #134067;
//   }
//   &TrustedDevInactive-label {
//     margin: 19px 50px 0 -209px;
//     color: #67889b;
//   }
//   &CTOActiveTab-label {
//     margin: 12px 50px 0 -210px;
//     color: #2f254c;
//   }
//   &CTOInactiveTab-label {
//     margin: 0 50px 0 -196px;
//     color: #7664ac;
//   }
// }

.navbar .top_nav .navbar-collapse {
  .activeTrustedDevTab {
    height: 55px;
    margin: -24px 0 0 -33px;
    position: absolute;
  }
  .InactiveTrustedDevTab {
    height: 55px;
    margin: -25px 0 0 190px;
    position: absolute;
  }
  .CTOTab-inactive {
    height: 42px;
    margin: -20px 0 0 -14px;
    position: absolute;
    width: 240px;
  }
  .CTOTab-active {
    height: 42px;
    margin: -19px 0 0 205px;
    position: absolute;
    width: 240px;
  }
}
.navbar .top_nav .navbar-collapse .TabLabel {
  letter-spacing: 0.33px;
  font-family: "lato-bold";
  font-size: 0.813rem;
  cursor: pointer;
  position: absolute;
  &TrustedDevActive-label {
    margin: -4px 0 0 24px;
    color: #134067;
  }
  &TrustedDevInactive-label {
    margin: -5px 0 0 232px;
    color: #67889b;
  }
  &CTOActiveTab-label {
    margin: -5px 0 0 240px;
    color: #2f254c;
  }
  &CTOInactiveTab-label {
    margin: -5px 0 0 35px;
    color: #7664ac;
  }
}

.navbar .top_nav .navbar-collapse .active-tab-listitem {
  z-index: 2;
}
.navbar .top_nav .navbar-collapse .inactive-tab-listitem {
  z-index: 1;
}

.navbar .top_nav .navbar-nav .margin-right {
  margin-right: 15px;
}
.navbar .top_nav .navbar-nav .margin-left {
  margin-left: 15px;
}
.navbar .top_nav .social-twitter-icon {
  width: 17px;
}
.navbar .top_nav .social-facebook-icon {
  width: 9px;
}
.navbar .top_nav .social-linkedIn-icon {
  width: 15px;
}
.navbar .site-logo {
  width: 175px;
}
.bg_cto_theme .cto-site-logo {
  width: 197px;
  margin: 7px -15px 0 -13px;
}
.tab-content .d-sm-block .mobile-banner-logo,
.page_bottom_divider_banner .mobile-footer-logo,
.AI-page .mobile-footer-logo,
.Engagement-page .mobile-footer-logo {
  width: 175px;
  height: auto;
}
.ctoServicePortal-tab .mobile-banner-logo {
  width: 200px;
  height: auto;
}
.ctoServicePortal-tab .mobile-footer-logo {
  width: 210px;
  height: auto;
  margin: -19px 0 -20px -17px;
}
.navbar .login-link-font {
  font-family: "lato-semibold";
  letter-spacing: 0.5px;
  a:hover {
    color: #fafbfc;
  }
}
.about-page .nav {
  .active-tab {
    z-index: 1;
  }
  .header-tab {
    width: 166px;
    height: 41px;
    cursor: pointer;
  }
  .whypage-tabLabel {
    position: absolute;
    margin: 0 0 0 56px;
    line-height: 3.03;
    letter-spacing: 0.45px;
    font-size: 1rem;
    cursor: pointer;
    z-index: 2;
  }
}
.about-page .Rectangle-wrapper .rectangle {
  min-width: 210px;
  max-width: initial;
  min-height: 270px;
  max-height: 275px;
  margin: 5px;
  padding: 20px 15px 20px 15px;
  border-radius: 15px;
  box-shadow: 0px 4px 0 0 rgba(2, 43, 67, 0.13);
  &-bg-sky-style {
    background-color: #d8eef8;
  }
  &-bg-white-style {
    background-color: #ffffff;
  }
}

.about-page .Rectangle-wrapper .rectangle-img {
  width: 100px;
}

.about-page .Rectangle-wrapper .rectangle-label {
  font-family: "lato-semibold";
  color: #18395d;
  line-height: 1;
  letter-spacing: 0.55px;
  font-size: 1.125rem;
  text-align: center;
  margin: 20px 10px 20px;
  .text-style {
    color: #09b6b4;
    font-family: "lato-black";
  }
}

.about-page .Rectangle-wrapper .rectangle-heading {
  font-family: "lato-black";
  color: #3f95cc;
  font-size: 1.875rem;
  line-height: 0.69;
  letter-spacing: 0.9px;
  margin: 10px 0;
}

.about-page .about-page-why-footer-banner {
  background-image: url("../assets/images/how-page-banner.jpg");
  background-size: 100%, cover;
  background-repeat: no-repeat;
  height: 750px;
  width: 100%;
  margin-top: -400px;
  padding-top: 400px;
  background-position: 100% 100%;
}
.about-page .about-page-proud-member-banner {
  background-image: url("../assets/images/proud-member-label-banner.png");
  background-size: 100%, cover;
  background-repeat: no-repeat;
  height: 750px;
  width: 100%;
  margin-top: -400px;
  padding-top: 400px;
  background-position: 100% 100%;
}

.about-page .mobile-footer-logo {
  width: 175px;
  height: auto;
}

.about-page .icon-size {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-page {
  .icon-Dedicated {
    width: 68px;
    margin-top: 46px;
  }
  .icon-Fee {
    width: 85px;
    margin-top: 48px;
  }
  .icon-Increased {
    width: 70px;
    margin-top: 45px;
  }
  .icon-Vetted {
    width: 65px;
    margin-top: 50px;
  }
  .icon-Supported {
    width: 70px;
    margin-top: 53px;
  }
  .icon-Puzzle {
    width: 80px;
    margin: 52px 0 0 1px;
  }
}

.how-page .how-page-banner {
  background-image: url("../assets/images/HeroBanners/DesktopHD2208How.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  border-radius: 0px 0px 125px 0px;
  border-bottom: 10px solid #d1e6f3;
}

.how-page .How-TrustedDev-Works-Content {
  padding: 0 40px;
  color: #18395d;
  font-size: 1rem;
  line-height: 1.47;
  letter-spacing: 0.43px;
}

.how-page .How-TrustedDev-Works-Heading {
  font-family: "lato-black";
  color: #185780;
  line-height: 1;
  font-size: 1.188rem;
  letter-spacing: 0.54px;
  text-align: center;
  text-transform: uppercase;
  padding: 0px 40px;
}

.remove-text-transform {
  text-transform: none !important;
}

.how-page .How-TrustedDev-Works-Tagline {
  font-family: "lato-black";
  font-size: 1.344rem;
  color: #185780;
  text-transform: uppercase;
  line-height: 1.12;
  letter-spacing: 0.54px;
  margin: 20px 0;
  .text-style {
    color: #09b6b4;
  }
}

.how-page .hire_btn {
  font-family: "lato-black";
  background-color: #09b6b4;
  padding: 12px 40px;
  text-align: center;
  display: block;
  margin: 25px auto 5px;
  color: #fafbfc;
  font-size: 0.875rem;
  letter-spacing: 0.43px;
  border-color: #09b6b4;
  text-transform: uppercase;
}

.how-page .trusteddev-tab .circle_blue_large {
  border: 2px solid #09b6b4;
  width: 46px;
  height: 43px;
  left: -25px;
  top: 25px;
}

.how-page .trusteddev-tab .dotted_line {
  border-right: 3px dashed #09b6b4;
  position: absolute;
  height: 94.5%;
  left: -3px;
  top: -30px;
}

.what-page .what-page-banner {
  background-image: url("../assets/images/HeroBanners/DesktopHD2208What.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 35%;
  height: 400px;
  border-radius: 0px 0px 125px 0px;
  border-bottom: 10px solid #d1e6f3;
}

.what-page .Expert-Software-Engineer-Card {
  margin-top: -150px;
}

.what-page .what-content:last-child {
  margin-bottom: 150px;
}

.what-page .Expert-Software-Engineer-Card .card-header {
  background-color: #004069;
  color: #ffffff;
}

.what-page .card-header,
.what-page .card {
  border-radius: 0 30px 0 0;
  border: none;
}

.what-page .Expert-Software-card-header {
  border-radius: 30px 0 0 0;
  border: none;
}

.what-page .World-Class-Technical-Recruitment-Border {
  border-radius: 0 0 40px 0;
  border: none;
}

.what-page .World-Class-Technical-Recruitment-Card {
  background-color: #004069;
  color: #ffffff;
  border-bottom: 10px solid #e2f6ff;
}
.what-page .Expert-software-engineer-background-banner {
  background-image: url("../assets/images/how-page-banner.jpg");
  background-repeat: no-repeat;
  background-size: 190%;
  background-position-x: 53%;
  background-position-y: 43%;
  height: 650px;
  margin-bottom: -65px;
}
.what-page .Expert-software-engineer-section-body {
  height: 505px;
  background-color: #ffffff;
  border-radius: 0 0 0 30px;
  padding: 0;
  .impact-gif {
    width: 750px;
    float: left;
    margin-left: 25px;
    margin-bottom: 25px;
    z-index: 1;
  }
  .impact2-gif {
    width: 363px;
    margin-top: -100px;
    margin-left: -17px;
    z-index: 0;
  }
  .arrow-right {
    position: absolute;
    top: 45%;
    margin-left: 15px;
    width: 75px;
    z-index: 1;
  }
  .star {
    position: absolute;
    z-index: 1;
    &1 {
      top: 10%;
      margin-left: 60px;
      width: 35px;
    }
    &2 {
      top: 3%;
      margin-left: 95px;
      width: 45px;
    }
    &3 {
      top: 9%;
      margin-left: 150px;
      width: 35px;
    }
  }
}

.AI-page .card-header {
  border-radius: 0 0 65px 0;
  border: none;
}

.AI-page .card-margin {
  margin-top: -10px;
}

.AI-page .AI-page-BackgroundColor {
  height: 585px;
  border-radius: 0px 0px 100px 0px;
  background-color: #ecf7fd;
}

.AI-page .AI-trustedDev {
  font-family: "lato-bold";
  font-size: 2.25rem;
  line-height: 1.3;
  letter-spacing: 0.89px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
  .text-style {
    color: #134067;
  }
}
.AI-page .How-works {
  font-family: "lato-bold";
  font-size: 1.95rem;
  line-height: 1.3;
  letter-spacing: 0.89px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 0;
  .text-style {
    color: #134067;
  }
}
.Custom-SAAS-page .SAAS-trustedDev {
  font-family: "lato-bold";
  font-size: 1.95rem;
  line-height: 1.3;
  letter-spacing: 0.89px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
  .text-style {
    color: #134067;
  }
}

.AI-page .AI-content {
  font-family: "lato-bold";
  font-size: 1.5rem;
  line-height: 1.54;
  letter-spacing: 0.74px;
  text-align: center;
  color: #134067;
  .text-style {
    background-color: #cef0f0;
  }
}

.AI-page .AI-paragraph,
.Custom-SAAS-page .SAAS-paragraph {
  font-family: "lato-semibold";
  font-size: 1.125rem;
  line-height: 1.25;
  letter-spacing: 0.48px;
  text-align: center;
  color: #18395d;
  .text-style {
    font-family: "lato-heavy";
  }
}

.AI-page .AI-how-it-work-border {
  border-radius: 35px 0 0 0;
  background-color: #134067;
  border: none;
}

.AI-page .order-section {
  background-color: #ecf7fd;
}

.AI-page .The-Implementation-of-AI-With-Software-Development-Resource-Vet {
  font-family: "lato-bold";
  font-size: 1.781rem;
  line-height: 1.15;
  letter-spacing: 0.89px;
  color: #ffffff;
  margin-bottom: 0;
  &_text-style-1 {
    font-size: 28px;
    letter-spacing: 1.8px;
  }
}

.AI-page .page_divider_banner {
  background-image: url("../assets/images/layer-26.png");
  background-size: 100%, cover;
  background-repeat: no-repeat;
  height: 800px;
  background-position: 100% 58%;
  margin-bottom: -450px;
}

.AI-page .rectangle-wrapper-margin {
  margin-top: -1px;
}

.Custom-SAAS-page {
  .black-shade {
    background-image: linear-gradient(#021627c4, #021627a6 10%);
  }
  .first-card-header {
    border-radius: 0 45px 0 0;
    border: none;
    margin-top: -25px;
  }
  .owasp-img {
    width: 145px;
    margin: 0 0 0 100px;
  }
  .computer-img {
    width: 1175px;
    display: block;
    margin: 0px auto -40px;
  }
  .middle-background-banner {
    background-image: url("../assets/images/fsd-banner.png");
    background-size: 100%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    height: 1300px;
  }
  .second-card-header {
    border-radius: 0 0 0 35px;
    background-color: #14b9b7;
    border: none;
  }
  .Complex-Information-Security-By-Design {
    font-family: "lato-bold";
    font-size: 2.313rem;
    line-height: 1.15;
    letter-spacing: 0.93px;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
    .text-style {
      font-size: 2.594rem;
      letter-spacing: 1.04px;
      color: #14b9b7;
    }
  }
  .Full-stack-development-img {
    height: 1300px;
  }
  .section-wrapper {
    padding: 25px;
  }
  .section-wrapper h3 {
    font-family: "lato-black";
    font-size: 27.5px;
    line-height: 1.17;
    letter-spacing: 0.69px;
    color: #185780;
    margin: 0 0 20px 20px;
    text-align: left;
  }
  .Owsap-image {
    width: 300px;
    margin: -10px 0 0 -60px;
  }
  .section-wrapper ul {
    list-style: none;
  }
  .section-wrapper ul li {
    font-family: "lato-semibold";
    font-size: 1.25rem;
    line-height: 2.63;
    letter-spacing: 0.61px;
    color: #185780;
    margin: 7px 0;
  }
  .Order-list-number {
    font-family: "lato-black";
    background: #e1f9f9;
    padding: 6px 10px 7px 15px;
    border-radius: 25px;
    margin: 0 12px;
  }
  .section-wrapper ul li:last-child .Order-list-number {
    font-family: "lato-black";
    background: #e1f9f9;
    padding: 6px 5px 7px 9px;
    border-radius: 25px;
    margin: 0 12px;
  }
}

.Engagement-page {
  .engagement-page-banner {
    background-image: url("../assets/images/engagement-page-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 0px 0px 110px 0px;
  }
  .blue-shadeBg {
    background-image: url("../assets/images/GlowHD.png");
    background-size: auto;
    background-repeat: no-repeat;
    height: 100%;
    background-position: top;
  }
  .engagement-page-banner .main-heading {
    font-family: "lato-bold";
    font-size: 2.781rem;
    line-height: 1.16;
    letter-spacing: 1.11px;
    text-align: center;
    color: #ffffff;
    margin: 0px auto 50px;
  }
  .engagement-card {
    border: none;
    text-align: center;
    background-color: #09b6b400;
    margin: auto 15px;
  }
  .engagement-card-header {
    background-color: #09b6b4;
    border-radius: 20px 20px 0 0;
    min-height: 100px;
    padding: 15px 12px 0px;
  }
  .engagement-card-bottom {
    padding-bottom: 60px;
  }
  .engagement-card-header .card-circle {
    border: 2px solid #ffffff;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    background-color: #15bab8;
    display: block;
    margin: -50px auto 0;
  }
  .engagement-card {
    .suitcase-img {
      margin-top: 10px;
      width: 51px;
    }
    .binary-img {
      width: 35px;
      margin-top: 17px;
    }
  }
  .engagement-card-header .heading {
    font-size: 1.156rem;
    font-family: lato-heavy;
    line-height: 1.16;
    letter-spacing: 1.39px;
    text-align: center;
    color: #ffffff;
    margin: 10px auto;
  }
  .card-body {
    .color-body {
      background-color: #f4fbff;
      align-items: center;
    }
    .engagement-padding {
      padding: 15px 20px;
    }
    .engagement-paragraph {
      font-size: 1rem;
      line-height: 21.5px;
      letter-spacing: 0.4px;
      color: #18395d;
      margin-bottom: 0;
    }
    .engagement-paraHeight {
      min-height: 138px;
    }
    .light-blue-bg {
      background-color: #e0edf5;
      width: 100%;
      min-height: 112px;
    }
    .white-bg {
      background-color: #ffffff;
    }
    .Engagement-Duration {
      font-family: "lato-heavy";
      font-size: 1.125rem;
      line-height: 21.5px;
      letter-spacing: 0.45px;
      color: #185780;
      margin-bottom: 13px;
    }
    .Engagement-Time {
      font-size: 1rem;
      line-height: 1.22;
      letter-spacing: 0.4px;
      text-align: center;
      color: #18395d;
    }
    .engagement-footer {
      background-color: #134067;
      border-radius: 0 0 20px 20px;
    }
    .engagement-hire-btn {
      background-color: #ffffff;
      border-radius: 3px;
      color: #122235;
      font-family: "lato-black";
      font-size: 0.813rem;
      letter-spacing: 0.33px;
      padding: 7px 25px;
      text-transform: uppercase;
    }
  }
}
.bg-black-navbar {
  background: #000000bd;
  box-shadow: 0px 10px 10px 0px #000;
}

.how-page,
.what-page,
.about-page {
  .tab-content {
    .nav-pill-position {
      margin-top: -53px;
    }
  }
}

.tab-content .nav-pill {
  font-family: "lato-bold";
  display: flex;
  align-items: center;
  padding: 0;
  margin: 3px -12px;
  letter-spacing: 0.45px;
  text-transform: uppercase;
}
// Login Page
.Login-page .Banner {
  background-image: url("../assets/images/login-page-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.privacy-text {
  color: #4c677b;
}

.Login-page .back-link,
.BasicIngo-Page .header-text,
.Password-Creation-Page .header-text,
.Survey-Page .header-text {
  font-family: "lato-medium";
  font-size: 0.875rem;
  line-height: 1.19;
  letter-spacing: 0.8px;
  color: #4c677b;
  display: flex;
  align-items: center;
  margin-right: 50px;
  .arrow {
    font-size: 16px;
    color: #4c677b;
    margin-right: 5px;
  }
  a {
    color: #14b9b8;
  }
}

.Login-page .bank-arrow-position {
  margin: 15px 0 0 30px;
}

.Login-page .login-page-logo {
  width: 275px;
  display: block;
  margin: 20px auto 30px;
}

.Login-page .card {
  width: 60%;
  margin: 50px auto 0;
  border: none;
}

.password-verification .card {
  width: 60%;
  margin: 125px auto 0;
  border: none;
}

.password-verification .card-body .input-group-prepend .input-group-text {
  color: #b2c8d8;
  padding: 0 5px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-bottom: 1.3px solid #509ec6;
  border-radius: 0;
}

.Login-page .welcome-back-label,
.verify-page .verification-label {
  font-family: "lato-bold";
  font-size: 1.625rem;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #134067;
  margin: 10px auto;
}

.Login-page .please-login-line,
.verify-page .please-login-line {
  font-family: "lato-medium";
  font-size: 1.125rem;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #134067;
  margin-bottom: 50px;
}

.verify-page .please-login-line {
  margin-bottom: 10px;
}

.password-verification .please-login-line {
  margin-bottom: 60px;
}
.Login-page .login-form,
.Password-Creation-Page .password-creation-form {
  line-height: 1.19;
  letter-spacing: 0.8px;
  text-align: left;
  color: #4c677b;
  .form-label {
    font-family: "lato-medium";
    font-size: 0.875rem;
    margin-bottom: 0px;
  }
  .form-control {
    border: none;
    border-bottom: 1.3px solid #509ec6;
    border-radius: 0;
    padding: 0;
    font-size: 1.188rem;
    color: #4c677b;
    margin-bottom: 2px;
  }
  .form-control::placeholder {
    line-height: 1.21;
    letter-spacing: 0.48px;
    text-align: left;
    color: #b2c8d8;
    font-size: 14px;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: #4c677b;
  }
  .form-control:focus:-moz-placeholder {
    color: #4c677b;
  }
  .form-control:focus::-moz-placeholder {
    color: #4c677b;
  }
  .form-control:focus:-ms-input-placeholder {
    color: #4c677b;
  }
  .form-control:focus {
    box-shadow: none;
    background-color: rgba(20, 185, 184, 0.11);
  }
  a.forgot-password {
    float: right;
    font-family: "lato-medium";
    font-size: 0.875rem;
    line-height: 1.19;
    letter-spacing: 0.8px;
    color: #4c677b;
  }
}
.Login-page .login-form .btn {
  background-color: #14b9b8;
  font-family: "lato-bold";
  line-height: 1.19;
  letter-spacing: 0.8px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  margin: 35px auto 30px;
}
.Login-page .login-form .btn:hover {
  color: #ffffff;
}

.Login-page .sign-up-link {
  color: #4c677b;
  font-family: "lato-medium";
  line-height: 1.24;
  letter-spacing: 0.43px;
  text-align: center;
  .sign-up {
    font-family: "lato-bold";
    color: #14b9b8;
  }
}

.Login-page .content-background {
  background-image: linear-gradient(to top, #052f53, rgba(1, 27, 46, 0) 75%);
  height: 100vh;
}

.Login-page .banner-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
  padding-left: 75px;
  .banner-heading {
    font-family: "lato-bold";
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    font-size: 2.375rem;
  }
  .blueDash {
    padding: 0.2em 0 0;
    width: 2em;
    background-color: #14b9b8;
  }
  .banner-tagline {
    font-family: "lato-medium";
    line-height: 1.22;
    letter-spacing: 0.58px;
    text-align: left;
    width: 400px;
    color: #ffffff;
    margin: 20px 0;
  }
}

.verify-page .verification-label {
  margin: 100px auto 10px;
}

.verify-page .login-form .btn {
  margin: 35px auto 30px;
}
.forgot-password-page .card .card-body .login-form .btn {
  margin: 5px auto 30px;
}
.forgot-password-page .row .forgot-password-card {
  margin: 160px auto 0;
}
//forgot-password-page
.forgot-password-page .password-reset-label,
.create-password-page .new-password-label {
  font-family: "lato-heavy";
  font-size: 1.2rem;
  text-align: center;
  color: #134067;
  display: block;
}

.forgot-password-page .login-page-logo {
  margin: 20px auto;
}

.forgot-password-page .verification-label {
  margin: 75px auto 10px;
}
//create-password-page
.create-password-page .login-page-logo {
  margin: 20px auto;
}
.create-password-page .card {
  margin: 20px auto 0;
}
.create-password-page .new-password-label {
  margin-bottom: 25px;
}
.create-password-page .login-form .form-control,
.Password-Creation-Page .password-creation-form .form-control {
  height: calc(1.5em + 0.75rem + 0px);
  font-size: 1.5rem;
  color: #4c677b;
}
.create-password-page .login-form .btn {
  margin: 20px auto 25px;
}
.create-password-page .login-form .form-label {
  margin-bottom: 0px;
}
.create-password-page .password-req-wrapper,
.Password-Creation-Page .password-req-wrapper {
  line-height: 1.39;
  letter-spacing: 0.9px;
  text-align: left;
  color: #4c677b;
  .req-header {
    font-family: "lato-bold";
    font-size: 0.875rem;
  }
  .list-item {
    margin: 18px 0;
    font-size: 0.875rem;
  }
}
.create-password-page .eye-img {
  position: absolute;
  right: 25px;
  width: 25px;
  margin-top: -30px;
  cursor: pointer;
}
.Password-Creation-Page .eye-img {
  position: absolute;
  right: 5px;
  width: 25px;
  margin-top: -30px;
  cursor: pointer;
}
.Password-Creation-Page .margin-bottom50 {
  margin-bottom: 3.125rem !important;
}

//BasicInfo
.BasicIngo-Page .dark-bckground,
.Password-Creation-Page .dark-bckground,
.Survey-Page .dark-bckground {
  background-color: #134067;
  height: 100vh;
}
.BasicIngo-Page .Banner {
  background-image: url("../assets/images/basic-info-banner.svg");
  background-size: 85% 92%;
  background-repeat: no-repeat;
  height: 100%;
  background-position-x: 100%;
  width: 100%;
}

.BasicIngo-Page .basic-info-card,
.Survey-Page .survey-card,
.Password-Creation-Page .basic-info-card {
  height: 100vh;
  border: none;
}
.BasicIngo-Page .basic-info-card .form-fields-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0 60px;
}
.Password-Creation-Page .basic-info-card .card-body,
.Congrats-Page .basic-info-card .card-body {
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
.BasicIngo-Page .basic-info-card .card-body {
  padding: 0px;
  .form-group {
    width: 60%;
  }
  .input-group {
    width: 60%;
  }
}
.BasicIngo-Page .card-body-wrapper,
.Password-Creation-Page .card-body-wrapper,
.Survey-Page .card-body-wrapper {
  display: flex;
  align-items: baseline;
  overflow: auto;
}
.BasicIngo-Page .navbar-dark,
.Password-Creation-Page .navbar-dark,
.Survey-Page .navbar-dark {
  background-color: #134067;
  height: 50px;
}
.BasicIngo-Page .card-footer,
.Survey-Page .card-footer,
.Password-Creation-Page .card-footer {
  background-color: #134067;
  height: 64px;
  width: 50%;
  position: fixed;
  bottom: 0;
}
.Password-Creation-Page .card-footer {
  background-color: #134067;
  height: 64px;
}
.BasicIngo-Page .continue-btn,
.Password-Creation-Page .continue-btn,
.Survey-Page .continue-btn {
  background-color: #14b9b8;
  border-radius: 3px;
  color: #ffffff;
  font-family: "lato-bold";
  font-size: 0.813rem;
  line-height: 1.19;
  letter-spacing: 0.8px;
  padding: 10px 39px;
  text-align: center;
  display: flex;
  align-items: center;
}
.Congrats-Page .login-btn {
  padding: 10px 60px;
}
.BasicIngo-Page .header-text,
.BasicIngo-Page .header-text .arrow,
.Password-Creation-Page .header-text,
.Password-Creation-Page .header-text .arrow,
.Survey-Page .header-text,
.Survey-Page .header-text .arrow {
  color: #cfe6f2;
}
.BasicIngo-Page .navbar .sign-in .Password-Creation-Page .navbar .sign-in {
  color: #14b9b8;
  font-family: "lato-bold";
  font-size: 0.875rem;
  line-height: 1.24;
  letter-spacing: 0.43px;
}
.BasicIngo-Page .heading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.BasicIngo-Page .basic-form-headline {
  width: 60%;
}
.BasicIngo-Page .basic-form-headline,
.Survey-Page .basic-form-headline {
  font-family: "lato-heavy";
  font-size: 1.2rem;
  line-height: 1.21;
  padding: 25px 0 5px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
  color: #134067;
  background-color: #ffffff;
}
.Password-Creation-Page .basic-form-headline {
  font-family: "lato-heavy";
  font-size: 1.2rem;
  line-height: 1.21;
  margin: 5px 0 15px;
  letter-spacing: normal;
  text-align: left;
  color: #134067;
}

.BasicIngo-Page input.largerCheckbox {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.BasicIngo-Page .checkbox-text,
.Survey-Page .checkbox-text {
  margin-left: 40px;
  margin-top: -3px;
  font-size: 0.875rem;
  cursor: pointer;
}

.BasicIngo-Page .site-logo,
.Password-Creation-Page .site-logo,
.Survey-Page .site-logo {
  width: 250px;
  padding: 40px 0 0 50px;
  position: absolute;
}

.BasicIngo-Page .trustedDev-register-label,
.Password-Creation-Page .trustedDev-register-label,
.Survey-Page .trustedDev-register-label {
  position: absolute;
  bottom: 20px;
  margin-left: 50px;
  font-family: "lato-bold";
  font-size: 1.8rem;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.BasicIngo-Page .blue-longdash,
.Password-Creation-Page .blue-longdash {
  padding: 0.15em 0 0 0;
  background-color: #14b9b8;
  width: 1.7em;
  position: absolute;
  bottom: 75px;
  left: 50px;
}

.BasicIngo-Page .basic-info-form {
  line-height: 1.19;
  letter-spacing: 0.8px;
  text-align: left;
  color: #4c677b;
  .form-label {
    font-family: "lato-medium";
    font-size: 0.75rem;
    margin-bottom: 0px;
  }
  .form-control {
    border: none;
    border-bottom: 1.3px solid #509ec6;
    border-radius: 0;
    padding: 0;
    font-size: 0.938rem;
    color: #4c677b;
  }
  .form-control::placeholder {
    line-height: 1.21;
    letter-spacing: 0.48px;
    text-align: left;
    color: #b2c8d8;
    font-size: 14px;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: #4c677b;
  }
  .form-control:focus:-moz-placeholder {
    color: #4c677b;
  }
  .form-control:focus::-moz-placeholder {
    color: #4c677b;
  }
  .form-control:focus:-ms-input-placeholder {
    color: #4c677b;
  }
  .form-control:focus {
    box-shadow: none;
    background-color: rgba(20, 185, 184, 0.11);
  }
}
// checkbox button CSS
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkbox-label .checkbox-custom {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #ffffff;
}
.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #4c677b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #4c677b;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
//Radio button
input[type="radio"]:not(old) {
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
  font-size: 1em;
  opacity: 0;
  cursor: pointer;
}

input[type="radio"]:not(old) + label {
  display: inline-block;
  margin: 0px 0 0 -22px;
}

input[type="radio"]:not(old) + label > span {
  display: inline-block;
  height: 22px;
  width: 22px;
  margin: 0.25em 0.5em 0.25em 0;
  border: 2px solid #4c677b;
  background-color: #4c677b;
  background-image: -moz-linear-gradient(#ffffff, #ffffff);
  background-image: -ms-linear-gradient(#ffffff, #ffffff);
  background-image: -o-linear-gradient(#ffffff, #ffffff);
  background-image: -webkit-linear-gradient(#ffffff, #ffffff);
  background-image: linear-gradient(#ffffff, #ffffff);
  vertical-align: bottom;
}

input[type="radio"]:not(old):checked + label > span {
  background-image: -moz-linear-gradient(#ffffff, #ffffff);
  background-image: -ms-linear-gradient(#ffffff, #ffffff);
  background-image: -o-linear-gradient(#ffffff, #ffffff);
  background-image: -webkit-linear-gradient(#ffffff, #ffffff);
  background-image: linear-gradient(#ffffff, #ffffff);
}

input[type="radio"]:not(old):checked + label > span > span {
  display: block;
  width: 13px;
  height: 13px;
  margin: 0.185em;
  border: 0.0625em solid #4c677b;
  background-color: #4c677b;
  background-image: -moz-linear-gradient(#4c677b, #4c677b);
  background-image: -ms-linear-gradient(#4c677b, #4c677b);
  background-image: -o-linear-gradient(#4c677b, #4c677b);
  background-image: -webkit-linear-gradient(#4c677b, #4c677b);
  background-image: linear-gradient(#4c677b, #4c677b);
}

/* Style for Circular Checkbox */
.checkbox-label .checkbox-custom.circular {
  border-radius: 5%;
  border: 2px solid #4c677b;
}
.checkbox-label input:checked ~ .checkbox-custom.circular {
  background-color: #ffffff;
  border-radius: 5%;
  border: 2px solid #4c677b;
}
.checkbox-label input:checked ~ .checkbox-custom.circular::after {
  border: solid #4c677b;
  border-width: 0 2px 2px 0;
}
//Survey-Page
.Survey-Page .header-Banner {
  background-image: url("../assets/images/survey-page-banner.svg");
  background-size: 108% 108%;
  background-repeat: no-repeat;
  height: 91%;
  background-position-x: 100%;
  background-position-y: 0%;
  width: 100%;
}

.Survey-Page .navbar-dark {
  height: 75px;
}

.Survey-Page .back-arrow,
.Password-Creation-Page .back-arrow,
.Congrats-Page .back-arrow,
.BasicIngo-Page .back-arrow {
  margin-right: 10px;
  margin-left: -8px;
  width: 7px;
}

.Survey-Page .basic-form-headline {
  margin: 20px 0;
  text-align: left;
}
.Survey-Page .card-body-wrapper .card-body {
  padding: 0px;
}

.Survey-Page .card-body-wrapper .form-field-wrapper {
  padding: 0 125px;
  align-items: flex-start;
}

.Survey-Page .questionnaire-form {
  font-family: "lato-medium";
  line-height: 1.19;
  letter-spacing: 0.8px;
  text-align: left;
  color: #4c677b;
  .form-label {
    font-size: 1rem;
    margin-bottom: 0;
    padding-right: 75px;
  }
  .form-control {
    border: none;
    border-bottom: 1.3px solid #509ec6;
    border-radius: 0;
    padding: 0 0 0 5px;
    font-size: 0.938rem;
    color: #b2c8d8;
  }
  .form-group {
    margin-bottom: 40px;
  }
}
.Survey-Page .card-footer,
.Password-Creation-Page .card-footer,
.Congrats-Page .card-footer {
  display: flex;
  justify-content: space-evenly;
}
.Survey-Page .gray-btn,
.Password-Creation-Page .gray-btn,
.Congrats-Page .gray-btn {
  background-color: #cfe6f2;
  color: #134067;
}
.Survey-Page .radiobtnbox-text {
  cursor: pointer;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 0.875rem;
}
//Password-Creation-Page
.Password-Creation-Page .header-Banner {
  background-image: url("../assets/images/password-creation-banner.svg");
  background-size: 110% 96%;
  background-repeat: no-repeat;
  height: 100%;
  background-position-x: 100%;
  background-position-y: 0%;
  width: 100%;
}
.Password-Creation-Page .card-body-wrapper .card-modal {
  width: 60%;
  position: relative;
}
.Password-Creation-Page .basic-info-card .card-body {
  height: 84vh;
}
//Congrats-Page
.Congrats-Page .header-Banner {
  background-image: url("../assets/images/Congratulations-banner.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 95%;
  background-position-x: 100%;
  background-position-y: 0%;
  margin-top: 25px;
  width: 100%;
}
.Congrats-Page .basic-info-card .card-body {
  width: 100%;
  text-align: center;
}
.Congrats-Page .check-mail-label {
  font-family: "lato-bold";
  font-size: 1rem;
  color: #4c677b;
  line-height: 1.39;
  margin-bottom: 60px;
}
.Congrats-Page .details-wrapper {
  font-family: "lato-medium";
  font-size: 0.938rem;
  color: #4c677b;
  .contactUs-link {
    color: #14b9b8;
    font-family: "lato-bold";
  }
  a {
    color: #4c677b;
  }
}
.Congrats-Page .right-tick {
  width: 75px;
  margin-bottom: 25px;
}
.Congrats-Page .congrats-headline {
  font-family: "lato-heavy";
  font-size: 1.625rem;
  line-height: 1.21;
  margin: 5px 0 15px;
  letter-spacing: normal;
  text-align: left;
  color: #134067;
}
.Survey-Page .flex-flow {
  flex-flow: wrap;
}
.Survey-Page .optOther-textfield.form-control {
  border: none;
  outline: none;
  border-bottom: 1.3px solid #509ec6;
  border-radius: 0;
  padding: 0 0 0 5px;
  font-size: 0.875rem;
  color: #4c677b;
  margin: 10px 0 0 0;
  width: 75%;
}
.Survey-Page .optOther-textfield.form-control:focus::-webkit-input-placeholder,
.Survey-Page .optOther-textfield.form-control:focus:-moz-placeholder,
.Survey-Page .optOther-textfield.form-control:focus::-moz-placeholder,
.Survey-Page .optOther-textfield.form-control:focus:-ms-input-placeholder {
  color: #4c677b;
}
.Survey-Page .optOther-textfield.form-control:focus {
  box-shadow: none;
  background-color: rgba(20, 185, 184, 0.11);
}
//Progress Bar
.Congrats-Page .progress-bar-wrapper,
.Survey-Page .progress-bar-wrapper,
.Password-Creation-Page .progress-bar-wrapper {
  .progress {
    height: 0.5rem;
    margin: 0 3px;
    &-one {
      border-radius: 10px 0px 0px 10px;
    }
    &-two {
      border-radius: 0;
    }
    &-three {
      border-radius: 0;
    }
    &-four {
      border-radius: 0px 10px 10px 0px;
    }
  }
  .progress-bar-active {
    background-color: #14b9b8;
  }
  .progress-bar-inactive {
    background-color: #1c578b;
  }
  .progress-bar-label {
    font-family: "lato-medium";
    font-size: 0.75rem;
    margin-bottom: 0;
    line-height: 1.19;
    letter-spacing: 0.8px;
    text-align: center;
    margin: 0 25px;
  }
  .active-label {
    color: #cfe6f2;
  }
  .inactive-label {
    color: #1c578b;
  }
  .last-progress-bar {
    margin: 0 10px;
  }
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-dark.navbar-toggler {
  border-color: #134067;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

button:focus {
  outline: none;
}

.capital_letter {
  text-transform: uppercase;
}

.list_type_none {
  list-style-type: none;
}

.pointer {
  cursor: pointer;
}

.nav-tabs .nav-link.active {
  background-color: #e5f5ff;
}

.bg_cto_theme .nav-tabs .nav-link.active {
  background-color: #ecf8ff;
  color: #2f254c;
}
.bg_cto_theme .passive_tab {
  color: #7664ac;
  background-color: #23193f;
}

.nav-tabs {
  border-bottom: 0px;
}

.passive_tab:hover,
.active_tab:hover {
  color: #ffffff;
  border: 0px;
}

.letter_spacing {
  letter-spacing: 2px;
}

.icon_position {
  font-size: 36px;
  margin-left: -21px;
  margin-top: -75px;
  position: absolute;
}

.page_divider_banner {
  background-image: url("../assets/images/layer-26.png");
  background-size: 100%, cover;
  background-repeat: no-repeat;
  height: 450px;
  margin-top: -40px;
  background-position: 100% 75%;
}

.page_bottom_divider_banner {
  background-image: url("../assets/images/layer-26_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: auto;
}
.ctoServicePortal-tab .page_bottom_divider_banner {
  background-image: url("../assets/images/layer-26.png");
  background-size: 106%, cover;
  background-repeat: no-repeat;
  height: 450px;
  background-position: 100% 99%;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .nav-link {
  border: none;
}
.icon_chart {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.ctoServicePortal-tab .semicircle-img {
  height: 550px;
  margin: 43px auto 0px;
}

.ctoServicePortal-tab .banner-image {
  position: absolute;
  width: 90px;
  &-step1 {
    margin: 375px 0 0 0px;
  }
  &-step2 {
    margin: 160px 0px 0 140px;
  }
  &-step3 {
    margin: 35px 0 0 355px;
  }
  &-step4 {
    margin: 35px 0 0 660px;
  }
  &-step5 {
    margin: 160px 0 0 855px;
  }
  &-step6 {
    margin: 375px 0 0 985px;
  }
}

.ctoServicePortal-tab .banner-step {
  font-family: "lato-black";
  position: absolute;
  letter-spacing: 0.54px;
  font-size: 0.938rem;
  text-align: center;
  color: #ffffff;
  &-number1 {
    margin: 350px 0 0 0;
  }
  &-number2 {
    margin: 130px 0 0 140px;
  }
  &-number3 {
    margin: 10px 0 0 390px;
  }
  &-number4 {
    margin: 10px 0 0 695px;
  }
  &-number5 {
    margin: 130px 0 0 925px;
  }
  &-number6 {
    margin: 350px 0 0 1060px;
  }
}

.ctoServicePortal-tab .banner-heading {
  font-family: "lato-black";
  position: absolute;
  line-height: 1;
  letter-spacing: 0.54px;
  font-size: 0.938rem;
  color: #b9a6d6;
  text-transform: uppercase;
  &-step1 {
    text-align: left;
    margin: 390px 0 0 105px;
  }
  &-step2 {
    text-align: left;
    width: 200px;
    margin: 263px 0 0 140px;
  }
  &-step3 {
    margin: 135px 0 0 305px;
  }
  &-step4 {
    margin: 135px 0 0 650px;
  }
  &-step5 {
    margin: 263px 0 0 735px;
    text-align: right;
    width: 205px;
  }
  &-step6 {
    margin: 390px 0 0 770px;
    text-align: right;
    width: 205px;
  }
}

.ctoServicePortal-tab .banner-content {
  line-height: 1.3;
  font-size: 0.813rem;
  text-align: left;
  position: absolute;
  color: #ffffff;
  &-step1 {
    margin: 410px 0 0 105px;
    width: 200px;
  }
  &-step2 {
    margin: 301px 0 0 140px;
    width: 230px;
  }
  &-step3 {
    margin: 155px 0 0 290px;
    width: 230px;
    text-align: center;
  }
  &-step4 {
    margin: 155px 0 0 595px;
    width: 230px;
    text-align: center;
  }
  &-step5 {
    margin: 301px 0 0 695px;
    width: 245px;
    text-align: right;
  }
  &-step6 {
    margin: 410px 0 0 750px;
    width: 225px;
    text-align: right;
  }
}

.ctoServicePortal-tab .banner-center-heading {
  position: absolute;
  font: 1.625rem "lato-semibold";
  line-height: 1;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
  margin: 330px 0 0 425px;
  width: 240px;
  .text-style {
    font-family: "lato-black";
    text-transform: uppercase;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.privacy-policy .AI-paragraph, 
.privacy-policy .text-style {
  text-align: start;
}

.privacy-page .page_divider_banner {
  margin: 0;
  height: auto !important;
}

.enter-email-home {
  padding: 0 16px;
  text-align: center;
  border: none;
  border-top-right-radius: 3px;
  background-color: #09b6b4;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.33px;
  border-bottom-right-radius: 3px;
  box-shadow: none;
  height: 40px;
}

.enter-email--cto {
  background-color: #EF6D66;
}

.enter-email-input {
  display: inline-block;
  height: 40px;
  width: 300px;
  border:none;
  font-size: 13px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  // line-height: 40px;
  padding: 15px;
  outline: none;
}

/********** Media queries start **********/

/* Devices with more than 2560px width(4K device) */
@media (min-width: 2560px) {
  .how-page .how-page-banner {
    background-image: url("../assets/images/HeroBanners/Desktop4kHow.jpg");
    background-position-y: 33%;
    background-color: #010101;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
  }
  .about-page .why-page-banner {
    background-image: url("../assets/images/HeroBanners/Desktop4kWhy.jpg");
    background-position-y: 37%;
  }
  .what-page .what-page-banner {
    background-image: url("../assets/images/HeroBanners/Desktop4kWhat.jpg");
  }
  /* Custom SAAS Page */
  .Custom-SAAS-page .Custom-Saas-page-banner {
    background-image: url("../assets/images/HeroBanners/RunnerTest4K.jpg");
    height: 800px;
    background-color: #010101;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
  }
  .Guy_Animation {
    #guy1,
    #guy2,
    #guy3,
    #guy4,
    #guy5,
    #guy6,
    #guy7,
    #guy8,
    #guy9,
    #guy10,
    #guy11,
    #guy12,
    #gu13,
    #guy14,
    #guy15,
    #guy16,
    #guy17,
    #guy18,
    #guy19 {
      height: 500px;
      margin-left: 20px;
    }
  }
  /* AI Page */
  .AI-page {
    .AI-page-banner {
      background-image: url("../assets/images/HeroBanners/AITEst4K.jpg");
      height: 800px;
      background-size: contain;
      background-color: #031420;
      background-size: contain;
      background-position-y: center;
      background-position-x: center;
  
    }
    .AI-page-BackgroundColor {
      height: 810px;
    }
    .page_divider_banner .Enterprise-Benefits .col-xl-10 {
      flex: 0 0 72.12%;
      max-width: 72.12%;
      .col-xl-10 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      }
      .offset-xl-2 {
        margin-left: 39.66667%;
      }
    }
  }
  .what-page {
    .Expert-software-engineer-background-banner {
      height: 625px;
      margin-bottom: -50px;
    }
  }
  .trusteddev-tab .dotted_line {
    height: 82%;
  }
  .trusteddev-tab {
    .Implementation-of-AI .col-xl-10,
    .Dedicated-Customer .col-xl-10 {
      flex: 0 0 1846px;
      max-width: 1846px;
    }
    .Implementation-of-AI .col-xl-10 .offset-xl-2 {
      margin-left: 18.66667%;
    }
    .Dedicated-Customer .blue_bar_page_divider .col-xl-9 {
      flex: 0 0 62%;
      max-width: 62%;
    }
  }
}

/* Devices with more than 1900px width  and less than 2559.98px */
@media (min-width: 1900px) and (max-width: 2559.98px) {
  .how-page .how-page-banner {
    background-image: url("../assets/images/HeroBanners/DesktopHD2208How.jpg");
    background-position-y: 32%;
  }
  .about-page .why-page-banner {
    background-image: url("../assets/images/HeroBanners/DesktopHD2208Why.jpg");
    background-position-y: 37%;
  }
  /* Custom SAAS Page */
  .Custom-SAAS-page .Custom-Saas-page-banner {
    height: 800px;
    // background-image: url("../assets/images/HeroBanners/RunnerTest4K.jpg");
    background-position-y: 55px;
    background-position-x: center;
    background-size: cover;
  }
  .Guy_Animation {
    #guy1,
    #guy2,
    #guy3,
    #guy4,
    #guy5,
    #guy6,
    #guy7,
    #guy8,
    #guy9,
    #guy10,
    #guy11,
    #guy12,
    #gu13,
    #guy14,
    #guy15,
    #guy16,
    #guy17,
    #guy18,
    #guy19 {
      height: 500px;
      margin-left: 20px;
    }
  }
  .what-page {
    .Expert-software-engineer-background-banner {
      height: 625px;
      margin-bottom: -50px;
    }
  }
  /* AI Page */
  .AI-page {
    .AI-page-banner {
      height: 860px;
      background-image: url("../assets/images/HeroBanners/AITEst4K.jpg");
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
    }
    .AI-page-BackgroundColor {
      height: 870px;
    }
  }
  .trusteddev-tab .dotted_line {
    height: 82%;
  }
}

@media (min-width: 1920px) {
  .card.Vetting-Methodology-card {
    max-width: 852px;
    margin: auto;
  }
  .trusteddev-tab,
  .AI-page {
    .home-page-banner .page_divider_banner .row .col-xl-8,
    .page_divider_banner .row .col-xl-8 {
      flex: 0 0 1132px;
      max-width: 1132px;
    }
  }
  .about-page {
    .card-header.card_border.bg_sky_blue,
    .card-body.card_body_color {
      .why-trustedDev {
        margin-left: 15px;
      }
      .offset-xl-3 {
        margin-left: 33.33%;
      }
      .col-xl-9 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
      }
      .Rectangle-wrapper .rectangle {
        padding: 35px 15px 20px;
      }
    }
  }
  .about-page .row.no-gutters .col-xl-10,
  .col-xl-10.logo_divider_row {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .how-page {
    .card .card-body.card_body_color .row .offset-xl-3 {
      margin-left: 35.33%;
    }
  }
  /* Engagement-page */
  .Engagement-page {
    .trusteddev-tab .engagement-page-banner {
      height: calc(100vh - 195px);
    }
    .card-body {
      .engagement-paraHeight {
        min-height: 120px;
      }
      .engagement-padding {
        padding: 28px 60px 15px;
      }
    }
    .blue-shadeBg {
      .engagement-card-bottom {
        .col-xl-3,
        .col-lg-4 {
          max-width: 600px;
          width: 600px;
          flex: 0 0 600px;
        }
      }
    }
  }
}

/* Devices with more than 1600px width and less than 1899.98px */
@media (min-width: 1600px) and (max-width: 1899.98px) {
  html {
    font-size: 16px;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 900px;
  }
  .trusteddev-tab .circle_blue {
    &_large {
      top: 527px;
    }
  }
  .trusteddev-tab {
    .Animations_Circle {
      width: 115px;
      margin: -60px 0 0 -55px;
    }
    .Animations_Clock {
      width: 115px;
      margin: -65px 0 0 -55px;
    }
    .Animations_Bar {
      width: 100px;
      margin: -30px 0 0 -50px;
    }
    .Animations_Cog {
      width: 100px;
      margin: 5px 0 0 0px;
    }
  }
  .trusteddev-tab .digit-96,
  .AI-page .digit-96 {
    margin-left: 20px;
  }
  .trusteddev-tab .Belive-AI-content,
  .AI-page .Belive-AI-content {
    margin-left: 15px;
  }
  .trusteddev-tab .cost-of-not-automating-content,
  .AI-page .cost-of-not-automating-content {
    font-size: 1.5rem;
    margin-left: 15px;
  }
  .trusteddev-tab .list-item-content,
  .AI-page .list-item-content {
    margin-left: 18px;
  }
  .trusteddev-tab .four_box_center_img,
  .AI-page .four_box_center_img {
    width: 95px;
  }
  .trusteddev-tab .dotted_line {
    height: 86.5%;
  }
  /* AI Page */
  .AI-page {
    .AI-page-banner {
      height: 722px;
    }
    .AI-page-BackgroundColor {
      height: 732px;
    }
  }
  /* About Page */
  .how-page .how-page-banner {
    background-position-y: 25%;
  }
  /* Custom SAAS Page */
  .Custom-SAAS-page .Custom-Saas-page-banner {
    height: 800px;
    background-position-y: 55px;
  }
  .what-page {
    .Expert-software-engineer-section-body {
      height: 515px;
    }
    .Expert-software-engineer-background-banner {
      height: 635px;
      margin-bottom: -50px;
    }
  }
  .Guy_Animation {
    #guy1,
    #guy2,
    #guy3,
    #guy4,
    #guy5,
    #guy6,
    #guy7,
    #guy8,
    #guy9,
    #guy10,
    #guy11,
    #guy12,
    #gu13,
    #guy14,
    #guy15,
    #guy16,
    #guy17,
    #guy18,
    #guy19 {
      height: 500px;
      margin-left: 20px;
    }
  }
}

@media (min-width: 1600px) {
  .helper-inner {
    max-width: 720px;
    margin: 0 auto;
  }

  div.helper-text {
    width: 100%;
    
    
    padding: 2rem 0 0 0rem !important;
    position: absolute;
    z-index: 1;
    height: 320px;
  }

  div.helper-title {
    font-size: 2rem;
    color: #509EC6;
    margin-bottom: 1rem;
  }

  div.helper-list-item {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  div.helper-link {
    color: #4C677B;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      color: #4C677B;
      opacity: 0.9;
    }
  }

  div.basic-info-form {

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  
  }

  .Vetting-Methodology-card .col-6:first-child {
    padding-bottom: 40px;
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-top: 0px !important;
      padding-bottom: 50px;
    }
  }

  .trusteddev-tab .dotted_line {
    height: 89%;
  }

  .trusteddev-tab {
    .The-Implementation-of-AI-With-Software-Development-Resource-Vet {
      width: 1050px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .AI-page {
    .card .card_border .col-xl-8 h2.AI-trustedDev {
      width: 800px;
      margin: auto;
    }
  }
  .trusteddev-tab,
  .how-page {
    .The-TrustedDev-Vetting-Methodology-Card-Content,
    .How-TrustedDev-Works-Content,
    .How-TrustedDev-Works-Heading {
      max-width: 375px;
      margin-right: auto;
      margin-left: auto;
    }
    .Vetting-Methodology-step-heading {
      width: 275px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .what-page .what-content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  .AI-page {
    .AI-content {
      width: 830px;
      margin-left: auto;
      margin-right: auto;
    }
    .AI-paragraph {
      width: 850px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .about-page {
    .why-content {
      width: 875px;
      margin-left: auto;
      margin-right: auto;
    }
    .card-body .row .Rectangle-wrapper {
      width: 965px;
      margin-right: auto;
      margin-left: auto;
      .col-xl-4 {
        max-width: 321px;
      }
      .rectangle {
        height: 299px;
      }
    }
  }
  /* Basic Info*/
  .BasicIngo-Page {
    .basic-info-card .card-body .form-group {
      margin-bottom: 38px !important;
    }
    .card-body-wrapper {
      height: 100vh;
      align-items: center;
    }
  }
  .Engagement-page {
    .engagement-page-banner {
      height: 115vh;
      border-radius: 0;
    }
    .footer_block {
      margin-top: -80px;
    }
  }
  .Custom-SAAS-page {
    .row .card .card-body .col-xl-8 p.SAAS-paragraph {
      width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
    .middle-background-banner
      .Full-stack-development-img
      .row
      h3.Complex-Information-Security-By-Design {
      width: 750px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* Devices with more than 1400px width and less than 1599.98px */
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .Vetting-Methodology-card .col-6:first-child {
    padding-bottom: 20px;
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }
  .image {
    height: auto;
    margin: 0 20px;
    &_w100 {
      width: 100px;
    }
    &_w150 {
      width: 150px;
    }
    &_w160 {
      width: 160px;
    }
    &_PML_img {
      width: 100px;
      margin-left: -25px;
    }
  }
  .Guy_Animation {
    #guy1,
    #guy2,
    #guy3,
    #guy4,
    #guy5,
    #guy6,
    #guy7,
    #guy8,
    #guy9,
    #guy10,
    #guy11,
    #guy12,
    #gu13,
    #guy14,
    #guy15,
    #guy16,
    #guy17,
    #guy18,
    #guy19 {
      height: 445px;
      margin-left: 20px;
    }
  }
  /* AI Page */
  .AI-page {
    .AI-page-banner {
      height: 632px;
    }
    .AI-page-BackgroundColor {
      height: 642px;
    }
  }
  .how-page .how-page-banner {
    background-position-y: 20%;
  }
  .Custom-SAAS-page .Custom-Saas-page-banner {
    height: 710px;
    background-position-y: 70px;
  }
  .what-page {
    .Expert-software-engineer-section-body .impact-gif {
      width: 675px;
    }
    .Expert-software-engineer-background-banner {
      height: 564px;
      margin-bottom: -50px;
    }
    .Expert-software-engineer-section-body {
      height: 445px;
    }
  }
  .trusteddev-tab .dotted_line {
    height: 90%;
  }
  .Engagement-page {
    .blue-shadeBg {
      .engagement-card-bottom {
        .col-xl-3,
        .col-lg-4 {
          max-width: 29%;
          width: 29%;
          flex: 0 0 29%;
        }
      }
    }
  }
}

/* Devices with more than 1200px width and less than 1399.98px */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .image {
    margin: 0 20px;
    &_w100 {
      width: 70px;
    }
    &_w150 {
      width: 120px;
    }
    &_w160 {
      width: 130px;
    }
    &_PML_img {
      width: 70px;
      margin-left: -190px;
    }
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 900px;
  }
  .trusteddev-tab {
    .The-TrustedDev-Vetting-Methodology {
      font-size: 1.375rem;
    }
    .The-TrustedDev-Vetting-Methodology-text-style-1 {
      font-size: 1.813rem;
    }
    .The-TrustedDev-Vetting-Methodology-text-style-2 {
      font-size: 1.813rem;
    }
  }
  .trusteddev-tab
    .The-Implementation-of-AI-With-Software-Development-Resource-Vet,
  .trusteddev-tab
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
    font-size: 1.5rem;
    &_text-style-1 {
      font-size: 1.9rem;
    }
  }
  .page_bottom_divider_banner {
    background-size: 100%;
  }
  .trusteddev-tab .step-section,
  .what-page .step-section {
    height: 1050px;
  }
  .AI-page .step-section {
    height: 1130px;
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper .three-person-image {
    width: 70px;
    margin: -8px 0 0 45px;
  }
  .trusteddev-tab .Vetting-Methodology-step-heading {
    font-size: 1.125rem;
  }
  .trusteddev-tab .digit-96,
  .AI-page .digit-96 {
    font-size: 2.25rem;
    margin: 8px 0px 8px 15px;
  }
  .trusteddev-tab .Belive-AI-content,
  .AI-page .Belive-AI-content {
    font-size: 1rem;
    margin-left: 15px;
    small {
      margin-top: 6px;
    }
  }
  .trusteddev-tab .Average-amount-content small,
  .AI-page .Average-amount-content small,
  .trusteddev-tab .Belive-AI-content small,
  .AI-page .Belive-AI-content small {
    margin-left: 3px;
    position: absolute;
    font-size: 0.625rem;
  }
  .trusteddev-tab .hour24-content,
  .AI-page .hour24-content {
    font-size: 2.25rem;
  }
  .trusteddev-tab .Average-amount-content,
  .AI-page .Average-amount-content {
    font-size: 0.938rem;
    margin-right: 15px;
    small {
      margin-top: 7px;
    }
  }
  .trusteddev-tab .list-item-content,
  .AI-page .list-item-content {
    font-size: 1rem;
    margin-left: 10px;
  }
  .trusteddev-tab .cost-of-not-automating-content,
  .AI-page .cost-of-not-automating-content {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-top: 20px;
  }
  .trusteddev-tab .what-can-be-automated-content,
  .AI-page .what-can-be-automated-content {
    font-size: 1.2rem;
    margin-right: 10px;
    margin-top: 20px;
  }
  .trusteddev-tab .percentage-line-content .text-style,
  .AI-page .percentage-line-content .text-style {
    font-size: 1.125rem;
  }
  .trusteddev-tab .percentage-line-content,
  .AI-page .percentage-line-content {
    font-size: 0.875rem;
    margin-right: 15px;
  }
  .trusteddev-tab .dotted_line {
    height: 90%;
  }
  /* What Page */
  .Guy_Animation {
    #guy1,
    #guy2,
    #guy3,
    #guy4,
    #guy5,
    #guy6,
    #guy7,
    #guy8,
    #guy9,
    #guy10,
    #guy11,
    #guy12,
    #gu13,
    #guy14,
    #guy15,
    #guy16,
    #guy17,
    #guy18,
    #guy19 {
      height: 380px;
      margin-left: 20px;
    }
  }
  .what-page .Expert-software-engineer-background-banner {
    height: 516px;
    margin-bottom: -50px;
  }
  .what-page .Expert-software-engineer-section-body {
    height: 395px;
  }
  .what-page .Expert-software-engineer-section-body .impact-gif {
    width: 590px;
  }
  /* AI Page */
  .AI-page {
    .page_divider_banner {
      height: 860px;
    }
  }
  /* Custom SAAS Page */
  .Custom-SAAS-page .Custom-Saas-page-banner {
    height: 650px;
    background-position-y: 90px;
  }
  /* AI Page */
  .AI-page {
    .AI-page-banner {
      height: 540px;
    }
    .AI-page-BackgroundColor {
      height: 550px;
    }
  }
  .Engagement-page {
    .blue-shadeBg {
      .engagement-card-bottom {
        .col-xl-3,
        .col-lg-4 {
          max-width: 29%;
          width: 29%;
          flex: 0 0 29%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  div.helper-text {
    padding: 2rem 3.5rem 0 3rem;
    position: absolute;
    z-index: 1;
    height: 320px;
  }

  div.helper-title {
    font-size: 1.5rem;
    color: #509EC6;
    margin-bottom: 1rem;
  }

  div.helper-list-item {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  div.helper-link {
    color: #4C677B;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      color: #4C677B;
      opacity: 0.9;
    }
  }

  div.basic-info-form {

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  
  }
  
  .card-body-wrapper {
    
    height: calc(100% - 114px);
  }
  
  .card-body {
    width: 100%;
    height: 100%;
  }

  .Vetting-Methodology-card .col-6:first-child {
    // padding-bottom: 40px;
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }

  .trusteddev-tab {
    .banner_heading,
    .mobile-banner-logo {
      animation-delay: 0.1s;
    }
    .banner_paragraph,
    .mobile_hire_button {
      animation-delay: 0.3s;
    }
    .Vetting-Methodology-card {
      animation-delay: 0.4s;
      .identifyImg {
        animation-delay: 0.7s;
      }
      .cultureImg {
        animation-delay: 1.3s;
      }
      .ongoingAiImg {
        animation-delay: 1.9s;
      }
      .problemSolvingImg {
        animation-delay: 1s;
      }
      .testProjectImg {
        animation-delay: 1.6s;
      }
      .identifyContent {
        animation-delay: 0.8s;
      }
      .cultureContent {
        animation-delay: 1.4s;
      }
      .OngoingAiContent {
        animation-delay: 2s;
      }
      .problemSolvingContent {
        animation-delay: 1.1s;
      }
      .testProjectContent {
        animation-delay: 1.7s;
      }
      .dotted_line {
        animation-delay: 0.5s;
      }
      .circle_blue_large,
      .your-staff-is-ready-to-hire-wrapper {
        animation-delay: 2s;
      }
    }
    .card_line {
      &Left_1 {
        animation-delay: 0.6s;
      }
      &Left_3 {
        animation-delay: 1.2s;
      }
      &Left_5 {
        animation-delay: 1.8s;
      }
      &Right_2 {
        animation-delay: 0.9s;
      }
      &Right_4 {
        animation-delay: 1.5s;
      }
    }
    .Animations_Circle {
      width: 100px;
      margin: -60px 0 0 -50px;
    }
    .Animations_Clock {
      width: 100px;
      margin: -55px 0 0 -50px;
    }
    .Animations_Bar {
      width: 80px;
      margin: -25px 0 0px -40px;
    }
    .Animations_Cog {
      width: 90px;
      margin: 5px 0 0 -8px;
    }
    .your-staff-is-ready-to-hire-wrapper .ready-to-hire-label {
      margin: 0px 0 0 125px;
      width: 335%;
      font-size: 1.125rem;
    }
    .circle_blue_large {
      top: 100%;
    }
  }
  .trusteddev-tab .step-section,
  .what-page .step-section {
    height: 1290px;
  }
  .AI-page .step-section {
    height: 1350px;
  }
  .ctoServicePortal-tab .step-section {
    height: 1250px;
  }
  .AI-page .page_divider_banner {
    height: 860px;
  }
  .what-page .what-trustedDev {
    font-size: 1.65rem;
    .text-style-2 {
      font-size: 2rem;
    }
  }
}

/* Devices with more than 992px width and less than 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .basic-info-form {

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  
  }
  
  .card-body-wrapper {
    
    height: calc(100% - 114px);
  }
  
  .card-body {
    width: 100%;
    height: 100%;
  }

  .Vetting-Methodology-card .col-6:first-child {
    padding-bottom: 40px;
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }

  .navbar .site-logo {
    width: 135px;
  }
  .bg_cto_theme .cto-site-logo {
    width: 165px;
  }

  .trusteddev-tab .banner_heading {
    font-size: 38px;
    letter-spacing: 2px;
  }
  .trusteddev-tab .The-TrustedDev-Vetting-Methodology {
    font-size: 1.5rem;
  }
  .trusteddev-tab .Vetting-Methodology-step-heading {
    font-size: 1.125rem;
  }
  .trusteddev-tab .The-TrustedDev-Vetting-Methodology {
    &-text-style-1 {
      font-size: 26px;
    }
    &-text-style-2 {
      font-size: 26px;
    }
  }
  .trusteddev-tab {
    .Animations_Circle {
      width: 80px;
      margin: -40px 0 0 -40px;
    }
    .Animations_Clock {
      width: 80px;
      margin: -45px 0 0 -33px;
    }
    .Animations_Bar {
      width: 70px;
      margin: -30px 0 0px -38px;
    }
    .Animations_Cog {
      width: 75px;
      margin: 20px 0 0 -12px;
    }
  }
  .image {
    margin: 0 20px;
    &_w100 {
      width: 70px;
    }
    &_w150 {
      width: 120px;
    }
    &_w160 {
      width: 130px;
    }
    &_PML_img {
      width: 70px;
      margin-left: -245px;
    }
  }
  .trusteddev-tab .percentage-line-content,
  .AI-page .percentage-line-content {
    font-size: 0.875rem;
    margin-right: 10px;
    letter-spacing: 0;
  }

  .trusteddev-tab .Rectangle-box,
  .AI-page .Rectangle-box {
    min-height: 165px;
  }

  .trusteddev-tab .Belive-AI-content,
  .AI-page .Belive-AI-content {
    font-size: 0.875rem;
    margin-left: 10px;
  }

  .trusteddev-tab .digit-96,
  .AI-page .digit-96 {
    font-size: 1.875rem;
    margin-left: 10px;
  }

  .trusteddev-tab .Average-amount-content,
  .AI-page .Average-amount-content {
    font-size: 0.875rem;
    margin-right: 3px;
  }

  .trusteddev-tab .hour24-content,
  .AI-page .hour24-content {
    font-size: 1.875rem;
    margin-top: -7px;
    margin-bottom: 2px;
  }

  .trusteddev-tab .Average-amount-content .text-style,
  .AI-page .Average-amount-content .text-style {
    font-size: 1rem;
  }

  .trusteddev-tab .cost-of-not-automating-content,
  .AI-page .cost-of-not-automating-content {
    font-size: 1rem;
    margin-top: 15px;
    margin-left: 10px;
  }

  .trusteddev-tab .list-item-content,
  .AI-page .list-item-content {
    font-size: 0.875rem;
    margin-left: 10px;
  }

  .trusteddev-tab .what-can-be-automated-content,
  .AI-page .what-can-be-automated-content {
    font-size: 1rem;
    margin: 15px 10px 7px 0;
  }

  .trusteddev-tab .percentage-line-content .text-style,
  .AI-page .percentage-line-content .text-style {
    font-size: 1rem;
  }

  .trusteddev-tab
    .The-Implementation-of-AI-With-Software-Development-Resource-Vet,
  .trusteddev-tab
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
    line-height: 1.25;
    letter-spacing: 1.2px;
    font-size: 1.4rem;
    &_text-style-1 {
      letter-spacing: 1.4px;
      font-size: 1.7rem;
    }
  }
  .trusteddev-tab .order-section,
  .ctoServicePortal-tab .order-section,
  .what-page .order-section {
    padding: 35px 225px 50px;
  }

  .trusteddev-tab .step-section,
  .AI-page .step-section,
  .what-page .step-section,
  .ctoServicePortal-tab .step-section {
    height: 950px;
  }

  .AI-page .step-section {
    height: 1000px;
  }

  .ctoServicePortal-tab {
    .Brands-That-Our-Leadership-content,
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
      font-size: 1rem;
      &_text-style-1,
      &_bold-text,
      &_brand-text,
      .text-style-1,
      .text-style-2 {
        font-size: 1.4rem;
      }
    }
  }
  .what-page .what-trustedDev {
    font-size: 1.275rem;
    .text-style-2 {
      font-size: 1.5rem;
    }
  }
  .what-page .Expert-software-engineer-background-banner {
    height: 444px;
    margin-bottom: -51px;
  }
  .ctoServicePortal-tab .brands {
    grid-template-columns: repeat(auto-fit, minmax(120px, 2fr));
    grid-gap: 0.25rem;
  }
  .ctoServicePortal-tab .brands__item img {
    max-width: 90%;
  }
  .navbar .top-nav-logo {
    margin-left: -20px;
  }
  .icon-MobilecircleBgRtl {
    margin: 0px auto 10px;
  }
  .trusteddev-tab .dotted_line {
    height: 90%;
  }
  .trusteddev-tab .circle_blue_large {
    top: 100%;
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper .three-person-image {
    width: 75px;
    margin: -10px 8px 0 45px;
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper .ready-to-hire-label {
    margin: 0px 0 0 125px;
    font-size: 1.125rem;
    width: 350%;
  }
  .trusteddev-tab .step-img,
  .ctoServicePortal-tab .step-img {
    position: absolute;
    &-step1 {
      width: 325px;
      margin: 8px 0 0 -330px;
    }
    &-step2 {
      width: 315px;
      margin: 160px 0 0 15px;
    }
    &-step3 {
      margin: 345px 0px 0 -215px;
      width: 325px;
    }
    &-step4 {
      width: 200px;
      margin: 460px 0px 0 140px;
    }
    &-step5 {
      width: 215px;
      margin: 600px 0px 0 -390px;
    }
    &-step6 {
      width: 245px;
      margin: 735px 0px 0 10px;
    }
  }

  .trusteddev-tab .step-label,
  .ctoServicePortal-tab .step-label {
    position: absolute;
    font-size: 16px;
    font-family: "lato-black";
    line-height: 0.85;
    letter-spacing: 0.84px;
    text-align: center;
    color: #3f95cc;
    &-step1 {
      margin: 25px 0 0 -100px;
    }
    &-step2 {
      margin: 235px 0 0 -30px;
    }
    &-step3 {
      margin: 420px 0px 0 -260px;
    }
    &-step4 {
      margin: 585px 0px 0 85px;
    }
    &-step5 {
      margin: 700px 0px 0 -285px;
    }
    &-step6 {
      width: 215px;
      margin: 790px 0px 0 -100px;
    }
  }

  .trusteddev-tab .step-content,
  .ctoServicePortal-tab .step-content {
    width: 221px;
    position: absolute;
    line-height: 1.33;
    letter-spacing: 0.45px;
    text-align: center;
    color: #185780;
    font-size: 13px;
    font-family: "lato-semibold";
    &-step1 {
      margin: 70px 0 0 -60px;
    }
    &-step2 {
      margin: 270px 0px 0 -145px;
    }
    &-step3 {
      margin: 375px 0px 0 -490px;
    }
    &-step4 {
      margin: 610px 0px 0 270px;
      width: 140px;
    }
    &-step5 {
      margin: 670px 0px 0 -160px;
      width: 235px;
    }
    &-step6 {
      width: 215px;
      margin: 840px 0px 0 170px;
      text-align: start;
    }
  }

  .trusteddev-tab .step-arrow,
  .ctoServicePortal-tab .step-arrow {
    position: absolute;
    &-step1 {
      margin: 20px 0px 0px 0px;
      width: 250px;
    }
    &-step2 {
      margin: 235px 0px 0px -245px;
      width: 200px;
    }
    &-step3 {
      margin: 415px 0px 0px 65px;
      width: 140px;
    }
    &-step4 {
      margin: 575px 0px 0px -185px;
      width: 250px;
    }
    &-step5 {
      margin: 715px 0 0 -200px;
      width: 165px;
      transform: scaleY(-1) rotate(-55deg);
    }
  }

  //What Page
  .what-page .step-img {
    position: absolute;
    &-step1 {
      width: 325px;
      margin: 8px 0 0 -330px;
    }
    &-step2 {
      width: 315px;
      margin: 185px 0 0 15px;
    }
    &-step3 {
      margin: 320px 0px 0 -195px;
      width: 325px;
    }
    &-step4 {
      width: 200px;
      margin: 425px 0px 0 145px;
    }
    &-step5 {
      width: 215px;
      margin: 580px 0px 0 -365px;
    }
    &-step6 {
      width: 245px;
      margin: 735px 0px 0 10px;
    }
  }

  .what-page .step-label {
    font-size: 1.125rem;
    &-step1 {
      margin: 25px 0 0 -105px;
    }
    &-step2 {
      margin: 235px 0 0 -30px;
    }
    &-step3 {
      margin: 400px 0px 0 -245px;
    }
    &-step4 {
      margin: 555px 0px 0 95px;
    }
    &-step5 {
      margin: 675px 0px 0 -260px;
    }
    &-step6 {
      width: 215px;
      margin: 790px 0px 0 -100px;
    }
  }

  .what-page .step-content {
    font-size: 0.875rem;
    &-step1 {
      margin: 70px 0 0 -80px;
    }
    &-step2 {
      width: 185px;
      margin: 265px 0px 0 -105px;
    }
    &-step3 {
      margin: 430px 0px 0 -370px;
    }
    &-step4 {
      margin: 545px 0px 0 270px;
      width: 140px;
    }
    &-step5 {
      margin: 660px 0px 0 -160px;
      width: 210px;
    }
    &-step6 {
      width: 225px;
      margin: 840px 0px 0 170px;
      text-align: start;
    }
  }

  .what-page .step-arrow {
    position: absolute;
    &-step1 {
      margin: 20px 0px 0px 0px;
      width: 250px;
    }
    &-step2 {
      margin: 235px 0px 0px -225px;
      width: 180px;
    }
    &-step3 {
      margin: 390px 0px 0px 83px;
      width: 130px;
    }
    &-step4 {
      margin: 550px 0px 0px -165px;
      width: 250px;
    }
    &-step5 {
      margin: 710px 0 0 -190px;
      width: 165px;
      transform: scaleY(-1) rotate(-60deg);
    }
  }
  .Guy_Animation {
    #guy1,
    #guy2,
    #guy3,
    #guy4,
    #guy5,
    #guy6,
    #guy7,
    #guy8,
    #guy9,
    #guy10,
    #guy11,
    #guy12,
    #gu13,
    #guy14,
    #guy15,
    #guy16,
    #guy17,
    #guy18,
    #guy19 {
      height: 315px;
      margin-left: 20px;
    }
  }
  .trusteddev-tab .four_box_center_img {
    width: 70px;
    margin: -35px 0 0 0;
  }
  .font90 {
    font-size: 4rem;
  }
  .height2 {
    min-height: 198px;
  }
  .ctoServicePortal-tab {
    .cto-page-banner {
      height: 1105px;
      border-radius: 0px 0px 75px 0px;
    }
    .Animations_Card {
      width: 38px;
      margin: 430px 0 0 24px;
    }
    .Animations_Labirynth {
      width: 45px;
      margin: 238px 0px 0 129px;
    }
    .Animations_Heads {
      width: 50px;
      margin: 142px 0 0 300px;
      z-index: 2;
    }
    .Animations_Code {
      width: 40px;
      margin: 144px 0 0 595px;
    }
    .Animations_Ekierka {
      width: 40px;
      margin: 255px 0 0 681px;
    }
    .AI {
      width: 35px;
      margin: 242px 0 0 753px;
    }
    .Animations_Turbo {
      margin: 432px 0 0 873px;
    }
  }
  .ctoServicePortal-tab .semicircle-img {
    height: 475px;
    margin: 123px auto 0;
    z-index: -1;
  }
  .ctoServicePortal-tab .banner-image {
    width: 70px;
    &-step1 {
      margin: 410px 0 0 8px;
    }
    &-step2 {
      margin: 225px 0 0 117px;
    }
    &-step3 {
      margin: 125px 0 0 290px;
      z-index: 1;
    }
    &-step4 {
      margin: 125px 0 0 580px;
    }
    &-step5 {
      margin: 225px 0 0 735px;
    }
    &-step6 {
      margin: 420px 0 0 860px;
    }
  }

  .ctoServicePortal-tab .banner-step {
    font-size: 0.813rem;
    &-number1 {
      margin: 395px 0 0 0px;
    }
    &-number2 {
      margin: 205px 0 0 115px;
    }
    &-number3 {
      margin: 100px 0 0 317px;
    }
    &-number4 {
      margin: 100px 0 0 600px;
    }
    &-number5 {
      margin: 205px 0 0 790px;
    }
    &-number6 {
      margin: 395px 0 0 910px;
    }
  }

  .ctoServicePortal-tab .banner-heading {
    font-size: 0.813rem;
    &-step1 {
      margin: 415px 0 0 88px;
    }
    &-step2 {
      width: 170px;
      margin: 302px 0 0 127px;
    }
    &-step3 {
      margin: 200px 0 0 245px;
    }
    &-step4 {
      margin: 200px 0 0 570px;
    }
    &-step5 {
      margin: 302px 0 0 616px;
      width: 184px;
    }
    &-step6 {
      margin: 415px 0 0 685px;
      width: 170px;
    }
  }

  .ctoServicePortal-tab .banner-content {
    font-size: 0.75rem;
    &-step1 {
      margin: 432px 0 0 90px;
      width: 195px;
    }
    &-step2 {
      margin: 332px 0 0 125px;
      width: 203px;
    }
    &-step3 {
      margin: 220px 0 0 220px;
      width: 220px;
    }
    &-step4 {
      margin: 220px 0 0 525px;
      width: 195px;
    }
    &-step5 {
      margin: 332px 0 0 578px;
      width: 220px;
    }
    &-step6 {
      margin: 432px 0 0 640px;
      width: 215px;
    }
  }

  .ctoServicePortal-tab .banner-center-heading {
    margin: 360px 0 0 365px;
  }
  .ctoServicePortal-tab .page-divider-section {
    margin-top: 10px;
  }
  .ctoServicePortal-tab .banner_paragraph {
    margin: 15px auto -10px auto;
  }
  // About page (Why)
  .about-page .why-page-banner {
    border-radius: 0px 0px 95px 0px;
    background-position-y: -40%;
  }
  .about-page .card-body {
    padding: 30px;
  }
  .about-page .Rectangle-wrapper .rectangle {
    min-width: 170px;
    min-height: 215px;
  }
  .about-page .Rectangle-wrapper .rectangle-img {
    width: 70px;
  }
  .about-page .Rectangle-wrapper .rectangle-heading {
    font-size: 1.375rem;
  }
  .about-page .Rectangle-wrapper .rectangle-label {
    font-size: 0.938rem;
  }
  .about-page .icon-size {
    width: 48px;
    margin-top: 34px;
  }
  //What
  .what-page .what-page-banner {
    background-position-y: 0%;
  }
  .what-page .card-body.Expert-software-engineer-section-body {
    padding: 0px;
    height: 335px;
  }
  .what-page .Expert-software-engineer-section-body .impact-gif {
    width: 500px;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .what-page .Expert-software-engineer-section-body .impact2-gif {
    width: 335px;
    margin-top: -80px;
    margin-left: -25px;
  }
  .what-page .Expert-software-engineer-section-body .star1 {
    top: 12%;
    margin-left: 60px;
    width: 22px;
  }
  .what-page .Expert-software-engineer-section-body .star2 {
    top: 7%;
    margin-left: 80px;
    width: 25px;
  }
  .what-page .Expert-software-engineer-section-body .star3 {
    top: 9%;
    margin-left: 110px;
    width: 20px;
  }
  //About Page (How)
  .how-page .how-page-banner {
    background-position-y: -50%;
    border-radius: 0px 0px 75px 0px;
  }
  .how-page .How-TrustedDev-Works-Content {
    padding: 0 20px;
  }
  .how-page .dotted_line {
    height: 775px;
  }
  .how-page .circle_blue_large {
    top: 775px;
  }
  .how-page .How-TrustedDev-Works-Heading {
    padding: 0px 20px;
  }
  //AI Page
  .AI-page {
    .AI-page-banner {
      height: 500px;
      background-position-y: 60px;
    }
    .AI-page-BackgroundColor {
      height: 510px;
    }
    .page_divider_banner {
      height: 825px;
    }
  }
  //Custom-SaaS
  .Custom-SAAS-page {
    .Custom-Saas-page-banner {
      height: 540px;
      background-position-y: 80px;
    }
    .computer-img {
      width: 885px;
    }
    .Owsap-image {
      width: 275px;
      margin: -8px 0 0 -50px;
    }
    .owasp-img {
      width: 125px;
      margin: 0 0 0 55px;
    }
    .FSD_animation {
      #table1,
      #table2,
      #table3,
      #table4,
      #table5,
      #table6,
      #table7,
      #table8,
      #table9,
      #table10,
      #table11,
      #table12,
      #table13,
      #table14,
      #table15,
      #table16,
      #table17,
      #table18,
      #table19,
      #table20 {
        height: 1092px;
      }
    }
    .Full-stack-development-img {
      height: 1300px;
    }
    .middle-background-banner {
      height: 1300px;
      background-size: cover;
    }
  }
  //LogIn Page
  .Login-page .login-page-logo {
    width: 250px;
  }
  .Login-page .card {
    width: 70%;
  }
  //Survey Page
  .Survey-Page .card-body-wrapper .form-field-wrapper {
    padding: 0 50px;
  }
  .Survey-Page .questionnaire-form .form-label {
    padding-right: 0;
  }
  .Survey-Page .checkbox-text {
    margin-top: 0px;
  }
  //Progress Bar
  .Congrats-Page .navbar.navbar-dark,
  .Survey-Page .navbar.navbar-dark,
  .Password-Creation-Page .navbar.navbar-dark {
    padding: 0.5rem;
  }
  .Congrats-Page .header-text,
  .Survey-Page .header-text,
  .Password-Creation-Page .header-text {
    margin-right: 8px;
  }
  .Congrats-Page .progress-bar-wrapper .progress-bar-label,
  .Survey-Page .progress-bar-wrapper .progress-bar-label,
  .Password-Creation-Page .progress-bar-wrapper .progress-bar-label {
    margin: 0 15px;
  }
  //basic-info
  .BasicIngo-Page .checkbox-text {
    margin-left: 30px;
    margin-top: -18px;
  }
  .BasicIngo-Page .basic-info-card .card-body .input-group {
    margin-top: 10px;
  }
  .BasicIngo-Page .basic-info-card .card-body .input-group {
    width: 58%;
  }
}

/* Large devices (992px and up) (lg) */
@media (min-width: 992px) {
  .emil-container {
    margin-bottom: 35px !important;
  }

  .error-message-email {
    bottom: -25px !important;
  }

  .AI-page .privacy-content {
    height: 121px;
  }
  
  .AI-page .privacy-banner {
    background-image: none;
  }
  .main_container {
    width: 90%;
  }
  .page_bottom_divider_banner {
    background-size: 140%;
  }
  .sub_nav {
    display: block !important;
  }
  .ctoServicePortal-tab .bottom-margin {
    margin-bottom: 520px;
  }
  .trusteddev-tab .mobile,
  .ctoServicePortal-tab .mobile,
  .what-page .mobile {
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }
}

/* Medium devices (tablets, 768px and up) (md) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .error-message-email {
    bottom: -20px !important;
  }

  .Vetting-Methodology-card .col-6:first-child {
    padding-bottom: 40px;
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      // padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .AI-page .privacy-content {
    height: 121px;
  }
  
  .AI-page .privacy-banner {
    background-image: none;
  }
  html {
    font-size: 12px;
  }
  .trusteddev-tab {
    .banner_heading,
    .mobile-banner-logo {
      animation-delay: 0.1s;
    }
    .banner_paragraph,
    .mobile_hire_button {
      animation-delay: 0.3s;
    }
    .Vetting-Methodology-card {
      animation-delay: 0.4s;
      .identifyImg {
        animation-delay: 0.7s;
      }
      .cultureImg {
        animation-delay: 0.3s;
      }
      .ongoingAiImg {
        animation-delay: 0.9s;
      }
      .problemSolvingImg {
        animation-delay: 1s;
      }
      .testProjectImg {
        animation-delay: 0.6s;
      }
      .identifyContent {
        animation-delay: 0.8s;
      }
      .cultureContent {
        animation-delay: 0.4s;
      }
      .OngoingAiContent {
        animation-delay: 1s;
      }
      .problemSolvingContent {
        animation-delay: 1.1s;
      }
      .testProjectContent {
        animation-delay: 0.7s;
      }
      .dotted_line {
        animation-delay: 0.5s;
      }
      .circle_blue_large,
      .your-staff-is-ready-to-hire-wrapper {
        animation-delay: 1.1s;
      }
    }
    .card_line {
      &Left_1 {
        animation-delay: 0.6s;
      }
      &Left_3 {
        animation-delay: 0.2s;
      }
      &Left_5 {
        animation-delay: 0.8s;
      }
      &Right_2 {
        animation-delay: 0.9s;
      }
      &Right_4 {
        animation-delay: 0.5s;
      }
    }
  }
  .image {
    margin: 0 10px;
    &_w100 {
      width: 65px;
    }
    &_w150 {
      width: 105px;
    }
    &_w160 {
      width: 120px;
    }
    &_PML_img {
      width: 65px;
      margin-left: -145px;
    }
  }
  .main_container {
    width: 95%;
  }
  .hire_button {
    background-color: #d9f0ff;
    border-color: #e5f5ff;
    color: #09b6b4;
    display: block;
    padding: 10px 32px;
    margin: 6px auto;
  }
  .page_bottom_divider_banner {
    background-size: 150%;
  }
  .content_background {
    height: 120px;
  }
  .navbar-collapse {
    display: block !important;
  }
  #navbarSupportedContent .container .navbar-nav .nav-item a span {
    font-weight: 700;
  }
  .navbar .top_nav .navbar-collapse {
    .CTOTab-inactive {
      height: 38px;
      margin: -15px 0 0 -12px;
      width: 175px;
    }
    .TabLabelCTOInactiveTab-label {
      width: 125px;
      margin: -3px 0 0 21px;
    }
    .CTOTab-active {
      height: 38px;
      margin: -14px 0 0 150px;
      width: 175px;
    }
    .TabLabelCTOActiveTab-label {
      margin: -3px 0 0 173px;
    }
    .activeTrustedDevTab {
      height: 50px;
      margin: -20px 0 0 -23px;
      width: 175px;
    }
    .InactiveTrustedDevTab {
      height: 50px;
      margin: -21px 0 0 135px;
      width: 175px;
    }
    .TabLabelTrustedDevActive-label {
      margin: 0px 0 0 11px;
      height: 44px;
    }
    .TabLabelTrustedDevInactive-label {
      width: 147px;
      text-align: center;
      margin: 0px 0 0 152px;
    }
  }
  .trusteddev-tab {
    .Animations_Circle {
      width: 80px;
      margin: -45px 0 0 -40px;
    }
    .Animations_Clock {
      width: 75px;
      margin: -40px 0 0 -40px;
    }
    .Animations_Bar {
      width: 60px;
      margin: -25px 0 0px -30px;
    }
    .Animations_Cog {
      width: 65px;
      margin: 5px 0 0 -12px;
    }
  }
  .ctoServicePortal-tab {
    .Brands-That-Our-Leadership-content,
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
      font-size: 1.2rem;
      &_text-style-1,
      &_bold-text,
      &_brand-text,
      .text-style-1,
      .text-style-2 {
        font-size: 1.22rem;
      }
    }
  }
  .icon-circleBgLtr,
  .icon-circleBgRtl {
    width: 70px;
  }
  .icon-MobilecircleBgRtl {
    margin: 0px auto 10px;
  }
  .trusteddev-tab .dotted_line {
    height: 88%;
    top: 30px;
  }
  .icon {
    &-identity {
      width: 50px;
      margin: -70px 0 0 -24px;
    }
    &-Culture {
      width: 50px;
      margin: -70px 0 0 -27px;
    }
    &-OngoingAI {
      width: 50px;
      margin: -75px 0 0 -24px;
    }
    &-ProblemSolving {
      width: 55px;
      margin: -76.5px 0 0 -27px;
    }
    &-TestProject {
      width: 55px;
      margin: -70px 0 0 -25px;
    }
    &-Enterprise {
      width: 33px;
      margin: -62px 0 0 -16px;
    }
    &-Staff {
      width: 45px;
      margin: -65px 0 0 -25px;
    }
    &-puzzle {
      width: 48px;
      margin: -67px 0 0 -23px;
    }
  }
  .ctoServicePortal-tab .brands {
    grid-template-columns: repeat(auto-fit, minmax(100px, 2fr));
    grid-gap: 0.25rem;
  }
  .ctoServicePortal-tab .brands__item img {
    max-width: 90%;
  }
  .ctoServicePortal-tab .bottom-margin {
    margin-bottom: 380px;
  }
  .ctoServicePortal-tab .cto-page-banner {
    height: 821px;
    border-radius: 0px 0px 60px 0px;
  }
  .ctoServicePortal-tab .page-divider-section {
    border-radius: 0 75px 0 0;
  }
  .ctoServicePortal-tab .semicircle-img {
    height: 365px;
    margin: 65px auto 0px;
    z-index: 0;
  }
  .ctoServicePortal-tab .banner_heading {
    font-size: 2rem;
  }
  .ctoServicePortal-tab .banner_paragraph {
    margin: 15px auto -5px auto;
  }
  .ctoServicePortal-tab {
    .Animations_Card {
      width: 30px;
      margin: 316px 0 0 12px;
    }
    .Animations_Labirynth {
      width: 38px;
      margin: 169px 0px 0 84px;
    }
    .Animations_Heads {
      width: 40px;
      margin: 90px 0 0 213px;
    }
    .Animations_Code {
      width: 30px;
      margin: 92px 0 0 472px;
    }
    .Animations_Ekierka {
      width: 32px;
      margin: 167px 0 0 592px;
    }
    .AI {
      width: 30px;
      margin: 172px 0 0 592px;
    }
    .Animations_Turbo {
      width: 30px;
      margin: 317px 0 0 680px;
    }
  }
  .ctoServicePortal-tab .banner-image {
    width: 55px;
    &-step1 {
      margin: 300px 0 0 0px;
    }
    &-step2 {
      margin: 160px 0 0 75px;
    }
    &-step3 {
      margin: 75px 0 0 205px;
    }
    &-step4 {
      margin: 75px 0 0 460px;
    }
    &-step5 {
      margin: 160px 0 0 580px;
    }
    &-step6 {
      margin: 305px 0 0 665px;
    }
  }

  .ctoServicePortal-tab .banner-step {
    font-size: 0.813rem;
    &-number1 {
      margin: 290px 0 0 -10px;
    }
    &-number2 {
      margin: 140px 0 0 80px;
    }
    &-number3 {
      margin: 60px 0 0 225px;
    }
    &-number4 {
      margin: 60px 0 0 482px;
    }
    &-number5 {
      margin: 140px 0 0 620px;
    }
    &-number6 {
      margin: 293px 0 0 715px;
    }
  }

  .ctoServicePortal-tab .banner-heading {
    font-size: 0.813rem;
    &-step1 {
      margin: 305px 0 0 64px;
    }
    &-step2 {
      width: 130px;
      margin: 225px 0 0 80px;
    }
    &-step3 {
      margin: 136px 0 0 180px;
    }
    &-step4 {
      margin: 136px 0 0 450px;
    }
    &-step5 {
      margin: 225px 0 0 500px;
      width: 130px;
    }
    &-step6 {
      margin: 305px 0 0 522px;
      width: 135px;
    }
  }

  .ctoServicePortal-tab .banner-content {
    font-size: 0.75rem;
    &-step1 {
      margin: 319px 0 0 64px;
      width: 135px;
    }
    &-step2 {
      margin: 247px 0 0 80px;
      width: 145px;
    }
    &-step3 {
      margin: 148px 0 0 170px;
      width: 150px;
    }
    &-step4 {
      margin: 148px 0 0 421px;
      width: 140px;
    }
    &-step5 {
      margin: 247px 0 0 460px;
      width: 170px;
    }
    &-step6 {
      margin: 319px 0 0 496px;
      width: 160px;
    }
  }
  .ctoServicePortal-tab .banner-center-heading {
    margin: 265px 0 0 240px;
  }
  .ctoServicePortal-tab .banner_heading {
    width: 630px;
  }
  .ctoServicePortal-tab .banner_paragraph {
    width: 500px;
  }
  .how-page .trusteddev-tab .dotted_line {
    height: 95%;
  }
  .how-page .trusteddev-tab .circle_blue_large {
    top: 20px;
  }
  .trusteddev-tab .circle_blue {
    &_large {
      top: 100%;
    }
  }
  .navbar .site-logo {
    width: 125px;
  }
  .bg_cto_theme .cto-site-logo {
    width: 150px;
    margin-right: -12px;
  }
  .navbar .top-nav-logo {
    margin-left: -25px;
  }
  .ctoServicePortal-tab .hire_button,
  .trusteddev-tab .hire_button {
    font-size: 9px;
    letter-spacing: 0.1px;
    padding: 7px 15px;
  }
  .trusteddev-tab .banner_heading {
    font-size: 35px;
    letter-spacing: 2px;
  }
  .trusteddev-tab .blue_bar_page_divider {
    padding: 15px;
  }
  .sub-menu-width {
    max-width: 875px;
  }
  #navbarSupportedContent .container .navbar-nav .nav-item a span {
    font-weight: 100;
  }
  .trusteddev-tab .navbar-nav .nav-item .nav-link,
  .ctoServicePortal-tab .navbar-nav .nav-item .nav-link {
    font-size: 11px;
    letter-spacing: 0.1px;
  }
  .trusteddev-tab .order-section,
  .ctoServicePortal-tab .order-section,
  .what-page .order-section {
    padding: 35px 160px 50px;
  }

  .trusteddev-tab
    .The-Implementation-of-AI-With-Software-Development-Resource-Vet,
  .trusteddev-tab
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: 1.2px;
    &_text-style-1 {
      letter-spacing: 1.4px;
      font-size: 1.75rem;
    }
  }

  .trusteddev-tab .social-media,
  .ctoServicePortal-tab .social-media,
  .what-page .social-media {
    width: 32px;
    height: 32px;
  }

  .trusteddev-tab .Rectangle-box,
  .AI-page .Rectangle-box {
    min-height: 150px;
  }
  .trusteddev-tab .digit-96,
  .AI-page .digit-96 {
    font-size: 2.5rem;
    margin-left: 10px;
  }
  .trusteddev-tab .Belive-AI-content,
  .AI-page .Belive-AI-content {
    font-size: 1rem;
    margin-left: 10px;
  }
  .trusteddev-tab .hour24-content,
  .AI-page .hour24-content {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .trusteddev-tab .Average-amount-content,
  .AI-page .Average-amount-content {
    font-size: 1rem;
    margin-right: 10px;
  }
  .trusteddev-tab .white-box,
  .AI-page .white-box,
  .trusteddev-tab .blue-box,
  .AI-page .blue-box {
    height: 45px;
    width: 45px;
  }
  .trusteddev-tab .four_box_center_img,
  .AI-page .four_box_center_img {
    width: 65px;
    margin: -35px -5px 0px 0;
  }
  .trusteddev-tab .what-can-be-automated-content,
  .AI-page .what-can-be-automated-content {
    margin: 10px 10px 5px 0;
    font-size: 1.25rem;
  }
  .trusteddev-tab .percentage-line-content,
  .AI-page .percentage-line-content {
    font-size: 1rem;
    margin-right: 10px;
  }
  .trusteddev-tab .percentage-line-content .text-style,
  .AI-page .percentage-line-content .text-style {
    font-size: 1.2rem;
  }
  .trusteddev-tab .cost-of-not-automating-content,
  .AI-page .cost-of-not-automating-content {
    font-size: 1.25rem;
    margin: 10px 0 5px 10px;
  }
  .trusteddev-tab .list-item-content,
  .AI-page .list-item-content {
    font-size: 1rem;
    margin-left: 10px;
  }

  .trusteddev-tab .The-TrustedDev-Vetting-Methodology {
    font-size: 20px;
    &-text-style-1 {
      font-size: 24px;
    }
    &-text-style-2 {
      font-size: 24px;
    }
  }

  .trusteddev-tab .step-section,
  .what-page .step-section,
  .ctoServicePortal-tab .step-section {
    height: 1730px;
  }

  .AI-page .step-section {
    height: 1825px;
  }

  .trusteddev-tab .step-img,
  .ctoServicePortal-tab .step-img {
    position: absolute;
    &-step1 {
      width: 390px;
      margin: 10px 0 0 -155px;
    }
    &-step2 {
      width: 380px;
      margin: 300px 0 0 -225px;
    }
    &-step3 {
      margin: 575px 0px 0 -195px;
      width: 385px;
    }
    &-step4 {
      width: 210px;
      margin: 900px 0px 0 -105px;
    }
    &-step5 {
      width: 215px;
      margin: 1230px 0px 0 -220px;
    }
    &-step6 {
      width: 250px;
      margin: 1430px 0px 0 -125px;
    }
  }
  .AI-page .step-img {
    &-step6 {
      width: 250px;
      margin: 1470px 0px 0 -125px;
    } 
  }

  .trusteddev-tab .step-label,
  .ctoServicePortal-tab .step-label {
    position: absolute;
    font-size: 24px;
    font-family: "lato-black";
    line-height: 0.85;
    letter-spacing: 0.84px;
    text-align: center;
    color: #3f95cc;
    &-step1 {
      margin: 27px 0 0 -70px;
    }
    &-step2 {
      margin: 455px 0 0 -168px;
    }
    &-step3 {
      margin: 672px 0px 0 -260px;
    }
    &-step4 {
      margin: 1030px 0px 0 -175px;
    }
    &-step5 {
      margin: 1325px 0px 0 -128px;
    }
    &-step6 {
      margin: 1482px 0px 0 -170px;
    }
  }
  .AI-page .step-label {
    &-step6 {
      margin: 1522px 0px 0 -170px;
    } 
  }

  .trusteddev-tab .step-content,
  .ctoServicePortal-tab .step-content {
    width: 221px;
    position: absolute;
    line-height: 1.33;
    letter-spacing: 0.45px;
    text-align: center;
    color: #185780;
    font-size: 14px;
    font-family: "lato-semibold";
    &-step1 {
      margin: 220px 0 0 -130px;
      width: 250px;
    }
    &-step2 {
      margin: 500px 0px 0 -120px;
    }
    &-step3 {
      margin: 790px 0px 0 -105px;
    }
    &-step4 {
      margin: 1140px 0px 0 -95px;
      width: 190px;
    }
    &-step5 {
      margin: 1375px 0px 0 -135px;
      width: 285px;
    }
    &-step6 {
      margin: 1655px 0px 0 -125px;
      width: 250px;
    }
  }

  .AI-page .step-content {
    &-step6 {
      margin: 1695px 0px 0 -125px;
      width: 250px;
    }    
  }

  .trusteddev-tab .step-arrow,
  .ctoServicePortal-tab .step-arrow {
    position: absolute;
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }

  .trusteddev-tab .mobile,
  .ctoServicePortal-tab .mobile {
    &-step1 {
      width: 125px;
      height: 230px;
      margin: 158px 0 0 75px;
    }
    &-step2 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 95px;
      height: 260px;
      margin: 360px 0 0 -215px;
      z-index: 1;
    }
    &-step3 {
      width: 90px;
      height: 245px;
      margin: 690px 0 0 119px;
    }
    &-step4 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 75px;
      height: 185px;
      margin: 1050px 0 0 -190px;
    }
     &-step5 {
      width: 90px;
      height: 245px;
      margin: 1400px 0 0 119px;
    }
  }

  .AI-page .mobile {
    &-step5 {
      width: 90px;
      height: 245px;
      margin: 1440px 0 0 119px;
    }    
  }

  //What Page
  // .Guy_Animation {
  //   #guy1,
  //   #guy2,
  //   #guy3,
  //   #guy4,
  //   #guy5,
  //   #guy6,
  //   #guy7,
  //   #guy8,
  //   #guy9,
  //   #guy10,
  //   #guy11,
  //   #guy12,
  //   #gu13,
  //   #guy14,
  //   #guy15,
  //   #guy16,
  //   #guy17,
  //   #guy18,
  //   #guy19 {
  //     animation: 9s Guy infinite;
  //     visibility: hidden;
  //     height: 255px;
  //     margin-left: 20px;
  //   }
  // }
  .what-page .what-trustedDev {
    font-size: 1.375rem;
    line-height: 1.25;
    letter-spacing: 0.8px;
    .text-style-2 {
      font-size: 1.4rem;
    }
  }
  .what-page {
    .Expert-software-engineer-background-banner {
      height: 329px;
      margin-bottom: -30px;
    }
    .Expert-software-engineer-section-body .impact-gif {
      width: 345px;
    }
    .Expert-software-engineer-section-body .arrow-right {
      margin-left: 5px;
      width: 45px;
    }
    .Expert-software-engineer-section-body .impact2-gif {
      width: 245px;
    }
  }
  .what-page .step-img {
    position: absolute;
    &-step1 {
      width: 390px;
      margin: 10px 0 0 -155px;
    }
    &-step2 {
      width: 380px;
      margin: 300px 0 0 -225px;
    }
    &-step3 {
      margin: 575px 0px 0 -195px;
      width: 385px;
    }
    &-step4 {
      width: 210px;
      margin: 865px 0px 0 -105px;
    }
    &-step5 {
      width: 215px;
      margin: 1230px 0px 0 -220px;
    }
    &-step6 {
      width: 250px;
      margin: 1430px 0px 0 -125px;
    }
  }

  .what-page .step-label {
    font-size: 2rem;
    &-step1 {
      margin: 25px 0 0 110px;
    }
    &-step2 {
      margin: 360px 0 0 -285px;
    }
    &-step3 {
      margin: 672px 0px 0 -260px;
    }
    &-step4 {
      margin: 1000px 0px 0 -175px;
    }
    &-step5 {
      margin: 1325px 0px 0 -128px;
    }
    &-step6 {
      margin: 1480px 0px 0 -170px;
    }
  }

  .what-page .step-content {
    font-size: 1.25rem;
    &-step1 {
      margin: 220px 0 0 -130px;
      width: 250px;
    }
    &-step2 {
      margin: 510px 0px 0 -120px;
      width: 250px;
    }
    &-step3 {
      margin: 790px 0px 0 -140px;
      width: 290px;
    }
    &-step4 {
      margin: 1110px 0px 0 -110px;
      width: 225px;
    }
    &-step5 {
      margin: 1360px 0px 0 -130px;
      width: 325px;
    }
    &-step6 {
      margin: 1665px 0px 0 -166px;
      text-align: center;
      width: 330px;
    }
  }

  .what-page .step-arrow {
    position: absolute;
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }

  .what-page .mobile {
    &-step1 {
      width: 125px;
      height: 200px;
      margin: 158px 0 0 75px;
    }
    &-step2 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 95px;
      height: 260px;
      margin: 360px 0 0 -215px;
      z-index: 1;
    }
    &-step3 {
      width: 90px;
      height: 240px;
      margin: 690px 0 0 119px;
    }
    &-step4 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 75px;
      height: 200px;
      margin: 1025px 0 0 -195px;
    }
    &-step5 {
      width: 90px;
      height: 245px;
      margin: 1400px 0 0 149px;
    }
  }

  // About page (Why)
  .how-page .trusteddev-tab .card_line {
    &Left_1,
    &Left_3,
    &Left_5 {
      margin: -50px 9px 47px 61%;
    }
    &Right_2,
    &Right_4 {
      margin: -45px 61% 43px 9px;
    }
    &Last {
      width: 30%;
      margin: -45px 61% 43px 21px;
    }
  }
  .about-page .icon-size {
    width: 38px;
    margin-top: 29px;
  }
  .about-page .why-page-banner {
    background-image: url("../assets/images/HeroBanners/Tablet2XWhy.jpg");
    border-radius: 0px 0px 75px 0px;
  }
  .about-page .card-body {
    padding: 25px;
  }
  .about-page .Rectangle-wrapper .rectangle {
    min-width: 140px;
    min-height: 185px;
    padding: 20px 5px;
  }
  .about-page .Rectangle-wrapper .rectangle-img {
    width: 60px;
  }
  .about-page .Rectangle-wrapper .rectangle-heading {
    font-size: 1.25rem;
  }
  .about-page .Rectangle-wrapper .rectangle-label {
    font-size: 1rem;
    margin: 20px 0px;
  }
  .how-page .how-page-banner {
    background-image: url("../assets/images/HeroBanners/Tablet2XHow.jpg");
    border-radius: 0px 0px 75px 0px;
  }
  //About (who)
  .how-page .How-TrustedDev-Works-Content,
  .how-page .How-TrustedDev-Works-Heading {
    padding: 0 25px;
  }
  //About (what)
  .what-page .what-page-banner {
    background-image: url("../assets/images/HeroBanners/Tablet2XWhat.jpg");
    background-position-y: -25%;
    border-radius: 0px 0px 75px 0px;
  }
  .what-page .card-body.Expert-software-engineer-section-body {
    padding: 0px;
    height: 260px;
  }
  .what-page .Expert-software-engineer-section-body .impact-gif {
    width: 375px;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .what-page .Expert-software-engineer-section-body .impact2-gif {
    width: 275px;
    margin-top: -80px;
    margin-left: -25px;
  }
  .what-page .Expert-software-engineer-section-body .star1 {
    top: 8%;
    margin-left: 38px;
    width: 22px;
  }
  .what-page .Expert-software-engineer-section-body .star2 {
    top: 0%;
    margin-left: 61px;
    width: 25px;
  }
  .what-page .Expert-software-engineer-section-body .star3 {
    top: 5%;
    margin-left: 95px;
    width: 20px;
  }
  //AI Page
  .AI-page {
    .AI-page-banner {
      height: 410px;
      background-position-y: 75px;
    }
    .AI-page-BackgroundColor {
      height: 420px;
    }
    .page_divider_banner {
      height: 790px;
    }
  }
  //Custom-SaaS
  .Custom-SAAS-page {
    .Custom-Saas-page-banner {
      height: 445px;
      background-position-y: 85px;
    }
    .computer-img {
      width: 650px;
      margin: 0px auto -25px;
    }
    .section-wrapper {
      padding: 25px 0;
    }
    .Owsap-image {
      width: 215px;
      margin: 0px 0 0 -60px;
    }
    .owasp-img {
      width: 95px;
      margin: 0 0 0 35px;
    }
    .FSD_animation {
      #table1,
      #table2,
      #table3,
      #table4,
      #table5,
      #table6,
      #table7,
      #table8,
      #table9,
      #table10,
      #table11,
      #table12,
      #table13,
      #table14,
      #table15,
      #table16,
      #table17,
      #table18,
      #table19,
      #table20 {
        height: 1100px;
      }
    }
    .Full-stack-development-img {
      height: 1160px;
    }
    .middle-background-banner {
      height: 1160px;
      background-size: cover;
    }
  }
  //LogIn Page
  .Login-page .banner-content {
    padding-left: 25px;
  }
  .Login-page .banner-content .banner-tagline {
    width: 340px;
  }
  .Login-page .login-page-logo {
    width: 225px;
    margin: 20px auto 20px;
  }
  .Login-page .card {
    width: 70%;
  }
  //Basic Page
  .BasicIngo-Page .basic-form-headline {
    font-size: 1.5rem;
    margin: 5px 0 15px;
    text-align: left;
  }
  .BasicIngo-Page .checkbox-text {
    font-size: 1rem;
    margin-top: -15px;
  }
  .BasicIngo-Page .continue-btn {
    font-size: 1rem;
    .font18 {
      font-size: 1.5rem;
    }
  }
  .BasicIngo-Page .basic-info-form {
    .form-label {
      font-size: 1rem;
      margin-bottom: 8px;
    }
    .form-control {
      padding: 0;
      font-size: 1.2rem;
      margin-bottom: 25px;
    }
    .form-control::placeholder {
      font-size: 1.2rem;
    }
  }
  //Survey Page
  .Survey-Page .card-body-wrapper .form-field-wrapper {
    padding: 0 25px;
  }
  .Survey-Page .questionnaire-form .form-label {
    padding-right: 15px;
  }
  .Survey-Page .basic-form-headline {
    padding: 0;
  }
  .Survey-Page .continue-btn {
    padding: 8px 20px;
  }
  //Congrats-Page
  .Congrats-Page .check-mail-label {
    font-size: 1.2rem;
  }
  .Congrats-Page .details-wrapper {
    font-size: 1rem;
  }
  //Progress bar
  .Congrats-Page .progress,
  .Survey-Page .progress,
  .Password-Creation-Page .progress {
    height: 0.6rem;
  }
  .Congrats-Page .header-text,
  .Survey-Page .header-text,
  .Password-Creation-Page .header-text {
    margin-right: 0px;
  }
  .Congrats-Page .progress-bar-wrapper .progress-bar-label,
  .Survey-Page .progress-bar-wrapper .progress-bar-label,
  .Password-Creation-Page .progress-bar-wrapper .progress-bar-label {
    margin: 0 12px;
  }
  .Congrats-Page .progress-bar-wrapper .last-progress-bar,
  .Survey-Page .progress-bar-wrapper .last-progress-bar,
  .Password-Creation-Page .progress-bar-wrapper .last-progress-bar {
    margin: 0 11px;
  }
  .Congrats-Page .navbar.navbar-dark,
  .Survey-Page .navbar.navbar-dark,
  .Password-Creation-Page .navbar.navbar-dark {
    padding: 0.5rem;
  }
  .Congrats-Page .header-text,
  .Survey-Page .header-text,
  .Password-Creation-Page .header-text {
    margin-right: 10px;
  }
}

/* Devices with less than 767.98px width */
@media (max-width: 767.98px) {
  .AI-page .privacy-content {
    height: 10px;
  }
  
  .AI-page .privacy-banner {
    background-image: none;
  }
  html {
    font-size: 12px;
  }
  .trusteddev-tab .card_border {
    border-radius: 15px 15px 0 0;
  }
  .home-page-background,
  .cto-page-banner {
    margin-top: 56px;
  }
  .Engagement-page {
    .blue-shadeBg {
      background-image: url("../assets/images/GlowHD.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
    }
  }
  // Fixed header on mobile
  .top_navbar {
    position: fixed;
    width: 100%;
    margin-top: -56px;
  }
  .sub_nav {
    position: fixed;
    margin-top: 0;
    z-index: 3;
    border: 0.5px solid #0f3658;
    border-top: none;
  }
  .trusteddev-tab {
    .banner_heading,
    .mobile-banner-logo {
      animation-delay: 0.1s;
    }
    .banner_paragraph,
    .mobile_hire_button {
      animation-delay: 0.3s;
    }
    .Vetting-Methodology-card {
      animation-delay: 0.4s;
      .identifyImg {
        animation-delay: 0.7s;
      }
      .cultureImg {
        animation-delay: 0.3s;
      }
      .ongoingAiImg {
        animation-delay: 0.9s;
      }
      .problemSolvingImg {
        animation-delay: 1s;
      }
      .testProjectImg {
        animation-delay: 0.6s;
      }
      .identifyContent {
        animation-delay: 0.8s;
      }
      .cultureContent {
        animation-delay: 0.4s;
      }
      .OngoingAiContent {
        animation-delay: 1s;
      }
      .problemSolvingContent {
        animation-delay: 1.1s;
      }
      .testProjectContent {
        animation-delay: 0.7s;
      }
      .dotted_line {
        animation-delay: 0.5s;
      }
      .circle_blue_large,
      .your-staff-is-ready-to-hire-wrapper {
        animation-delay: 1.1s;
      }
    }
    .card_line {
      &Left_1 {
        animation-delay: 0.6s;
      }
      &Left_3 {
        animation-delay: 0.2s;
      }
      &Left_5 {
        animation-delay: 0.8s;
      }
      &Right_2 {
        animation-delay: 0.9s;
      }
      &Right_4 {
        animation-delay: 0.5s;
      }
    }
  }
  // .cto-page-banner .navbar-collapse {
  //   background-color: #e7d5f7;
  //   width: 100%;
  //   padding: 0rem 1rem;
  //   .navbar-nav {
  //     width: 100%;
  //   }
  //   li.nav-item {
  //       border-bottom: 1px solid #23193f;
  //       margin: 0 25px;
  //   }
  //   a.nav-link {
  //     color: #2f254c !important;
  //     padding: 20px 0;
  //     text-align: center !important;
  //   }
  //   .active_nav {
  //     border: none;
  //   }
  //   .already-member {
  //     color: #2f254c;
  //     font-size: 12px;
  //     font-family: "lato-semibold";
  //     line-height: 0.8;
  //     letter-spacing: 0.58px;
  //     margin-top: 5px;
  //   }
  //   .login-link {
  //     color: #ef6d66;
  //     letter-spacing: 0.75px;
  //     font-size: 14.5px;
  //     font-family: "lato-semibold";
  //     text-decoration: underline;
  //   }
  //   .nav-row {
  //     background-color: #d1e6f3;
  //   }
  //   .nav-item .nav-link:hover, .nav-item .nav-link:active {
  //     background-color: #2f254cab;
  //     color: #ffffff !important;
  //     margin: -1px -25px;
  //     border-bottom: 1px solid #23193f;
  //     border-top: 1px solid #23193f;
  //   }
  // }
  .trusteddev-tab,
  .ctoServicePortal-tab {
    .navbar {
      .nav-row {
        background-color: #d5ebf7;
      }
      .login-link {
        color: #00aeac;
        letter-spacing: 0.75px;
        font-size: 14.5px;
        font-family: "lato-semibold";
        text-decoration: underline;
      }
      .already-member {
        color: #134067;
        font-size: 12px;
        font-family: "lato-semibold";
        line-height: 0.8;
        letter-spacing: 0.58px;
      }
    }
    .active_nav,
    .underline:hover {
      border-bottom: none;
    }
    .navbar-nav {
      width: 100%;
      .nav-item {
        border-bottom: 1px solid #9ccce8;
        margin: 0 25px;
        .nav-link {
          color: #134067;
          padding: 20px 0;
          text-align: center;
        }
      }
      .nav-item .nav-link:hover,
      .nav-item .nav-link:active {
        background-color: #94c5e1;
        color: #ffffff;
        margin: -1px -25px;
        border-bottom: 1px solid #9ccce8;
        border-top: 1px solid #9ccce8;
      }
    }
  }

  .tab-content .navbar-collapse,
  .ctoServicePortal-tab .navbar-collapse {
    background-color: #ecf8ff;
    padding: 0;
    width: 100%;
  }
  .navbar-collapse {
    flex-basis: auto;
    flex-grow: inherit;
  }
  .top_nav {
    padding: 0 15px !important;
  }
  .active_tab,
  .passive_tab {
    padding: 9px;
  }
  .hire_button {
    background-color: #d9f0ff;
    border-color: #e5f5ff;
    color: #09b6b4;
    display: block;
    padding: 10px 32px;
    margin: 25px auto;
  }
  .page_divider_banner {
    background-image: none;
    height: auto;
  }
  .blue_bar_page_divider {
    border-radius: 0;
    padding: 25px 15px;
  }
  .content_background {
    border-radius: 0px;
    height: 120px;
  }
  .page_bottom_divider_banner {
    background-size: 200%;
  }
  .Custom-SAAS-page .page_bottom_divider_banner {
    background-size: 200%;
    height: 480px;
  }
  .image {
    margin: 15px;
  }
  .footer_block {
    border-radius: 0px;
    padding: 25px !important;
    height: auto;
  }
  .margin-right {
    margin-right: auto;
  }
  .bg-color {
    background-color: #e5f5ff;
  }
  .logo_divider_row {
    border-radius: 0 50px 0 0;
    padding: 0px 0px 0px 0px !important;
  }
  .text-align {
    text-align: center;
  }
  .center_content {
    justify-content: center;
    display: block;
  }
  .center_icon_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .trusteddev-tab .page_divider_banner .Rectangle-box .list_type_none.padding0 {
    margin-bottom: 0;
  }
  .trusteddev-tab .footer-content,
  .ctoServicePortal-tab .footer-content,
  .what-page .footer-content {
    margin-bottom: 30px;
  }
  .right_cut_corners::before {
    border-top: 20px solid #134067;
    border-left: 25px solid #ffffff;
  }
  .left_cut_corners::before {
    border-top: 20px solid #134067;
    border-right: 25px solid #e5f5ff;
  }
  .active_nav,
  .underline:hover {
    border-bottom: 1px solid #134067;
  }
  .nav-vertical-line {
    // border-left: 2px solid #13b9b7;
    height: 24px;
    margin-top: 28px;
  }
  #navbarSupportedContent .container .navbar-nav .nav-item a span {
    font-weight: 100;
    font-size: 14px;
  }
  .ctoServicePortal-tab .page-divider-section-background {
    padding: 0;
    margin: -25px 0 0px 0;
  }
  .ctoServicePortal-tab {
    .cto-step-section.step-section {
      height: auto;
    }
    .page-divider-section {
      border-radius: 0 35px 0 0;
      border-right: 0px;
      border-bottom: 10px solid #d1e6f3;
      padding: 15px;
      margin-top: 0;
    }
  }
  .ctoServicePortal-tab .banner_heading {
    font-size: 1.625rem;
    width: 85%;
    margin: 0px auto;
  }
  .ctoServicePortal-tab .banner_paragraph {
    width: 80%;
    margin-bottom: 20px;
  }
  .ctoServicePortal-tab .mobile-section-wrapper {
    background-color: #000000c9;
    padding-bottom: 30px;
  }
  .ctoServicePortal-tab .cto-page-banner {
    background-size: 360%, 0%;
    height: 1040px;
    border-radius: 0;
  }
  .ctoServicePortal-tab .header-background-image {
    background-image: url("../assets/images/layer-35.png");
    background-position-y: 42%;
    background-position-x: 5%;
    border-radius: 0;
    background-repeat: no-repeat;
  }
  .ctoServicePortal-tab .page-divider-section {
    border-radius: 0 35px 0 0;
    border-right: 0px;
    border-bottom: 10px solid #d1e6f3;
    margin-top: 0px;
    padding: 15px;
  }
  .ctoServicePortal-tab .dark_bar_page_divider {
    padding: 15px;
    border-radius: 0;
    border-bottom: 10px solid #d1e6f3;
  }
  .ctoServicePortal-tab
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring {
    font-size: 1.5rem;
    line-height: 1.2;
    .text-style-1,
    .text-style-2 {
      font-size: 1.75rem;
    }
  }
  .ctoServicePortal-tab .page_bottom_divider_banner {
    background-image: url("../assets/images/layer-26_2.png");
    background-size: 275%;
    background-repeat: no-repeat;
    background-position: unset;
    background-position-x: 21%;
    height: 415px;
  }

  .trusteddev-tab .The-TrustedDev-Vetting-Methodology {
    font-size: 20px;
    letter-spacing: 1.3px;
    line-height: 35px;
    margin: 0;
    &-text-style-1 {
      font-size: 22px;
    }
    &-text-style-2 {
      font-size: 22px;
    }
  }
  .trusteddev-tab .banner_paragraph {
    width: auto !important;
    font-weight: 600;
    // padding: 15px 50px;
    margin-bottom: 0;
  }

  .get-a-demo-container { 
    padding: 25px 50px;
  }

  .trusteddev-tab .banner_heading {
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 1.18;
  }

  .trusteddev-tab .blue_bar_page_divider {
    padding: 25px;
    border-radius: 0;
  }
  .trusteddev-tab .longdash-mobi {
    margin: 18px 0 13px;
    width: 3em;
  }

  .trusteddev-tab
    .The-Implementation-of-AI-With-Software-Development-Resource-Vet,
  .trusteddev-tab
    .A-Dedicated-Customer-Success-Concierge-Works-With-Every-Hiring,
  .about-page .why-trustedDev,
  .what-page .what-trustedDev,
  .AI-page .AI-trustedDev,
  .AI-page .How-works,
  .Custom-SAAS-page .SAAS-trustedDev,
  .Custom-SAAS-page .Complex-Information-Security-By-Design {
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: 0.8px;
    &_text-style-1,
    .text-style-2,
    .text-style {
      font-size: 1.625rem;
      letter-spacing: 0.8px;
    }
  }
  .what-page .order-section {
    padding: 25px 75px 55px 75px;
  }
  .ctoServicePortal-tab .order-section,
  .trusteddev-tab .order-section {
    padding: 45px 0;
  }
  .AI-page .step-section {
    height: 1300px;
  }

  .trusteddev-tab,
  .AI-page,
  .what-page,
  .ctoServicePortal-tab {
    .footer_block {
      border-radius: 0 0 0 0;
      height: 185px;
    }
  }

  .trusteddev-tab .Rectangle-box,
  .AI-page .Rectangle-box {
    max-height: 225px;
  }

  .trusteddev-tab .mobile_hire_button,
  .ctoServicePortal-tab .mobile_hire_button {
    font-family: "lato-black";
    font-size: 10px;
    background-color: #e8f4fc;
    border-color: #e8f4fc;
    letter-spacing: 0.33px;
    padding: 7px 30px;
    display: block;
    margin: 0 auto 20px;
  }
  .trusteddev-tab .home-page-banner .blue-color {
    color: #14b9b8;
  }
  .ctoServicePortal-tab .cto-page-banner .orange-color {
    color: #7255c8;
  }

  .trusteddev-tab .navbar {
    animation: none;
  }
  //How page
  .how-page .tab-content .nav-pill-position,
  .what-page .tab-content .nav-pill-position {
    margin-top: -45.1px;
  }
  //About Page (Why)
  .about-page .card-body {
    padding: 30px;
  }
  .about-page .Rectangle-wrapper .rectangle {
    margin: 15px auto;
    min-width: 175px;
    max-width: 240px;
    min-height: 245px;
    max-height: 275px;
  }
  .about-page .card_border,
  .about-page .card-body {
    border-radius: 0;
  }
  //AI Page
  .AI-page .How-works {
    text-align: center;
  }
  .AI-page .card-header {
    border-radius: 0 0 30px 0;
  }
  .AI-page .AI-content {
    padding: 15px 10px 15px;
  }
  .AI-page .AI-paragraph {
    padding: 0;
  }
  .AI-page .AI-how-it-work-border {
    border-radius: 0;
  }
  .AI-page .AI-trustedDev {
    text-align: center;
  }
  .AI-page .page_divider_banner {
    height: 865px;
  }
  //About Page (How)
  .how-page .How-TrustedDev-Works-Content,
  .how-page .How-TrustedDev-Works-Heading {
    padding: 0 20px;
  }
  .tab-content .nav-pill {
    margin: 3px -9px;
  }
  .about-page .nav {
    .header-tab {
      width: 133px;
      height: 33px;
    }
    .whypage-tabLabel {
      margin: 0 0 0 50px;
    }
  }
  //About (What)
  .what-page .card-header {
    border-radius: 0;
    border-bottom: 8px solid #d1e6f3;
  }
  .what-page .gif-padding {
    padding: 0;
  }
  .what-page .World-Class-Technical-Recruitment-Card {
    border-bottom: 10px solid #e2f6ff;
  }
  .what-page .card_body_color {
    padding: 13px 25px 25px;
  }
  .what-page .Expert-software-engineer-section-body {
    border-radius: 0;
  }
  .what-page .Expert-software-engineer-background-banner {
    height: auto;
    margin-bottom: 0;
  }
  .what-page .what-trustedDevLeft {
    text-align: center;
  }
  .what-page .Expert-software-engineer-section-body {
    height: auto;
  }
  .what-page .card-body.Expert-software-engineer-section-body {
    padding: 0px !important;
  }
  .what-page .Expert-software-engineer-section-body .impact-gif {
    width: 100%;
    margin-left: 0px;
  }
  .what-page .Expert-software-engineer-section-body .impact2-gif {
    width: 400px;
    margin: -165px auto 0;
  }
  .what-page .Expert-software-engineer-section-body .d-flex {
    flex-flow: column;
  }
  .what-page .Expert-software-engineer-section-body .star1 {
    position: relative;
    left: 0;
    margin: 15px 0 0 -80px;
  }
  .what-page .Expert-software-engineer-section-body .star2 {
    position: relative;
    left: 0;
    margin: -25px 0 0 5px;
  }
  .what-page .Expert-software-engineer-section-body .star3 {
    position: relative;
    left: 0;
    margin: 18px 0 0 9px;
  }
  .what-page .Expert-software-engineer-section-body .arrow-right {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0 auto;
    position: relative;
  }
  //Custom-SaaS
  .Custom-SAAS-page {
    .card-header {
      border-bottom: none;
    }
    .second-card-header {
      border-radius: 0 0 0 15px;
    }
    .Complex-Information-divider,
    .page_divider {
      border-radius: 0 20px 0 0;
      padding: 15px;
    }
  }
  .Custom-SAAS-page .SAAS-trustedDev {
    text-align: center;
  }
  .Custom-SAAS-page .section-wrapper ul {
    padding: 0;
  }
  .Custom-SAAS-page .owasp-img {
    display: none;
  }
  //Engagement Page
  .Engagement-page {
    .col-sm-12 {
      max-width: 60%;
    }
    .engagement-page-banner {
      background-size: cover;
      .main-heading {
        margin: 0;
        padding: 25px 0 5px;
        font-size: 1.5rem;
      }
    }
    .blue-shadeBg {
      background-image: url("../assets/images/GlowMob.png");
      margin-top: 56px;
    }
    .longdash {
      padding: 0.1em 0 0;
      width: 2.5em;
      display: block;
      background-color: #57a4d5;
      margin: 15px auto 60px;
    }
    .engagement-card-bottom {
      padding-bottom: 0px;
    }
    .engagement-card-header .card-circle {
      width: 60px;
      height: 60px;
      margin: -40px auto 0;
    }
    .engagement-card-header {
      border-radius: 25px 20px 0 0;
      min-height: auto;
    }
    .engagement-card {
      margin: auto;
      min-width: 290px;
      max-width: 345px;
      .suitcase-img {
        margin-top: 8px;
        width: 40px;
      }
      .binary-img {
        width: 28px;
        margin-top: 15px;
      }
    }
    .card-body {
      margin-bottom: 50px;
      .engagement-paragraph {
        line-height: 1.5;
        letter-spacing: 0.31px;
      }
      .engagement-paraHeight {
        min-height: auto;
      }
      .engagement-footer {
        -moz-box-shadow: 0px 20px 45px -15px #00f6ff;
        -webkit-box-shadow: 0px 20px 45px -15px #00f6ff;
        box-shadow: 0px 20px 45px -15px #00f6ff;
      }
    }
  }

  //LogIn Page
  .Login-page .login-form .btn {
    padding: 15px;
  }
  .verify-page .login-form .btn {
    margin-top: 100px;
  }
  .Login-page .please-login-line,
  .verify-page .please-login-line {
    font-size: 1.313rem;
  }
  .Login-page .login-form .form-label,
  .Login-page .login-form a.forgot-password {
    font-size: 1rem;
  }
  .Login-page .bank-arrow-position {
    margin: 15px 0 0 10px;
  }
  //Basic Page
  .BasicIngo-Page .basic-form-headline,
  .Survey-Page .basic-form-headline {
    font-size: 1.5rem;
    padding: 0 20px;
    margin: 25px 0;
    text-align: center;
    width: 100%;
  }
  .BasicIngo-Page .checkbox-text,
  .Survey-Page .checkbox-text {
    font-size: 1rem;
    margin: 0 0 0 40px;
    display: inline-flex;
  }
  .BasicIngo-Page .back-arrow,
  .Survey-Page .back-arrow {
    margin-left: 0px;
  }
  .BasicIngo-Page .continue-btn,
  .Survey-Page .continue-btn {
    font-size: 1rem;
    .font18 {
      font-size: 1.5rem;
    }
  }
  .BasicIngo-Page .basic-info-form {
    .form-label {
      font-size: 1rem;
      margin-bottom: 8px;
      margin-top: 12px;
    }
    .form-control {
      padding: 0;
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    .form-control::placeholder {
      font-size: 1.2rem;
    }
  }
  .BasicIngo-Page .card-footer,
  .Survey-Page .card-footer,
  .Password-Creation-Page .card-footer {
    width: 100%;
  }
  .BasicIngo-Page .basic-info-card .card-body .form-group,
  .BasicIngo-Page .basic-info-card .card-body .input-group {
    width: 85%;
  }
  // Password-Creation-Page
  .Password-Creation-Page .back-arrow {
    margin-right: 5px;
    margin-left: 0px;
  }
  .Password-Creation-Page .form-group.field_cont {
    margin-bottom: 1.5rem;
  }
  // Survey-Page
  .Survey-Page .card-body-wrapper .form-field-wrapper {
    padding: 0 50px;
  }
}

/* Devices with more than 768px width */
@media (min-width: 768px) {
  .helper-text {
    padding: 2rem 3.5rem 0 1.5rem;
    position: absolute;
    z-index: 1;
    height: 320px;
  }

  .helper-title {
    font-size: 1.3rem;
    color: #509EC6;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }

  .helper-list-item {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    position: relative;

    &:before {
      content:"• ";
      color: #509EC6;
      position: absolute;
      left: -1rem;
    }
  }

  .helper-link {
    color: #4C677B;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      color: #4C677B;
      opacity: 0.9;
    }
  }

  /* Fixed navigation CSS*/
  .fixed {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  .fixed.sticky {
    background: rgba(12, 28, 41, 0.6);
    backdrop-filter: blur(5px);
    box-shadow: none;
  }
  .sticky {
    box-shadow: black 0px 9px 20px 0px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 100%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .sub_nav {
    z-index: 3;
    margin-top: 56px;
    height: 75px;
  }
  .navbar-expand-md .navbar-nav {
    align-items: center;
  }
  .top_navbar {
    position: fixed;
    width: 100%;
  }
  .sub_nav {
    position: absolute;
    width: 100%;
    margin-top: 56px;
    height: 55px;
  }
  .Home-Page .trusteddev-tab .top-space,
  .ctoServicePortal-tab .top-space {
    margin-top: 140px;
  }
  .Engagement-page .engagement-page-banner .main-heading {
    padding-top: 130px;
  }
  .mobile-owasp-img {
    display: none;
  }
  //How Page
  .about-page .why-trustedDev {
    margin-left: -50px;
  }
  .about-page .card-align {
    margin-left: -30px;
  }
}

/* Small devices (landscape phones, 576px and up) (sm) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .helper-text {
    padding: 2rem 3.5rem 0 3.5rem;
    position: absolute;
    z-index: 1;
    height: 320px;
  }

  .helper-title {
    font-size: 2.2rem;
    color: #509EC6;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .helper-list-item {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    position: relative;

    &:before {
      content:"• ";
      color: #509EC6;
      position: absolute;
      left: -1rem;
    }
  }
  

  .helper-link {
    color: #4C677B;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      color: #4C677B;
      opacity: 0.9;
    }
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-bottom: 40px;
    }
  }

  .enter-email-input,
  .enter-email-home {
    height: 40px;
  }

  .enter-email-input {
    width: 250px
  }

  html {
    font-size: 12px;
  }
  .image_w100 {
    width: 85px;
  }
  .image_w150 {
    width: 130px;
  }
  .image_w160 {
    width: 150px;
  }
  .trusteddev-tab {
    .Animations_Circle {
      width: 60px;
      margin: -35px 0 0 -55px;
    }
    .Animations_Clock {
      width: 60px;
      margin: -18px 0 0 -30px;
    }
    .Animations_Bar {
      width: 55px;
      margin: -10px 0 0px -20px;
    }
    .Animations_Cog {
      width: 60px;
      margin: 6px 0 0 -40px;
    }
  }
  .navbar .top_nav .navbar-collapse {
    // .CTOTab-inactive {
    //   height: 33px;
    //   margin: -10px 0 0 -305px;
    //   width: 175px;
    // }
    // .TabLabelCTOInactiveTab-label {
    //   width: 147px;
    //   margin: 0px 0 0 -272px;
    // }
    // .CTOTab-active {
    //   height: 34px;
    //   margin: -10px 0 0 -145px;
    //   width: 175px;
    // }
    // .TabLabelCTOActiveTab-label {
    //   margin: 0 0 0 -122px;
    //   height: 34px;
    // }
    .activeTrustedDevTab, .CTOTab-inactive { 
      height: 43px;
      margin: -20px 0 0 -236px;
      width: 128px;
    }
    .InactiveTrustedDevTab, .CTOTab-active{ 
      height: 43px;
      margin: -20px 0 0 -118px;
      width: 128px;
    }
    .TabLabelTrustedDevActive-label, .TabLabelCTOInactiveTab-label { 
      margin: -8px 0 0 -204px;
      font-size: 0.75rem;
      text-align: center;
      line-height: 11px;
    }
    .TabLabelTrustedDevInactive-label, .TabLabelCTOActiveTab-label { 
      width: 83px;
      text-align: center;
      margin: -8px 0 0 -95px;
      line-height: 11px;
      font-size: 0.75rem;
    }
  }
  .trusteddev-tab .digit-96,
  .AI-page .digit-96 {
    font-family: "lato-black";
    font-size: 2.625rem;
    letter-spacing: 1.05px;
    color: #3f95cc;
  }
  .trusteddev-tab .page_divider_banner .Rectangle-box .list_type_none.padding0 {
    margin-bottom: 0;
    max-width: 220px;
    margin: auto;
  }
  .trusteddev-tab .cost-of-not-automating-content,
  .AI-page .cost-of-not-automating-content {
    font-size: 1.25rem;
    line-height: 1.17;
    letter-spacing: 0.3px;
    margin: 35px 0 5px -45px;
    text-align: center !important;
  }
  .trusteddev-tab .Belive-AI-content,
  .AI-page .Belive-AI-content {
    font-size: 1.125rem;
    text-align: center;
  }
  .trusteddev-tab .hour24-content,
  .AI-page .hour24-content {
    font-size: 2.594rem;
    letter-spacing: 1px;
  }
  .trusteddev-tab .Average-amount-content,
  .AI-page .Average-amount-content {
    font-size: 1.125rem;
    margin-top: 5px;
  }
  .trusteddev-tab,
  .AI-page {
    .white-box {
      height: 45px;
      width: 40px;
      margin: -23px 0 0 0px;
    }
    .blue-box {
      height: 45px;
      width: 41px;
      margin: -23px 0 0 19px;
    }
    .four_box_center_img {
      width: 65px;
      margin: -37px 0 0 4px;
    }
  }
  .trusteddev-tab .list-item-content,
  .AI-page .list-item-content {
    letter-spacing: 0;
    font-size: 1.125rem;
  }
  .trusteddev-tab .what-can-be-automated-content,
  .AI-page .what-can-be-automated-content {
    letter-spacing: 0.6px;
    font-size: 1.25rem;
    margin-top: 20px;
    text-align: center;
  }
  .trusteddev-tab .Automnated-mobi-gif,
  .AI-page .Automnated-mobi-gif {
    width: 60px;
    margin: auto;
    margin-left: 10px;
  }
  .trusteddev-tab .percentage-line-content,
  .AI-page .percentage-line-content {
    font-size: 1.125rem;
    letter-spacing: 0px;
    margin: 5px 0 0 5px;
    width: 60%;
  }
  .trusteddev-tab .The-TrustedDev-Vetting-Methodology-Card-Content {
    padding: 0 22px;
  }
  .trusteddev-tab .dotted_line {
    height: 88%;
    top: 30px;
  }
  .trusteddev-tab .Proud-Member-of-the-Following-Organizations,
  .ctoServicePortal-tab .Proud-Member-of-the-Following-Organizations,
  .what-page .Proud-Member-of-the-Following-Organizations {
    font-size: 2rem;
    letter-spacing: 0.44px;
    margin: auto;
    padding: 32px !important;
  }
  .how-page .trusteddev-tab .dotted_line {
    height: 85%;
    top: 30px;
  }
  .how-page .How-TrustedDev-Works-Tagline {
    font-size: 1.25rem;
  }
  .trusteddev-tab .circle_blue {
    &_large {
      top: 100%;
      width: 42px;
      height: 39px;
      left: -18px;
    }
    &_medium {
      width: 27px;
      height: 27px;
      left: 5px;
      top: 4px;
    }
  }
  .trusteddev-tab .last_dot {
    left: 5px;
    top: 5px;
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper .three-person-image {
    width: 65px;
    margin: -7px 0 0 45px;
  }
  .trusteddev-tab .Vetting-Methodology-step-heading {
    font-size: 1.125rem;
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper .ready-to-hire-label {
    margin: 0px 0 0 115px;
    font-size: 1.125rem;
  }
  .ctoServicePortal-tab .The-TrustedDev-Vetting-Methodology {
    font-family: "lato-black";
    font-size: 1.5rem;
    text-align: center;
    line-height: 2.59;
    letter-spacing: 1.35px;
    padding-top: 15px;
    .text-style-1 {
      font-size: 1.7rem;
    }
    .text-style-2 {
      font-size: 1.9rem;
      color: #ef6d66;
    }
  }
  .trusteddev-tab .step-section,
  .what-page .step-section,
  .ctoServicePortal-tab .step-section {
    height: 1485px;
  }
  .icon-circleBgLtr,
  .icon-circleBgRtl {
    width: 65px;
  }
  .icon {
    &-identity {
      width: 45px;
      margin: -65px 0 0 -21px;
    }
    &-Culture {
      width: 47px;
      margin: -67px 0 0 -26px;
    }
    &-OngoingAI {
      width: 45px;
      margin: -70px 0 0 -21px;
    }
    &-ProblemSolving {
      width: 50px;
      margin: -71px 0 0 -25px;
    }
    &-TestProject {
      width: 50px;
      margin: -65px 0 0 -22px;
    }
    &-Enterprise {
      width: 31px;
      margin: -60px 0 0 -16px;
    }
    &-Staff {
      width: 40px;
      margin: -60px 0 0 -22px;
    }
    &-puzzle {
      width: 45px;
      margin: -63px 0 0 -21px;
    }
  }
  .card_line {
    &Left_1,
    &Left_3,
    &Left_5 {
      margin: -47px 9px 45px 62%;
    }
    &Right_2,
    &Right_4 {
      margin: -43px 62% 41px 9px;
    }
    &Last {
      width: 33%;
      margin: -45px 61% 43px 24px;
    }
  }
  .icon-MobilecircleBgRtl {
    margin: 0px auto 10px;
  }

  // Trusteddev-tab
  .trusteddev-tab .step-img,
  .ctoServicePortal-tab .step-img {
    position: absolute;
    &-step1 {
      width: 300px;
      margin: 0 0 0 -110px;
    }
    &-Culture {
      width: 47px;
      margin: -67px 0 0 -26px;
    }
    &-OngoingAI {
      width: 45px;
      margin: -70px 0 0 -21px;
    }
    &-ProblemSolving {
      width: 50px;
      margin: -71px 0 0 -25px;
    }
    &-TestProject {
      width: 50px;
      margin: -65px 0 0 -22px;
    }
    &-Enterprise {
      width: 31px;
      margin: -60px 0 0 -16px;
    }
    &-Staff {
      width: 40px;
      margin: -60px 0 0 -22px;
    }
    &-puzzle {
      width: 45px;
      margin: -63px 0 0 -21px;
    }
  }
  .card_line {
    &Left_1,
    &Left_3,
    &Left_5 {
      margin: -47px 9px 45px 62%;
    }
    &Right_2,
    &Right_4 {
      margin: -43px 62% 41px 9px;
    }
    &Last {
      width: 33%;
      margin: -45px 61% 43px 24px;
    }
  }
  .icon-MobilecircleBgRtl {
    margin: 0px auto 10px;
  }

  // Trusteddev-tab
  .trusteddev-tab .step-img,
  .ctoServicePortal-tab .step-img {
    position: absolute;
    &-step1 {
      width: 300px;
      margin: 0 0 0 -110px;
    }
    &-step2 {
      width: 300px;
      margin: 240px 0 0 -170px;
    }
    &-step3 {
      margin: 473px 0px 0 -145px;
      width: 300px;
    }
    &-step4 {
      width: 190px;
      margin: 735px 0px 0 -95px;
    }
    &-step5 {
      width: 215px;
      margin: 1025px 0px 0 -200px;
    }
    &-step6 {
      width: 235px;
      margin: 1223px 0px 0 -117px;
    }
  }
  .AI-page .step-img {
    &-step6 {
      width: 235px;
      margin: 1250px 0px 0 -117px;
    }    
  }

  .trusteddev-tab .step-label,
  .ctoServicePortal-tab .step-label {
    position: absolute;
    font-size: 18px;
    font-family: "lato-black";
    line-height: 0.85;
    letter-spacing: 0.84px;
    text-align: center;
    color: #3f95cc;
    &-step1 {
      margin: 13px 0 0 -45px;
    }
    &-step2 {
      margin: 360px 0 0 -125px;
    }
    &-step3 {
      margin: 545px 0px 0 -195px;
    }
    &-step4 {
      margin: 855px 0px 0 -145px;
    }
    &-step5 {
      margin: 1120px 0px 0 -95px;
    }
    &-step6 {
      margin: 1272px 0px 0 -155px;
    }
  }
  .AI-page .step-label {
    &-step6 {
      margin: 1300px 0px 0 -150px;
    }    
  }

  .trusteddev-tab .step-content,
  .ctoServicePortal-tab .step-content {
    width: 221px;
    position: absolute;
    line-height: 1.33;
    letter-spacing: 0.45px;
    text-align: center;
    color: #185780;
    font-size: 13px;
    font-family: "lato-semibold";
    &-step1 {
      margin: 160px 0px 0 -100px;
    }
    &-step2 {
      margin: 395px 0px 0 -112px;
    }
    &-step3 {
      margin: 640px 0px 0 -100px;
    }
    &-step4 {
      margin: 945px 0px 0 -90px;
      width: 175px;
    }
    &-step5 {
      margin: 1170px 0px 0 -115px;
      width: 260px;
    }
    &-step6 {
      margin: 1430px 0px 0 -130px;
      width: 260px;
    }
  }
  .AI-page .step-content {
    &-step6 {
      margin: 1470px 0px 0 -130px;
      width: 260px;
    }    
  }

  .trusteddev-tab .step-arrow,
  .ctoServicePortal-tab .step-arrow {
    position: absolute;
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }

  .trusteddev-tab .mobile,
  .ctoServicePortal-tab .mobile {
    &-step1 {
      width: 100px;
      height: 200px;
      margin: 110px 0 0 65px;
    }
    &-step2 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 90px;
      height: 225px;
      margin: 285px 0 0 -170px;
      z-index: 1;
    }
    &-step3 {
      width: 90px;
      height: 225px;
      margin: 566px 0 0 94px;
    }
    &-step4 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 75px;
      height: 170px;
      margin: 865px 0 0 -171px;
    }
    &-step5 {
      width: 90px;
      height: 225px;
      margin: 1200px 0 0 134px;
    }
  }
  //CTO Tab
  .ctoServicePortal-tab {
    .Animations_Card {
      width: 31px;
      position: relative;
      margin: -47px auto 0;
      height: auto;
      display: block;
    }
    .Animations_Heads {
      width: 45px;
      position: relative;
      margin: -50px auto 0;
      display: block;
    }
    .Animations_Ekierka {
      width: 35px;
      position: relative;
      margin: -51px auto 0;
      display: block;
    }
    .Animations_Labirynth {
      width: 40px;
      position: relative;
      margin: -53.5px auto 0;
    }
    .Animations_Code {
      width: 32px;
      position: relative;
      margin: -47px auto 0;
    }
  }
  .ctoServicePortal-tab .mobile-section-wrapper .step-section {
    .icon_bg {
      height: auto;
      display: block;
      margin: 10px auto 0;
      &1,
      &3,
      &5 {
        width: 62px;
      }
      &2 {
        width: 62px;
        display: block;
        margin: 45px auto 0;
      }
      &4 {
        width: 62px;
        display: block;
        margin: 35px auto 0;
      }
      &6 {
        width: 62px;
        display: block;
        margin: 35px auto 0;
      }
    }
    .line {
      &Left_1 {
        margin: 10px 9px 45px 60%;
      }
      &Left_3 {
        margin: 13px 9px 45px 60%;
      }
      &Left_5 {
        margin: 15px 9px 45px 60%;
      }
      &Right_2 {
        margin: 20px 62% 34px 5px;
      }
      &Right_4 {
        margin: 14px 62% 34px 5px;
      }
      &Right_6 {
        margin: 14px 62% 34px 5px;
      }
    }
    .digit_05 {
      margin-top: -40px;
      margin-right: 10px;
      right: 13px;
    }
    .color_color6 {
      color: #b9a6d6;
    }
    .The-TrustedDev-Vetting-Methodology-Card-Content {
      padding: 0 22px;
      text-align: center;
      color: #fff;
    }
    .blue_dot {
      background-color: #ffffff;
      // &_step5 {
      //   right: 5px;
      //   margin-top: 4px;
      // }
    }
    .dotted_line {
      border-right: 2px dashed #ffffff;
      height: 70%;
      top: 38px;
      right: 0;
    }
    .digit {
      color: #ffffff;
    }
    .circle_blue_large {
      top: 19px;
      border: 2px solid #ef6d66;
      left: -22px;
      right: auto;
      width: 40px;
      height: 38px;
    }
    .circle_blue_medium {
      border: 4px solid #ef6d66;
      width: 27px;
      height: 27px;
      right: 5px;
      top: 3px;
    }
  }
  .ctoServicePortal-tab .Brands-That-Our-Leadership-content {
    font-size: 1.5rem;
    width: 300px;
    margin: auto;
    &_bold-text,
    &_brand-text {
      font-size: 1.75rem;
    }
  }
  //What Page
  .what-page .step-img {
    position: absolute;
    &-step1 {
      width: 300px;
      margin: 0 0 0 -120px;
    }
    &-step2 {
      width: 285px;
      margin: 240px 0 0 -155px;
    }
    &-step3 {
      margin: 440px 0px 0 -135px;
      width: 300px;
    }
    &-step4 {
      width: 190px;
      margin: 685px 0px 0 -85px;
    }
    &-step5 {
      width: 215px;
      margin: 985px 0px 0 -195px;
    }
    &-step6 {
      width: 235px;
      margin: 1183px 0px 0 -117px;
    }
  }

  .what-page .step-label {
    font-size: 18px;
    &-step1 {
      margin: 14px 0 0 -52px;
    }
    &-step2 {
      margin: 285px 0 0 -200px;
    }
    &-step3 {
      margin: 517px 0px 0 -185px;
    }
    &-step4 {
      margin: 807px 0px 0 -135px;
    }
    &-step5 {
      margin: 1080px 0px 0 -90px;
    }
    &-step6 {
      margin: 1234px 0px 0 -145px;
    }
  }

  .what-page .step-content {
    width: 225px;
    font-size: 1.125rem;
    &-step1 {
      margin: 160px 0px 0 -100px;
    }
    &-step2 {
      margin: 395px 0px 0 -112px;
    }
    &-step3 {
      margin: 610px 0px 0 -100px;
      width: 250px;
    }
    &-step4 {
      margin: 905px 0px 0 -95px;
      width: 200px;
    }
    &-step5 {
      margin: 1125px 0px 0 -105px;
      width: 275px;
    }
    &-step6 {
      text-align: center;
      margin: 1400px 0px 0 -130px;
      width: 260px;
    }
  }

  .what-page .step-arrow {
    position: absolute;
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }

  .what-page .mobile {
    &-step1 {
      width: 100px;
      height: 175px;
      margin: 110px 0 0 65px;
    }
    &-step2 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 90px;
      height: 220px;
      margin: 260px 0 0 -160px;
      z-index: 1;
    }
    &-step3 {
      width: 90px;
      height: 230px;
      margin: 530px 0 0 105px;
    }
    &-step4 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 75px;
      height: 175px;
      margin: 820px 0 0 -163px;
    }
    &-step5 {
      width: 90px;
      height: 225px;
      margin: 1170px 0 0 134px;
    }
  }
  .how-page .trusteddev-tab .circle_blue_large {
    width: 40px;
    height: 39px;
    left: -22px;
    top: 16px;
  }
  .what-page .what-page-banner {
    background-image: url("../assets/images/HeroBanners/Tablet1XWhat.jpg");
    border-radius: 0;
    margin-top: 56px;
  }
  //AI-Page
  .AI-page {
    .AI-page-banner {
      height: 390px;
      border-radius: 0;
      background-size: cover;
      margin-top: 56px;
      background-position-x: 88%;
    }
    .AI-page-BackgroundColor {
      height: auto;
    }
    .step-section {
      height: 1600px;
    }
    .mobile {
      &-step5 {
        width: 90px;
        height: 225px;
        margin: 1230px 0 0 120px;
      }
    }
  }
  //Custom-SAAS-page
  .Custom-SAAS-page {
    .Custom-Saas-page-banner {
      height: 360px;
      border-radius: 0;
      background-size: cover;
      background-position-y: 0px;
      margin-top: 56px;
    }
    .section-wrapper {
      padding: 30px 15px;
      width: 576px;
      margin: auto;
    }
    .section-wrapper h3 {
      font-size: 1.5rem;
    }
    .computer-img {
      width: 550px;
      margin: 0px auto;
    }
    .Full-stack-development-img {
      height: 571px;
    }
    .mobile-owasp-img {
      position: absolute;
      margin: 330px 0 0 -85px;
      width: 140px;
    }
    .middle-background-banner {
      height: 850px;
      background-size: cover;
      background-size: 160%;
      background-position-x: 90%;
    }
    .Owsap-image {
      width: 250px;
      margin: auto;
    }
    .FSD_animation {
      #table1,
      #table2,
      #table3,
      #table4,
      #table5,
      #table6,
      #table7,
      #table8,
      #table9,
      #table10,
      #table11,
      #table12,
      #table13,
      #table14,
      #table15,
      #table16,
      #table17,
      #table18,
      #table19,
      #table20 {
        height: 1100px;
      }
    }
    .Full-stack-development-img {
      height: 850px;
    }
  }
  //Why Page
  .about-page .why-page-banner {
    background-image: url("../assets/images/HeroBanners/Tablet1XWhy.jpg");
    // border-bottom: 10px solid rgba(63, 149, 204, 0.24);
    border-bottom: 10px solid #d1e6f3;
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 56px;
  }
  .about-page .tab-content .nav-pill-position {
    margin-top: -45.1px;
  }
  //Why Page
  .how-page .how-page-banner {
    background-image: url("../assets/images/HeroBanners/Tablet1XHow.jpg");
    border-radius: 0;
    margin-top: 56px;
  }
  //LogIn Page
  .Login-page .login-page-logo {
    width: 250px;
  }
  .Login-page .card {
    width: 60%;
  }
  //Survey-Page
  .Survey-Page .questionnaire-form .form-label {
    padding-right: 0px;
  }
  //Password Creation Page
  .Password-Creation-Page .card-body-wrapper .card-modal {
    width: 70%;
  }
  .Password-Creation-Page .basic-form-headline {
    text-align: center;
    font-size: 1.5rem;
    margin: 5px 0 35px;
  }
  .Password-Creation-Page .password-creation-form .form-label {
    font-size: 1rem;
  }
  .Password-Creation-Page .password-req-wrapper .req-header,
  .Password-Creation-Page .password-req-wrapper .list-item {
    font-size: 1.1rem;
  }
  .Password-Creation-Page .continue-btn {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  .Password-Creation-Page .eye-img {
    right: 10px;
  }
  //Progress Bar
  .Congrats-Page .progress-bar-wrapper .progress,
  .Survey-Page .progress-bar-wrapper .progress,
  .Password-Creation-Page .progress-bar-wrapper .progress {
    height: 0.7rem;
  }
  .Congrats-Page .progress-bar-wrapper .progress-bar-label,
  .Survey-Page .progress-bar-wrapper .progress-bar-label,
  .Password-Creation-Page .progress-bar-wrapper .progress-bar-label {
    font-size: 0.875rem;
  }
}

/* Extra Small devices (landscape phones, 576px and up) (xs) */
@media (max-width: 575.98px) {
  .helper-text {
    padding: 2rem 1.5rem 0 1.5rem;
    position: absolute;
    z-index: 1;
    height: 320px;
  }

  .helper-title {
    font-size: 2rem;
    color: #509EC6;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .helper-list-item {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    position: relative;

    &:before {
      content:"• ";
      color: #509EC6;
      position: absolute;
      left: -1rem;
    }
  }

  .helper-link {
    color: #4C677B;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      color: #4C677B;
      opacity: 0.9;
    }
  }


  .Vetting-Methodology-card .col-6:first-child {
    padding-bottom: 30px;

    &.position-relative:nth-child(3) {
      margin-top: 10px;
    }
  }

  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }

  .enter-email-input,
  .enter-email-home {
    height: 30px;
  }

  .enter-email-input {
    width: 150px
  }

  html {
    font-size: 12px;
  }
  .main_banner {
    background-size: 115%, cover;
    background-position-y: -25px;
  }
  .heading_font {
    font-size: 22px;
  }
  .hire_button {
    padding: 8px 28px;
  }
  .hide_under_575 {
    display: none;
  }
  .image_w100 {
    width: 70px;
  }
  .image_w150,
  .image_w160 {
    width: 100px;
  }
  .icon-circleBgLtr,
  .icon-circleBgRtl {
    width: 55px;
  }
  .icon-MobilecircleBgRtl {
    margin: 0px auto 10px;
  }
  .navbar .top_nav .navbar-collapse {
    .TabLabel {
      font-size: 0.75rem;
    }
    .CTOTab-inactive {
      // height: 42px;
      // margin: -19px 0 0 -245px;
      // width: 140px;
      height: 44px;
      margin: -21px 0 0 -242px;
      width: 139px;
    }
    .TabLabelCTOInactiveTab-label {
      // margin: -3px 0 0 -225px;
      margin: -3px 0 0 -222px;
    }
    .CTOTab-active {
      height: 44px;
      margin: -20px 0 0 -115px;
      width: 130px;
    }
    .TabLabelCTOActiveTab-label {
      width: 83px;
      text-align: center;
      margin: -6px 0 0 -92px;
      line-height: 11px;
    }
    .activeTrustedDevTab { 
      height: 44px;
      margin: -21px 0 0 -242px;
      width: 139px;
    }
    .TabLabelTrustedDevActive-label {
      margin: -8px 0 0 -205px;
      text-align: center;
      line-height: 11px;
    }
    .InactiveTrustedDevTab { 
      height: 44px;
      margin: -21px 0 0 -123px;
      width: 139px;
    }
    .TabLabelTrustedDevInactive-label {
      width: 83px;
      text-align: center;
      margin: -8px 0 0 -95px;
      line-height: 11px;
    }
  }
  .icon {
    &-identity {
      width: 40px;
      margin: -58px 0 0 -18px;
    }
    &-Culture {
      width: 40px;
      margin: -58px 0 0 -21px;
    }
    &-OngoingAI {
      width: 40px;
      margin: -62px 0 0 -19px;
    }
    &-ProblemSolving {
      width: 45px;
      margin: -63px 0 0 -22px;
    }
    &-TestProject {
      width: 40px;
      margin: -56px 0 0 -17px;
    }
    &-Enterprise {
      width: 27px;
      margin: -53px 0 0 -14px;
    }
    &-Staff {
      width: 35px;
      margin: -55px 0 0 -19px;
    }
    &-puzzle {
      width: 36px;
      margin: -54px 0 0 -17px;
    }
  }
  .card_line {
    &Left_1 {
      margin: -35px 9px 30px 61%;
    }
    &Left_3 {
      margin: -40px 9px 38px 61%;
    }
    &Left_5 {
      margin: -40px 9px 38px 61%;
    }
    &Right_2 {
      margin: -38px 61% 36px 9px;
    }
    &Right_4 {
      margin: -38px 61% 35px 9px;
    }
    &Last {
      width: 33%;
      margin: -55px 61% 55px 24px;
    }
  }
  .trusteddev-tab .The-TrustedDev-Vetting-Methodology-Card-Content {
    padding: 0 25px;
  }
  .trusteddev-tab,
  .AI-page,
  .what-page,
  .ctoServicePortal-tab {
    .footer_block {
      height: auto;
      font-size: 0.875rem;
      text-align: justify;
      letter-spacing: 0.5px;
      .container .d-inline {
        .d-flex {
          label,
          ul li {
            font-size: 0.813rem;
          }
        }
        span {
          font-size: 0.875rem;
        }
      }
    }
  }
  .trusteddev-tab .right_cut_corners::before,
  .AI-page .right_cut_corners::before {
    border-top: 20px solid #134067;
    border-left: 25px solid #ffffff;
  }
  .trusteddev-tab .left_cut_corners::before,
  .AI-page .left_cut_corners::before {
    border-top: 20px solid #134067;
    border-right: 25px solid #ecf7fd;
  }
  .trusteddev-tab .Rectangle-box,
  .AI-page .Rectangle-box {
    min-height: 175px;
    max-height: 210px;
  }
  .trusteddev-tab .page_divider_banner .Rectangle-box .list_type_none.padding0 {
    max-width: 152px;
    margin: auto;
  }
  .trusteddev-tab {
    .Animations_Circle {
      width: 45px;
      margin: -24px 0 0 -45px;
    }
    .Animations_Clock {
      width: 45px;
      margin: -10px 0 0 -25px;
    }
    .Animations_Bar {
      width: 45px;
      margin: -3px 0 0px -15px;
    }
    .Animations_Cog {
      width: 45px;
      margin: 5px 0 0 -38px;
    }
  }
  .trusteddev-tab .digit-96,
  .AI-page .digit-96 {
    font-family: "lato-black";
    font-size: 2rem;
    letter-spacing: 1.05px;
    color: #3f95cc;
    margin: 10px -50px 10px 10px !important;
  }
  .trusteddev-tab .cost-of-not-automating-content,
  .AI-page .cost-of-not-automating-content {
    font-size: 0.938rem;
    line-height: 1.17;
    letter-spacing: 0.3px;
    margin: 40px 0 8px -20px;
    text-align: center !important;
  }
  .trusteddev-tab .Belive-AI-content,
  .AI-page .Belive-AI-content {
    font-size: 0.813rem;
    letter-spacing: 0.1px;
    text-align: left;
    max-width: 156px;
    margin: auto;
  }
  .trusteddev-tab .hour24-content,
  .AI-page .hour24-content {
    font-size: 1.5rem;
    margin: 0 0 0 -50px;
    letter-spacing: 0;
  }
  .trusteddev-tab .Average-amount-content,
  .AI-page .Average-amount-content {
    font-size: 0.875rem;
    letter-spacing: 0.1px;
    text-align: center;
    margin: 18px auto 0;
    max-width: 163px;
    .text-style {
      font-size: 0.813rem;
    }
  }
  .trusteddev-tab,
  .AI-page {
    .white-box {
      height: 41px;
      width: 40px;
      margin: -18px 0 0 0px;
    }
    .blue-box {
      height: 41px;
      width: 39px;
      margin: -19px 0 0 10px;
    }
    .four_box_center_img {
      width: 60px;
      margin: -32px 0 0 0px;
    }
  }
  .trusteddev-tab .list-item-content,
  .AI-page .list-item-content {
    letter-spacing: 0;
    font-size: 0.813rem;
  }
  .trusteddev-tab .what-can-be-automated-content,
  .AI-page .what-can-be-automated-content {
    font-size: 0.938rem;
    line-height: 1.17;
    letter-spacing: 0.2px;
    margin-top: 15px;
    text-align: left;
  }
  .trusteddev-tab .Automnated-mobi-gif,
  .AI-page .Automnated-mobi-gif {
    width: 45px;
    margin: auto;
    margin-left: 10px;
  }
  .trusteddev-tab .percentage-line-content,
  .AI-page .percentage-line-content {
    font-size: 0.813rem;
    letter-spacing: 0px;
    margin-top: 5px;
    width: 75%;
    .text-style {
      font-size: 1rem;
    }
  }
  .trusteddev-tab .blue_bar_page_divider,
  .trusteddev-tab .page_divider {
    padding: 15px 1rem;
  }
  .trusteddev-tab .Proud-Member-of-the-Following-Organizations,
  .ctoServicePortal-tab .Proud-Member-of-the-Following-Organizations,
  .what-page .Proud-Member-of-the-Following-Organizations {
    font-size: 1.875rem;
    letter-spacing: 0.44px;
    width: 320px;
    margin: auto;
    padding: 25px !important;
  }
  .trusteddev-tab .step-section,
  .ctoServicePortal-tab .step-section {
    height: 1270px;
  }
  .AI-page .step-section {
    height: 1350px;
  }
  .trusteddev-tab .step-img,
  .ctoServicePortal-tab .step-img {
    position: absolute;
    &-step1 {
      width: 225px;
      margin: 0 0 0 -85px;
    }
    &-step2 {
      width: 225px;
      margin: 210px 0 0 -125px;
    }
    &-step3 {
      margin: 399px 0px 0 -113px;
      width: 250px;
    }
    &-step4 {
      width: 150px;
      margin: 625px 0px 0 -60px;
    }
    &-step5 {
      width: 175px;
      margin: 870px 0px 0 -148px;
    }
    &-step6 {
      width: 195px;
      margin: 1040px 0px 0 -80px;
    }
  }
  .AI-page .step-img {
    &-step6 {
      width: 195px;
      margin: 1050px 0px 0 -80px;
    }
  }
  .trusteddev-tab .step-label,
  .ctoServicePortal-tab .step-label {
    position: absolute;
    font-size: 16px;
    font-family: "lato-black";
    line-height: 0.85;
    letter-spacing: 0.84px;
    text-align: center;
    color: #3f95cc;
    &-step1 {
      margin: 10px 0 0 -35px;
    }
    &-step2 {
      margin: 300px 0 0 -100px;
    }
    &-step3 {
      margin: 460px 0px 0 -155px;
    }
    &-step4 {
      margin: 720px 0px 0 -105px;
    }
    &-step5 {
      margin: 945px 0px 0 -70px;
    }
    &-step6 {
      margin: 1080px 0px 0 -110px;
    }
  }

  .trusteddev-tab .step-content,
  .ctoServicePortal-tab .step-content {
    width: 200px;
    position: absolute;
    line-height: 1.33;
    letter-spacing: 0.45px;
    text-align: center;
    color: #185780;
    font-size: 11px;
    font-family: "lato-semibold";
    &-step1 {
      margin: 120px 0px 0 -100px;
      width: 190px;
    }
    &-step2 {
      margin: 328px 0px 0 -100px;
      width: 195px;
    }
    &-step3 {
      margin: 540px 0px 0 -115px;
      width: 225px;
    }
    &-step4 {
      margin: 795px 0px 0 -85px;
      width: 175px;
    }
    &-step5 {
      margin: 978px 0px 0 -80px;
      width: 210px;
    }
    &-step6 {
      margin: 1220px 0px 0 -100px;
      width: 215px;
    }
  }
  .AI-page .step-content {
    &-step6 {
      margin: 1230px 0px 0 -100px;
      width: 215px;
    }
  }

  .trusteddev-tab .step-arrow,
  .ctoServicePortal-tab .step-arrow {
    position: absolute;
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }

  .trusteddev-tab .mobile,
  .ctoServicePortal-tab .mobile {
    &-step1 {
      width: 100px;
      height: 182px;
      margin: 83px 0 0 40px;
    }
    &-step2 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 90px;
      height: 185px;
      margin: 245px 0 0 -140px;
      z-index: 1;
    }
    &-step3 {
      width: 58px;
      height: 200px;
      margin: 470px 0 0 95px;
    }
    &-step4 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 75px;
      height: 150px;
      margin: 728px 0 0 -132px;
    }
    &-step5 {
      width: 58px;
      height: 200px;
      margin: 1010px 0 0 92px;
    }
  }
  .AI-page .mobile {
    &-step5 {
      width: 58px;
      height: 200px;
      margin: 1030px 0 0 100px;
    }
  }
  //CTO Tab
  .ctoServicePortal-tab {
    .cto-page-banner {
      background-size: 575%, 0%;
      height: 1080px;
    }
    .banner_paragraph {
      font-size: 0.75rem;
    }
    .cto-step-section.step-section {
      height: auto;
    }
  }
  .ctoServicePortal-tab .Brands-That-Our-Leadership-content {
    font-size: 1.4rem;
    width: 300px;
    margin: auto;
    &_bold-text,
    &_brand-text {
      font-size: 1.65rem;
    }
  }
  .ctoServicePortal-tab {
    .Animations_Card {
      width: 30px;
      position: relative;
      margin: -50px auto 0;
    }
    .Animations_Heads {
      width: 45px;
      position: relative;
      margin: -51px auto 0;
    }
    .Animations_Ekierka {
      width: 35px;
      position: relative;
      margin: -52px auto 0;
    }
    .Animations_Labirynth {
      width: 40px;
      position: relative;
      margin: -61px auto 0;
    }
    .Animations_Code {
      width: 35px;
      position: relative;
      margin: -53px auto 0;
    }
  }
  .ctoServicePortal-tab .mobile-section-wrapper .step-section {
    .icon_bg {
      width: 70px;
      height: auto;
      display: block;
      margin: 10px auto 0;
      &1,
      &3,
      &5 {
        width: 65px;
      }
      &2 {
        width: 65px;
        margin: 35px auto 6px;
        display: block;
      }
      &4 {
        width: 65px;
        margin: 9px auto 4px;
        display: block;
      }
      &6 {
        width: 62px;
        display: block;
        margin: 55px auto 0;
      }
    }
    .line {
      &Left_1,
      &Left_3,
      &Left_5 {
        margin: 10px 9px 45px 60%;
      }
      &Right_2 {
        margin: 25px 62% 41px 5px;
      }
      &Right_4 {
        margin: 20px 62% 41px 5px;
      }
      &Right_6 {
        margin: 20px 62% 41px 5px;
      }
    }
    .digit {
      &_01,
      &_03,
      &_05 {
        margin-top: -20px;
      }
      &_02,
      &_04 {
        margin-top: -20px;
        left: 8px;
      }
      &_06 {
        margin-top: -25px;
        margin-right: -3px;
        left: 13px;
      }
    }
    .color_color6 {
      color: #b9a6d6;
    }
    .The-TrustedDev-Vetting-Methodology-Card-Content {
      padding: 0 22px;
      text-align: center;
      color: #fff;
    }
    .blue_dot {
      background-color: #ffffff;
      // &_step5 {
      //   right: 5px;
      //   margin-top: 4px;
      // }
    }
    .dotted_line {
      border-right: 2px dashed #ffffff;
      height: 66%;
      top: 40px;
      right: 0;
      position: absolute;
    }
    .digit {
      color: #ffffff;
      font-size: 1rem;
    }
    .circle_blue_large {
      top: 20px;
      border: 2px solid #ef6d66;
      left: -22px;
      right: auto;
      width: 40px;
      height: 38px;
    }
    .circle_blue_medium {
      border: 4px solid #ef6d66;
      width: 27px;
      height: 27px;
      right: 5px;
      top: 3px;
    }
  }
  //What Page
  .what-page .step-img {
    position: absolute;
    &-step1 {
      width: 225px;
      margin: 0 0 0 -85px;
    }
    &-step2 {
      width: 220px;
      margin: 205px 0 0 -116px;
    }
    &-step3 {
      margin: 373px 0px 0 -102px;
      width: 250px;
    }
    &-step4 {
      width: 150px;
      margin: 575px 0px 0 -55px;
    }
    &-step5 {
      width: 175px;
      margin: 845px 0px 0 -145px;
    }
    &-step6 {
      width: 210px;
      margin: 1000px 0px 0 -105px;
    }
  }

  .what-page .step-label {
    font-size: 1.25rem;
    &-step1 {
      margin: 10px 0 0 -35px;
    }
    &-step2 {
      margin: 240px 0 0 -155px;
    }
    &-step3 {
      margin: 435px 0px 0 -143px;
    }
    &-step4 {
      margin: 670px 0px 0 -97px;
    }
    &-step5 {
      margin: 922px 0px 0 -60px;
    }
    &-step6 {
      margin: 1045px 0px 0 -130px;
    }
  }

  .what-page .step-content {
    width: 180px;
    font-size: 11px;
    &-step1 {
      margin: 120px 0px 0 -95px;
    }
    &-step2 {
      margin: 325px 0px 0 -100px;
      width: 215px;
    }
    &-step3 {
      margin: 515px 0px 0 -90px;
      width: 220px;
    }
    &-step4 {
      margin: 750px 0px 0 -75px;
      width: 170px;
    }
    &-step5 {
      margin: 950px 0px 0 -75px;
      width: 210px;
    }
    &-step6 {
      text-align: center;
      margin: 1190px 0px 0 -120px;
      width: 245px;
    }
  }

  .what-page .step-arrow {
    position: absolute;
    &-step1 {
      display: none;
    }
    &-step2 {
      display: none;
    }
    &-step3 {
      display: none;
    }
    &-step4 {
      display: none;
    }
    &-step5 {
      display: none;
    }
  }

  .what-page .mobile {
    &-step1 {
      width: 101px;
      height: 160px;
      margin: 80px 0 0 43px;
    }
    &-step2 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 90px;
      height: 180px;
      margin: 223px 0 0 -127px;
      z-index: 1;
    }
    &-step3 {
      width: 58px;
      height: 242px;
      margin: 420px 0 0 100px;
    }
    &-step4 {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 75px;
      height: 175px;
      margin: 677px 0 0 -127px;
    }
    &-step5 {
      width: 58px;
      height: 200px;
      margin: 980px 0 0 95px;
    }
  }
  //
  .what-page .order-section {
    padding: 25px 20px 50px;
  }
  .what-page .step-section {
    height: 1220px;
  }
  .what-page .what-page-banner {
    background-image: url("../assets/images/HeroBanners/Mobile2XWhat.jpg");
    background-position-y: 0;
    border-radius: 0;
    margin-top: 56px;
  }
  .trusteddev-tab .banner_heading,
  .ctoServicePortal-tab .banner_heading {
    font-size: 1.25rem;
  }
  .trusteddev-tab .The-TrustedDev-Vetting-Methodology {
    font-size: 1.094rem;
    &-text-style-1,
    &-text-style-2 {
      font-size: 1.297rem;
    }
  }
  .ctoServicePortal-tab .The-TrustedDev-Vetting-Methodology {
    font-family: "lato-black";
    font-size: 1.063rem;
    text-align: center;
    line-height: 2.59;
    letter-spacing: 1.35px;
    padding-top: 15px;
    .text-style-1 {
      font-size: 1.25rem;
    }
    .text-style-2 {
      font-size: 1.25rem;
      color: #ef6d66;
    }
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper {
    .three-person-image {
      width: 52px;
      margin-left: 37px;
      margin-right: 5px;
    }
    .ready-to-hire-label {
      margin: 7px 0 0 83px;
      position: absolute;
      width: 280%;
      text-transform: uppercase;
      color: #185780;
      font-family: "lato-black";
      font-size: 0.906rem;
      text-align: center;
    }
  }
  .trusteddev-tab .your-staff-is-ready-to-hire-wrapper {
    display: flex;
    align-items: self-start;
    flex-direction: row;
    width: 100%;
  }
  .trusteddev-tab .banner_paragraph {
    // color: #0f3658;
    font-size: 0.875rem;
    // padding: 15px;
    line-height: 1.4;
    margin: 0;
  }
  .get-a-demo-container { 
    padding: 25px;
  }

  .ctoServicePortal-tab .cto-brand-logo-divider {
    .cocacola,
    .kelloggs,
    .jpmorgan,
    .experian {
      max-width: 100px;
      min-width: 75px;
    }
    .charles {
      max-width: 90px;
      min-width: 60px;
    }
  }
  .ctoServicePortal-tab .order-section {
    padding: 45px 0;
  }

  .trusteddev-tab .dotted_line {
    height: 90%;
    top: 30px;
  }
  .trusteddev-tab .last_dot {
    left: 4px;
    top: 4px;
  }
  .trusteddev-tab .circle_blue {
    &_large {
      top: 100%;
      width: 40px;
      height: 37px;
      left: -18px;
    }
    &_medium {
      width: 26px;
      height: 26px;
      left: 5px;
      top: 3px;
    }
  }
  //AI-Page
  .AI-page {
    .AI-page-banner {
      height: 275px;
      border-radius: 0;
      background-size: cover;
      background-position-x: 88%;
      margin-top: 56px;
    }
    .AI-page-BackgroundColor {
      height: auto;
    }
    .page_divider_banner {
      background-position: 100% 15%;
      height: 825px;
    }
    .Revealing-Stats-label {
      text-align: center;
      float: none;
      margin: 5px auto;
      display: block;
      font-size: 0.5rem;
    }
  }
  //About Page (Why)
  .about-page .why-page-banner {
    // border-bottom: 10px solid rgba(63, 149, 204, 0.24);
    border-bottom: 10px solid #d1e6f3;
    background-image: url("../assets/images/HeroBanners/Mobile2XWhy.jpg");
    border-radius: 0;
    background-position-y: 40%;
    margin-bottom: 0;
    margin-top: 56px;
  }
  .about-page .tab-content .nav-pill-position {
    margin-top: -42px;
  }
  .what-page .tab-content .nav-pill-position {
    margin-top: -42.5px;
  }
  .about-page .card-body {
    padding: 15px !important;
  }
  .about-page {
    .icon-size {
      width: 35px;
      margin-top: 31px;
    }
    .Rectangle-wrapper {
      .col-sm-6 {
        width: 50%;
      }
      .rectangle-img {
        width: 65px;
      }
      .rectangle-heading {
        font-size: 1.2rem;
        margin: 5px 0;
      }
      .rectangle-label {
        font-size: 0.875rem;
        margin: 10px 0px;
      }
      .rectangle {
        margin: 5px;
        min-width: 135px;
        min-height: 175px;
      }
    }
  }
  //Why Page
  .how-page .how-page-banner {
    background-image: url("../assets/images/HeroBanners/Mobile2XHow.jpg");
    background-position-y: 40%;
    border-radius: 0;
    margin-top: 56px;
  }
  //About (How)
  .how-page .How-TrustedDev-Works-Heading {
    font-size: 0.875rem;
  }
  .how-page .trusteddev-tab .digit {
    font-size: 0.875rem;
    &_01,
    &_03,
    &_05 {
      margin-top: -20px;
      right: 10px;
    }
    &_02,
    &_04 {
      margin-top: -20px;
      left: 10px;
    }
  }
  .about-page .nav .header-tab {
    width: 110px;
    height: 33px;
  }
  .about-page .nav .whypage-tabLabel {
    margin: 0 0 0 36px;
  }
  .tab-content .nav-pill {
    margin: 3px -6px;
  }
  .how-page .trusteddev-tab .circle_blue_large {
    top: 742px;
  }
  .how-page .icon_bg_right,
  .how-page .icon_bg {
    width: 65px;
  }
  .how-page .trusteddev-tab .circle_blue {
    &_large {
      top: 12px;
      width: 33px;
      height: 33px;
      left: -18px;
    }
    &_medium {
      border: 3.2px solid #09b6b4;
      width: 24px;
      height: 24px;
      left: 3px;
      top: 2.2px;
    }
  }
  .how-page .trusteddev-tab .dotted_line {
    height: 88%;
    top: 30px;
  }
  .how-page .about-page-why-footer-banner,
  .about-page .about-page-why-footer-banner {
    height: 600px;
  }
  .how-page .Proud-Member-of-the-Following-Organizations {
    font-size: 1.75rem;
    letter-spacing: 1px;
    padding: 25px !important;
  }
  .how-page .trusteddev-tab .staff-hire-label {
    font-size: 0.875rem;
    padding: 0px 30px;
  }
  .how-page .trusteddev-tab .card_lineLast {
    width: 33%;
    margin: -40px 61% 36px 17px;
  }
  .how-page .trusteddev-tab .logo_divider_row {
    border-radius: 0 50px 0 0;
  }
  .how-page .hire_btn {
    padding: 7px 15px;
  }
  .how-page .How-TrustedDev-Works-Content,
  .how-page .How-TrustedDev-Works-Heading {
    padding: 0 10px;
  }
  //What
  .what-page .Expert-software-engineer-section-body {
    .impact-gif {
      margin-bottom: 15px;
    }
    .impact2-gif {
      width: 240px;
      margin: -130px auto 0;
    }
    .arrow-right {
      width: 40px;
    }
    .star1 {
      width: 20px;
      margin: -35px 0 0 -50px;
    }
    .star2 {
      width: 30px;
      margin: -52px 0 0 8px;
    }
    .star3 {
      width: 20px;
      margin: 0px 0 25px 4px;
    }
  }
  //Custom-Saas
  .Custom-SAAS-page {
    .section-wrapper {
      padding: 25px 15px;
      width: 320px;
      margin: auto;
    }
    .section-wrapper h3 {
      font-size: 1.1rem;
      margin: 0 0 10px 0;
      text-align: center;
    }
    .Order-list-number {
      padding: 4px 6px 5px 10px;
      margin: 0 8px 0 0;
    }
    .section-wrapper ul li {
      font-size: 0.875rem;
    }
    .section-wrapper ul li:last-child .Order-list-number {
      padding: 5px 4px 5px 6px;
      margin: 0 4px 0 0;
    }
    .FSD_animation {
      #table1,
      #table2,
      #table3,
      #table4,
      #table5,
      #table6,
      #table7,
      #table8,
      #table9,
      #table10,
      #table11,
      #table12,
      #table13,
      #table14,
      #table15,
      #table16,
      #table17,
      #table18,
      #table19,
      #table20 {
        height: 320px;
        margin-top: -170px;
      }
    }
    .Full-stack-development-img {
      height: 515px;
    }
    .middle-background-banner {
      background-size: cover;
      background-position-x: 96%;
      height: 515px;
    }
  }
  .Custom-SAAS-page .Custom-Saas-page-banner {
    height: 275px;
    border-radius: 0;
    background-size: cover;
    background-position-x: 15%;
    background-position-y: 100%;
    margin-top: 56px;
  }
  .Custom-SAAS-page .computer-img {
    width: 100%;
    margin-bottom: 20px;
  }
  .Custom-SAAS-page .Owsap-image {
    width: 200px;
    margin: auto;
  }
  .Custom-SAAS-page .mobile-owasp-img {
    position: absolute;
    margin: 270px 0 0 -55px;
    width: 100px;
  }
  //Engagement Page
  .Engagement-page {
    .col-sm-12 {
      max-width: 100%;
    }
  }
  //LogIn page
  .Login-page .card {
    width: 100%;
    margin: 25px auto 20px;
    padding: 0 15px;
  }
  .Login-page .login-page-logo {
    width: 240px;
    margin: 20px auto 30px;
  }
  // create-password-page
  .create-password-page .eye-img {
    right: 35px;
  }
  //Survey-Page
  .Survey-Page .card-body-wrapper .form-field-wrapper {
    padding: 0 25px;
  }
  .Survey-Page .basic-form-headline {
    padding: 0px;
  }
  .Survey-Page .card-footer {
    justify-content: space-around;
  }
  .Survey-Page .questionnaire-form .form-label {
    padding-right: 0;
  }
  .Survey-Page .continue-btn {
    padding: 10px 20px;
  }
  .Survey-Page .optOther-textfield.form-control {
    width: 100%;
  }
  //Password-Creation-Page
  .Password-Creation-Page .card-body-wrapper .card-modal {
    width: 100%;
    padding: 25px;
  }
  .Password-Creation-Page .basic-form-headline {
    font-size: 1.5rem;
    text-align: center;
  }
  .Password-Creation-Page .eye-img {
    right: 30px;
  }
  .Password-Creation-Page .password-creation-form .form-label,
  .Password-Creation-Page .password-req-wrapper .req-header,
  .Password-Creation-Page .password-req-wrapper .list-item {
    font-size: 1rem;
  }
  .Password-Creation-Page .continue-btn {
    font-size: 1.1rem;
    padding: 10px 25px;
    display: flex;
    align-items: center;
  }
  //Progress Bar
  .Congrats-Page .header-text,
  .Survey-Page .header-text,
  .Password-Creation-Page .header-text {
    margin-right: 5px;
  }
  .Congrats-Page .progress-bar-wrapper .progress-bar-label,
  .Survey-Page .progress-bar-wrapper .progress-bar-label,
  .Password-Creation-Page .progress-bar-wrapper .progress-bar-label {
    margin: 0 10px;
    font-size: 0.938rem;
  }
  .Congrats-Page .progress-bar-wrapper .progress,
  .Survey-Page .progress-bar-wrapper .progress,
  .Password-Creation-Page .progress-bar-wrapper .progress {
    height: 0.7rem;
  }
  .Engagement-page {
    .engagement-card {
      margin: auto;
      max-width: 295px;
    }
  }
}

/* Only 320px width devices */
@media (max-width: 320px) {
  .helper-text {
    padding: 2rem 1rem 0 1rem;
    position: absolute;
    z-index: 1;
    height: 320px;
  }

  .helper-title {
    font-size: 2rem;
    color: #509EC6;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .helper-list-item {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    position: relative;

    &:before {
      content:"• ";
      color: #509EC6;
      position: absolute;
      left: -1rem;
    }
  }

  .helper-link {
    color: #4C677B;
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      color: #4C677B;
      opacity: 0.9;
    }
  }


  .Vetting-Methodology-card .col-6:nth-child(2) {
    & .position-relative:nth-child(3) {
      padding-top: 150px;
    }
  }

  .enter-email-input,
  .enter-email-home {
    height: 30px;
  }

  .enter-email-input {
    width: 150px
  }

  .about-page .why-page-banner {
    background-image: url("../assets/images/HeroBanners/Mobile1XWhy.jpg");
    background-size: cover;
    background-position-x: 45%;
    height: 400px;
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 56px;
    border-bottom: 10px solid #d1e6f3;
    // border-bottom: 10px solid rgba(63, 149, 204, 0.24);
  }
  .how-page .how-page-banner {
    background-image: url("../assets/images/HeroBanners/Mobile1XHow.jpg");
    background-position-y: 0%;
  }
  .what-page .what-page-banner {
    background-image: url("../assets/images/HeroBanners/Mobile1XWhat.jpg");
    background-position-y: 0%;
  }
}

/* For firefox browser */
@-moz-document url-prefix() {
  .Login-page .please-login-line,
  .verify-page .please-login-line {
    line-height: 3.21;
  }
  .navbar .top_nav .navbar-collapse {
    .activeTrustedDevTab {
      height: 55px;
      margin: -24px 0 -7px -28px;
    }
    .InactiveTrustedDevTab {
      height: 55px;
      margin: -25px 0 -7px 195px;
    }
    .TabLabel {
      letter-spacing: 0.33px;
      font-family: "lato-bold";
      font-size: 0.813rem;
      cursor: pointer;
      &TrustedDevActive-label {
        margin: -4px 50px 0 30px;
        color: #134067;
      }
      &TrustedDevInactive-label {
        margin: -4px 0 -7px 240px;
        color: #67889b;
      }
      &CTOActiveTab-label {
        margin: -3px 50px 0 240px;
        color: #2f254c;
      }
      &CTOInactiveTab-label {
        margin: -5px 50px 0 40px;
        color: #7664ac;
      }
    }
  }
  .Login-page .login-form,
  .Password-Creation-Page .password-creation-form {
    .form-control {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  .BasicIngo-Page .basic-info-form .form-control {
    padding: 10px 0;
  }
}

/* Safari browser */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    @media (max-width: 767.98px) {
      .navbar .top_nav .navbar-collapse {
        .activeTrustedDevTab {
          height: 69px;
          margin: -23px 0 0 -275px;
          width: 160px;
        }
        .TabLabelTrustedDevActive-label {
          margin: 5px 0 0 -241px;
        }
        .InactiveTrustedDevTab {
          height: 58px;
          margin: -20px 0 0 -140px;
          width: 161px;
        }
        .TabLabelTrustedDevInactive-label {
          width: 83px;
          text-align: center;
          margin: -1px 0 0 -95px;
          line-height: 11px;
        }
      }
    }
  }
}

/* Safari browser */
_::-webkit-full-page-media,
_:future,
:root .navbar .top_nav .navbar-collapse {
  .activeTrustedDevTab {
    height: 69px;
    margin: -23px 0 0 -275px;
    width: 160px;
  }
  .TabLabelTrustedDevActive-label {
    margin: 5px 0 0 -241px;
  }
}

/********** Media queries ends here **********/


.cto-page-banner, .about-page, .Custom-SAAS-page {
  .page_bottom_divider_banner, .about-page-why-footer-banner {
  background-image: none;
  height: auto !important;
  }
}

.AI-page .page_divider_banner {
  height: auto !important;
  padding-bottom: 100px;
  margin-bottom: -100px;
}

.get-a-demo-container {
  display: flex;
  justify-content: center;
  // flex-direction: column;
}

.error-message-email {
  color: red;
  font-weight: bold;
  position: absolute;
  bottom: 7px;
  right: 50%;
  transform: translateX(50%);
}


.survey-wrapper,
.survey-body {
  height: 100%;
}

.nav-link {
  display: flex;
  text-align: center;
}

.z-index-1 {
  z-index: 1;
}

.navbar-nav {
  width: 100%;
  justify-content: space-around;
}

.link-to {
  text-decoration: underline;
}